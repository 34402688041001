import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Table} from 'antd';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import axios from 'axios';
import { Api } from '../../../utils/Variables';

import PsContext from '../../context/PsContext';

class PlanWiseCount extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
			dataList: [],
			dataView: [],
			paginationPageSize: 5,
			currentPage: 0,
		}
		
		this.handlePaginationSizeChange = this.handlePaginationSizeChange.bind(this);
	}
	
	
	componentDidMount(){
		
		this.setState({
			dataList: this.props.planWiseCount,
			dataView: this.props.planWiseCount,
		});
	}
	
	handlePaginationSizeChange(current, pageSize) {
		
		this.setState({paginationPageSize: pageSize, currentPage: current});
	}
	
	getTotalCount(){
		try{
			var count = 0;
			var d = this.state.dataView;
			d.map((item,i)=>{
				count = parseInt(item.total_members)+ parseInt(count);
			});
			return count;
		}
		catch(error){ }
	}
	
	render(){
		
		const columns = [
				{
					title: 'Plan Name',
					dataIndex: 'planname',
					key: 'planname',
				},
				{
					title: 'Total',
					dataIndex: 'total_members',
					key: 'total_members',
				},
			];
			
		return(
			<React.Fragment>
				<Card>
					<Card.Body>
						<h6>Plan wise count - ({this.getTotalCount()})</h6>
						<hr />
						<Table 
							size="small"
							columns={columns} 
							bordered
							loading={this.state.showLoader}
							dataSource={this.state.dataView}
							pagination={{ 
								pageSize: this.state.paginationPageSize,
								onShowSizeChange: this.handlePaginationSizeChange,
							}}
						 />
						 
					</Card.Body>
				</Card>
			</React.Fragment>
		);
	}
	
}
export default PlanWiseCount;
