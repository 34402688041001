/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */

import MasterDashboard from './pages/MasterAdmin/Dashboard';
import CentersList from './pages/MasterAdmin/Centers/centersList';
import ViewDetails from './pages/MasterAdmin/Centers/ViewDetails';
import Announcements from './pages/MasterAdmin/Announcements';
import Products from './pages/MasterAdmin/Products';
import MasterProductEnquiry from './pages/MasterAdmin/Products/ProductEnquiry';
import MasterPages from './pages/MasterAdmin/Pages';
import MasterGallery from './pages/MasterAdmin/Gallery';
import MasterCategory from './pages/MasterAdmin/Category';
import MasterVideo from './pages/MasterAdmin/Video';

import Filemanager from './pages/Filemanager';

import Dashboard from './pages/Dashboard';
import TrainerList_New from './pages/Trainer/trainerList';
import PackageList_New from './pages/PricePackage/packageList';
import MemberList_New from './pages/Member/memberList';
import MembersReport from './pages/MembersReport';
import MembersDetailedReport from './pages/MembersReport/DetailesReport';
import MemberTrainerWiseReport from './pages/MembersReport/TrainerWiseReport';
import MemberDueList from './pages/MembersReport/MemberDueList';
import TimingList_New from './pages/BatchTiming/timingList';
import ReceiptEntry from './pages/ReceiptEntry';
import ReceiptList from './pages/ReceiptEntry/receiptList';
import PaymentList from './pages/PaymentEntry/paymentList';
import PaymentEntry from './pages/PaymentEntry/paymentEntry';
import GymProfile from './pages/GymProfile';
import UserProfile from './pages/UserProfile';
import AccountReport from './pages/DailyAccounts/accountReport';
import AccountHead from './pages/DailyAccounts/AccountHead';
import PayrollSettings from './pages/StaffPayroll/payrollSettings';
import GenerateSalary from './pages/StaffPayroll/generateSalary';
import PaySlip from './pages/StaffPayroll/paySlip';
import SalaryAbstract from './pages/StaffPayroll/salaryAbstract';
import DeviceList from './pages/MyDevices/deviceList';
import Settings from './pages/Settings';
import GymEnquries from './pages/GymEnquries';
import Announcement from './pages/Announcements';
import Offers from './pages/Offers';
import Communication from './pages/Communication';
import Equipments from './pages/Equipments';
import Product from './pages/Products';
import ProductEnquiry from './pages/Products/ProductEnquiry';
import Pages from './pages/Pages';
import Gallery from './pages/Gallery';
import ReferalCode from './pages/ReferalCode';

const pageList = [
  { name: 'Dashboard', path: '/app', component: MasterDashboard, allowed: ['superadmin'] },
  { name: 'Fitness Centre', path: '/app/fitness-centre', component: CentersList, allowed: ['superadmin'] },
  { name: 'Details', path: '/app/fitness-centre/:id/:code', component: ViewDetails, allowed: ['superadmin'] },
  { name: 'Announcements', path: '/app/announcements', component: Announcements, allowed: ['superadmin'] },
  { name: 'Products', path: '/app/products', component: Products, allowed: ['superadmin'] },
  { name: 'Product Enquiry', path: '/app/master/product-enquiry', component: MasterProductEnquiry, allowed: ['superadmin'] },
  { name: 'Pages', path: '/app/master/pages', component: MasterPages, allowed: ['superadmin'] },
  { name: 'Gallery', path: '/app/master/gallery', component: MasterGallery, allowed: ['superadmin'] },
  { name: 'Category', path: '/app/master/category', component: MasterCategory, allowed: ['superadmin'] },
  { name: 'Video', path: '/app/master/video', component: MasterVideo, allowed: ['superadmin'] },

  { name: 'Dashboard', path: '/app', component: Dashboard, allowed: ['admin', 'staff'] },
  //{ name: 'Add Fitness Center', path: '/app/add-fitness-center', component: AddCenter },
  { name: 'Members', path: '/app/members', component: MemberList_New, allowed: ['admin', 'staff'] },
  { name: 'Members Report', path: '/app/members-report', component: MembersReport, allowed: ['admin', 'staff'] },
  { name: 'Members Detailed Report', path: '/app/members-report/detailed', component: MembersDetailedReport, allowed: ['admin', 'staff'] },
  { name: 'Trainer Wise Report', path: '/app/members-report/trainer-wise', component: MemberTrainerWiseReport, allowed: ['admin', 'staff'] },
  { name: 'Members Due List', path: '/app/members-due-list', component: MemberDueList, allowed: ['admin', 'staff'] },
  { name: 'Price Packages', path: '/app/price-packages', component: PackageList_New, allowed: ['admin'] },
  { name: 'BatchTimings', path: '/app/batch-timings', component: TimingList_New, allowed: ['admin'] },
  { name: 'Staff / Trainers', path: '/app/trainers', component: TrainerList_New, allowed: ['admin'] },
  { name: 'Gym Profile', path: '/app/gym-profile', component: GymProfile, allowed: ['admin'] },
  { name: 'User Profile', path: '/app/user-profile', component: UserProfile, allowed: ['staff'] },
  { name: 'Receipts', path: '/app/receipts', component: ReceiptList, allowed: ['admin', 'staff'] },
  { name: 'Payments', path: '/app/payments', component: PaymentList, allowed: ['admin', 'staff'] },
  { name: 'Payment Entry', path: '/app/payments/new', component: PaymentEntry, allowed: ['admin', 'staff'] },
  { name: 'Receipt Entry', path: '/app/receipts/new', component: ReceiptEntry, allowed: ['admin', 'staff'] },
  { name: 'Daily Accounts', path: '/app/accounts', component: AccountReport, allowed: ['admin'] },
  { name: 'Account Head', path: '/app/accounts/head', component: AccountHead, allowed: ['admin'] },
  { name: 'Payroll Settings', path: '/app/staff-payroll', component: PayrollSettings, allowed: ['admin'] },
  { name: 'Generate Salary', path: '/app/staff-payroll/generate', component: GenerateSalary, allowed: ['admin'] },
  { name: 'Pay Slip', path: '/app/staff-payroll/payslip', component: PaySlip, allowed: ['admin'] },
  { name: 'Salary Abstract', path: '/app/staff-payroll/Salary-abstract', component: SalaryAbstract, allowed: ['admin'] },
  { name: 'My Devices', path: '/app/mydevices', component: DeviceList, allowed: ['admin'] },
  { name: 'Settings', path: '/app/settings', component: Settings , allowed: ['admin']},
  { name: 'Settings', path: '/app/settings/:userpath', component: Settings, allowed: ['admin'] },
  { name: 'Gym Enquires', path: '/app/gym-enquires', component: GymEnquries, allowed: ['admin', 'staff'] },
  { name: 'Announcement', path: '/app/announcement', component: Announcement, allowed: ['admin'] },
  { name: 'Offers', path: '/app/offers', component: Offers, allowed: ['admin'] },
  { name: 'Communication', path: '/app/communication', component: Communication, allowed: ['admin'] },
  { name: 'Equipments', path: '/app/equipments', component: Equipments, allowed: ['admin'] },
  { name: 'Product', path: '/app/product', component: Product, allowed: ['admin'] },
  { name: 'Product Enquiry', path: '/app/product-enquiry', component: ProductEnquiry, allowed: ['admin'] },
  { name: 'Pages', path: '/app/pages', component: Pages, allowed: ['admin'] },
  { name: 'Gallery', path: '/app/gallery', component: Gallery, allowed: ['admin'] },
  { name: 'ReferalCode', path: '/app/referalcode', component: ReferalCode, allowed: ['admin'] },
];

export default pageList;
