import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';

import { Api } from '../../../utils/Variables';
import { getDateByFormat } from '../../../utils/appUtils';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress,
} from '../../../utils';
import stateDistricts from '../../../utils/stateDistricts.json';
import ReactFileReader from 'react-file-reader';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import UPLOAD_ARROW from '../../../assets/img/upload-arrow.png';

const { Option } = Select;

class AddMember extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			stateValue: '',
			districtValue: '',
			districtList: [],
			profilePhoto: '',
			identityProof: '',
			submitBtn: 'Save Member',
			trainerList: [],
			packageList: [],
			timingList: [],
			selectedTiming: '',
			selectedPackage: '',
			selectedTrainer: '',
		}
		
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleDistrictChange = this.handleDistrictChange.bind(this);
		this.handleProfilePhotoChange = this.handleProfilePhotoChange.bind(this);
		this.handleProfilePhotoRemoveClick = this.handleProfilePhotoRemoveClick.bind(this);
		this.handleIdentityProofChange = this.handleIdentityProofChange.bind(this);
		this.handleIdentityProofRemoveClick = this.handleIdentityProofRemoveClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handlePackageChange = this.handlePackageChange.bind(this);
		this.handleBatchTimingChange = this.handleBatchTimingChange.bind(this);
		this.handleTrainerChange = this.handleTrainerChange.bind(this);
	}
	
	componentDidMount(){
		
		this.loadList();
	}
	
	loadList() {
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/members/options?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						trainerList: res['data'].data.trainers,
						packageList: res['data'].data.packages,
						timingList: res['data'].data.timings,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleStateChange(value) {
		const index = stateDistricts.states.findIndex((item) => item.id === value);
		var districts = stateDistricts.states[index].districts;
		this.setState({districtList: districts, stateValue: value});
	}
	
	handleDistrictChange(value){
		this.setState({districtValue: value});
	}
	
	handleProfilePhotoChange(files) {
		this.setState({profilePhoto: files.base64});
	}
	
	handleProfilePhotoRemoveClick() {
		this.setState({profilePhoto: ''});
	}
	
	handleIdentityProofChange(files) {
		this.setState({identityProof: files.base64});
	}
	
	handleIdentityProofRemoveClick() {
		this.setState({identityProof: ''});
	}
	
	handlePackageChange(value){
		this.setState({selectedPackage: value});
	}
	
	handleBatchTimingChange(value){
		this.setState({selectedTiming: value});
	}
	
	handleTrainerChange(value){
		this.setState({selectedTrainer: value});
	}
	
	handleFormSubmit(e) {
		e.preventDefault();
		try{
			
			/*if(this.state.identityProof.length<1){
				message.error('Select Identity Proof');
				return;
			}*/
			/*
			if(this.state.selectedPackage.length<1){
				message.error('Select Package');
				return;
			}
			*/
			
			if(!window.confirm('Do you want to save Member?')){
				return;
			}
			
			this.setState({showLoader: true, submitBtn: '....'});			
			axios.post(Api.Url+'v1/member/save', $("#frm_addMember_DForm").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frm_addMember_DForm").reset();
					message.success(res['data'].message || 'Success');
					this.setState({
						showLoader: false, 
						submitBtn: 'Save Member',
						//stateValue: '',
						//districtValue: '',
						profilePhoto: '',
						identityProof: '',
					});
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false, submitBtn: 'Save Member'});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false, submitBtn: 'Save Member'});
		}
	}
	
	
	render(){

		const date = new Date();
		const ageLimit = date.setFullYear(date.getFullYear() - 10);
  
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<form action="" method="post" encType="multipart/form-data" id="frm_addMember_DForm" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="i__stateId" value={this.state.stateValue} />
					<input type="hidden" name="i__districtId" value={this.state.districtValue} />
					<input type="hidden" name="i__profilePhoto" value={this.state.profilePhoto} />
					<input type="hidden" name="i__identityProof" value={this.state.identityProof} />
					<input type="hidden" name="i__prefix" value={getls('loginUser', 'prefix')} />
					<input type="hidden" name="i__timing" value={this.state.selectedTiming} />
					<input type="hidden" name="i__trainer" value={this.state.selectedTrainer} />
					<input type="hidden" name="i__package" value={this.state.selectedPackage} />
					
					<Row>
						<Col md={10} >
						
							<Row>
								<Col md={4} >
									<label>
										First Name 
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__firstName" 
										type="text" 
										size="sm"
										placeholder="First Name" 
										onKeyPress={lettersOnly}
										required
									/>
								</Col>
								
								<Col md={4} >
									<label>
										Last Name 
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__lastName" 
										type="text" 
										size="sm"
										placeholder="Last Name" 
										onKeyPress={lettersOnly}
									/>
								</Col>
								
								<Col md={4} >
									<label>
										Mobile
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__mobile" 
										type="number" 
										size="sm"
										placeholder="Mobile Number" 
										onKeyPress={integerIndMobile}
										required
									/>
								</Col>
								
							</Row>
							
							<Row className="mt-10" >
								
								<Col md={4} >
									<label>
										Email Id
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__email" 
										type="email" 
										size="sm"
										placeholder="Email Id" 
									/>
								</Col>
								
								<Col md={4} >
									<label>
										State
										<span className="text-danger">*</span>
									</label>
									 <Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select a State"
										  optionFilterProp="children"
										  onChange={this.handleStateChange}
										  style={{width: '100%'}}
										  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
										  {stateDistricts && stateDistricts.states.map((state) => (
											<Option key={state.id} value={state.id}>{state.name}</Option>
										  ))}
										</Select>
								</Col>
								
								<Col md={4} >
										
									<label>
										District
										<span className="text-danger">*</span>
									</label>
									 <Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select a District"
										  optionFilterProp="children"
										  onChange={this.handleDistrictChange}
										  style={{width: '100%'}}
										  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
										  {this.state.districtList && this.state.districtList.map((item) => (
												<Option key={item.id} value={item.id}>{item.name}</Option>
										  ))}
										</Select>
										
								</Col>
								
							</Row>
							
							<Row  className="mt-10" >
								<Col md={12} >
									<label>
										Address
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__address"
										as="textarea" 
										size="sm"
										rows="2"
										placeholder="Address" 
										required
									/>
								</Col>
								
								
							</Row>
							
							
							<Row  className="mt-10" >
							
								<Col md={4} >
									<label>
										Gender
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__gender"
										as="select"
										size="sm"
										placeholder="" 
										required
									>
										<option value="male"> Male </option>
										<option value="female" > Female </option>
									</Form.Control>
								</Col>
								
								<Col md={4} >
									<label>
										Date of Birth
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__dateOfBirth"
										type="date" 
										size="sm"
										placeholder="Date of Birth" 
										max={getDateByFormat(ageLimit, 'YYYY-MM-DD')}
										required
									/>
								</Col>
								
								<Col md={4} >
									<label>
										Date of Joining
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__dateOfJoining"
										type="date" 
										size="sm"
										placeholder="Date of Birth" 
										max={getDateByFormat((new Date()), 'YYYY-MM-DD')}
										required
									/>
								</Col>
							</Row>
							
							<Row  className="mt-10" >
								
								<Col md={4} >
									<label>
										Educational Qualification
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__qualification"
										type="text" 
										size="sm"
										placeholder="Educational Qualification" 
										
									/>
								</Col>
								
								<Col md={4} >
									<label>
										Years of Playing
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__yearOfPlaying"
										type="number" 
										size="sm"
										placeholder="Years of Playing" 
										
									/>
								</Col>
								
								<Col md={4} >
									<label>
										Expectation
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__expectation"
										type="text" 
										size="sm"
										placeholder="Expectation" 
										required
									/>
								</Col>
								
							</Row>
														
							<Row  className="mt-10" >
								<Col md={4} >
									<label>
										Aadhar No
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__aadhar"
										type="text" 
										size="sm"
										placeholder="Aadhar No" 
										onKeyPress={integerAadhar}
									/>
								</Col>
								
								<Col md={4} >
									<label>
										Occupation
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__occupation"
										type="text" 
										size="sm"
										placeholder="Occupation" 
									/>
								</Col>
								
								<Col md={4} >
									<label>
										Emergency No
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__emergencyno"
										type="text" 
										size="sm"
										placeholder="Emergency No" 
									/>
								</Col>
								
							</Row>
							
							<Row  className="mt-10" >
								<Col md={4} >
									<label>
										Height (CM)
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__height"
										type="number" 
										size="sm"
										placeholder="Height" 
										onKeyPress={decimalKeyPress}
									/>
								</Col>
								
								<Col md={4} >
									<label>
										Weight (KG)
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__weight"
										type="number" 
										size="sm"
										placeholder="Weight" 
										onKeyPress={decimalKeyPress}
									/>
								</Col>
								
								<Col md={4} >
									<label>
										Hip Measures (CM)
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__hipMeasure"
										type="number" 
										size="sm"
										placeholder="Hip Measures (CM)" 
										onKeyPress={decimalKeyPress}
									/>
								</Col>
								
							</Row>
							
							
							<Row className="mt-10" >
								
								<Col md={4} >
									<label>
										Package
										<span className="text-danger"></span>
									</label>
									 <Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select a Package"
										  optionFilterProp="children"
										  onChange={this.handlePackageChange}
										  style={{width: '100%'}}
										  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
										  {this.state.packageList && this.state.packageList.map((item) => (
											<Option key={item.id} value={item.id}>{item.name} ({item.amount})</Option>
										  ))}
										</Select>
								</Col>
								
								<Col md={4} >
									<label>
										Batch Timing
										<span className="text-danger"></span>
									</label>
									 <Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select Batch Timing"
										  optionFilterProp="children"
										  onChange={this.handleBatchTimingChange}
										  style={{width: '100%'}}
										  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
										  {this.state.timingList && this.state.timingList.map((item) => (
											<Option key={item.id} value={item.id}>{item.starttime}-{item.endtime} ({item.preferable})</Option>
										  ))}
										</Select>
								</Col>
								
								<Col md={4} >
										
									<label>
										Trainer
										<span className="text-danger"></span>
									</label>
									 <Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select a Trainer"
										  optionFilterProp="children"
										  onChange={this.handleTrainerChange}
										  style={{width: '100%'}}
										  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
										  {this.state.trainerList && this.state.trainerList.map((item) => (
												<Option key={item.id} value={item.id}>{item.firstname} {item.lastname}</Option>
										  ))}
										</Select>
										
								</Col>
							</Row>
							
							
							<Row className="mt-10" >	
								<Col md={4} >
									<label>
										Dose
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__dose1"
										type="number" 
										size="sm"
										value="1" readOnly
									/>
								</Col>	
								<Col md={4} >
									<label>
										Date
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__firstdosedate"
										type="date" 
										size="sm"
										placeholder="Weight"
									/>
								</Col>
								<Col md={4} >
									<label>
										Reference Id
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__firstdoseid"
										type="text" 
										size="sm"
										placeholder="Reference Id"
										onKeyPress={integerKeyPress}
									/>
								</Col>
								
							</Row>
							
							<Row className="mt-10" >	
								<Col md={4} >
									<label>
										Dose
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__dose2"
										type="number" 
										size="sm"
										value="2" readOnly
									/>
								</Col>	
								<Col md={4} >
									<label>
										Date
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__seconddosedate"
										type="date" 
										size="sm"
										placeholder="Weight"
									/>
								</Col>
								<Col md={4} >
									<label>
										Reference Id
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__seconddoseid"
										type="text" 
										size="sm"
										placeholder="Reference Id"
										onKeyPress={integerKeyPress}
									/>
								</Col>
								
							</Row>
														
						</Col>
						
						<Col md={2} >
							<Row>
								<Col md={12} >
									<label>Referal Code</label>
									<Form.Control 
										name="i__referCode"
										type="text" 
										size="sm"
										placeholder="Referal Code"
									/>
								</Col>
							</Row>
							<Row className="mt-10" >
								<Col md={12} >
									<label>Profile Photo</label>
									<ReactFileReader  base64 handleFiles={this.handleProfilePhotoChange} >
									<img 
										src={this.state.profilePhoto || UPLOAD_ARROW} 
										className="img-upload" 
										tooltip="Click to Upload new Image"
									/>
									</ReactFileReader>
									{this.state.profilePhoto ? <>
										<Button 
											size="xs" 
											variant="danger" 
											onClick={this.handleProfilePhotoRemoveClick} >
											Remove
										</Button>
									</> : null}
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={12} >
									<label>Idenity Proof <span className="text-danger"></span></label>
									<ReactFileReader  base64 handleFiles={this.handleIdentityProofChange} >
									<img 
										src={this.state.identityProof || UPLOAD_ARROW} 
										className="img-upload" 
										tooltip="Click to Upload new Image"
									/>
									</ReactFileReader>
									{this.state.identityProof ? <>
										<Button 
											size="xs" 
											variant="danger" 
											onClick={this.handleIdentityProofRemoveClick} >
											Remove
										</Button>
									</> : null}
								</Col>
							</Row>
						</Col>
					</Row>
					
					<Row>
						<Col md={12} >
							<hr />
							<div className="text-center" >
								<Button type="submit" size="sm" variant="success" disabled={this.state.submitBtn!='Save Member'} >
									{this.state.submitBtn}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>	
				</Spin>				
			</React.Fragment>
		);
	}
	
}
export default AddMember;
