 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, Modal, ListGroup, Table} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, todayDate, groupByMultiple
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Drawer, Skeleton, Spin, Select,message, Tooltip, 
} from 'antd';

import PsContext from '../../context/PsContext';
import PayrollMenu from './payrollMenu';
import PayrollSettingsNew from './payrollSettingsNew';

const { Option } = Select;

class PaySlip extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showAddModal: false,
			dataList: [],
			dataView: [],
			selectedMonth: '',
			selectedMonthValue: '',
			selectedYearValue: '',
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleMonthChange = this.handleMonthChange.bind(this);
		
	}
	
	componentDidMount(){
		
	}
	
	loadData(){
		try{
			
			if(this.state.selectedMonth.length<4)
			{
				message.error('Select Month');
				return;
			}
			this.setState({
				showLoader: true,
				dataList: [],
				dataView: [],
			});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__date', this.state.selectedMonth);
			form.append('i__month', this.state.selectedMonthValue);
			form.append('i__year', this.state.selectedYearValue);
						
			axios.post(Api.Url+'v1/payroll/listabstract', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false
					});
					
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleMonthChange(e){
		var m = new Date(e.target.value).getMonth()+1;
		var y = new Date(e.target.value).getFullYear();
		
		this.setState({
			selectedMonth: e.target.value,
			selectedMonthValue: m,
			selectedYearValue: y,
		});
	}
	
	daysInMonth (month, year) {
		
		return new Date(year, month, 0).getDate();
	}
	
	getSundays(year, month, totalDays){
		
		var sun = new Array();
		for(var i=1;i<=totalDays;i++){    //looping through days in month
			var newDate = new Date(year,month,i)
			if(newDate.getDay()==0){   //if Sunday
				sun.push(i);
			}
		}
	}
	
	
	
	render(){

		var sno=1;
		
		return(
			<div>
			
			<PayrollMenu {...this.props} />
				
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} 
				>
					
					<Card>
						<Card.Body>
							<Row>
								<Col md={1} >
									<label>Month</label>
								</Col>
								<Col md={2} >
									<Form.Control
										type="month"
										size="sm"
										max={momentDate(todayDate(), 'YYYY-MM')}
										onChange={this.handleMonthChange} 
									/>
								</Col>
								<Col md={1} >
									<Button 
										type="button" 
										size="sm" 
										variant="warning" 
										onClick={this.loadData} 
									>
										Load
									</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
					
					
				</Spin>
				
				
			</div>
		);
	}
	
}
export default PaySlip;
