 import React, { Component } from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import $ from 'jquery';

import PsContext from '../../context/PsContext';

class PayrollMenu extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			
		}
		
	}
	
	componentDidMount(){
		
	}
	
	render(){

			var menus = [
				{to: '/app/staff-payroll/salary-abstract', name: 'Salary Abstract'},
				{to: '/app/staff-payroll/generate', name: 'Generate Salary'},
				{to: '/app/staff-payroll', name: 'Payroll Settings'},
			];
		
		return(
			<ul className="nav nav-tabs nav-gap-x-1" id="myTab" role="tablist">
				{menus.map((item,i)=>{
					return <li className="nav-item" >
						<NavLink 
							className="nav-link" 
							to={item.to}
							exact
						> 
							{item.name} 
						</NavLink>
					</li>;
				})				
				}
			</ul>
		);
	}
	
}
export default PayrollMenu;
