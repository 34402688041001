import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

class ReferalCode extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddDrawer: false,
			showDeleteLoader: false,
			showEditDrawer: false,
			editData: [],
			dataList: [],
			dataView: [],
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/referalsettings/update', $("#frmUpdateReferal_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						showLoader: false,
					});
					document.getElementById("frmUpdateReferal_Mod").reset();
					message.success(res['data'].message || 'Success');
					this.loadData();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({
						showLoader: false
					});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showDeleteLoader: true});
			axios.get(Api.Url+'v1/referalsettings?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data[0],
						showDeleteLoader: false
					});
				}
				else{
					this.setState({showDeleteLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showDeleteLoader: false});
		}
	}
	
	getValue(field){
	
		var d = this.state.dataView;
		return d[field];
	}
	
	render(){

		if(this.state.showLoader)
			return(<SpinLoader loading={this.state.showLoader} />);
		
		return(
			<div>
				<Spin spinning={this.state.showLoader} >
				
					<Card>
						<Card.Body>
						<form 
							action=""
							method="post"
							encType="multipart/form-data" 
							id="frmUpdateReferal_Mod"
							onSubmit={this.handleFormSubmit}
						>	
							<input type="hidden" name="api" value={this.context.state.api} />
							<Row>
								<Col md={3} >
									<label className="font-14" >Code Validity
										<span className="text-danger">*</span>
									</label>
									<Form.Control
										type="number"
										size="sm"
										name="i__validity"
										defaultValue={this.getValue('code_validity')}
										required
									/>
								</Col>
								<Col md={3} >
									<label className="font-14" >Referal Grace Days
										<span className="text-danger">*</span>
									</label>
									<Form.Control
										type="number"
										size="sm"
										name="i__referal"
										defaultValue={this.getValue('referal_grace_days')}
										required
									/>
								</Col>
								{/*<Col md={3} >
									<label className="font-14" >Refered Grace Days
										<span className="text-danger">*</span>
									</label>
									<Form.Control
										type="number"
										size="sm"
										name="i__refered"
										defaultValue={this.getValue('refered_grace_days')}
										required
									/>
								</Col>*/}
								<Col md={3} className="mt-15" >
									<Button
										type="submit"
										variant="success"
									>
										Save Changes
									</Button>
								</Col>
							</Row>
						</form>
						</Card.Body>
					</Card>
				</Spin>
			</div>
		);
	}
	
}
export default ReferalCode;
