 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup ,
} from 'react-bootstrap';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import iziToast from 'izitoast';
import ReactFileReader from 'react-file-reader';
import PsContext from '../../context/PsContext';

import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import UPLOAD_ARROW from '../../../assets/img/upload-arrow.png';

const { Option } = Select;

class AddPage extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Save Announcement',
			showLoader: false,
			editorState: null,
			htmlContent: '',
			image: '',
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
		this.handleImageChange = this.handleImageChange.bind(this);
		this.handleImageRemoveClick = this.handleImageRemoveClick.bind(this);
	}
	
	handleImageChange(files) {
		this.setState({image: files.base64});
	}
	
	handleImageRemoveClick() {
		this.setState({image: ''});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/app/page/save', $("#frmAddPage_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmAddPage_Mod").reset();
					this.setState({
						editorState: BraftEditor.createEditorState(''),
						htmlContent: '',
						image: '',
						showLoader: false,
					});
					message.success(res['data'].message || 'Success');
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({
						showLoader: false
					});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleEditorChange(editorState){
		try{
			this.setState({ 
				editorState: editorState,
				htmlContent: editorState.toHTML(),
			});
		}
		catch(error){ console.log(error); }
	}
	
	render(){

		const types = [
			//{key: 'ANNOUNCEMENT', value: 'ANNOUNCEMENT'},
			{key: 'HEALTH_TIPS', value: 'HEALTH_TIPS'},
			{key: 'OFFER', value: 'OFFER'},
		];
		
		return(
			<div>
				<Spin spinning={this.state.showLoader} >
					<form 
						action=""
						method="post"
						encType="multipart/form-data" 
						id="frmAddPage_Mod"
						onSubmit={this.handleFormSubmit}
					>	
						<input type="hidden" name="api" value={this.context.state.api} />
						<input type="hidden" name="i__content" value={this.state.htmlContent} />
						<input type="hidden" name="i__image" value={this.state.image} />
						
						<Row>
							<Col md={4} >
								<Row className="mt-15" >
									<Col md={12}>
										<label className="font-14" >Title
											<span className="text-danger">*</span>
										</label>
										<Form.Control
											type="text"
											size="sm"
											name="i__title"
											required
										/>
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={12}>
										<label className="font-14" >Display Order
											<span className="text-danger">*</span>
										</label>
										<Form.Control
											type="number"
											size="sm"
											name="i__order"
											required
										/>
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={12}>
										<label className="font-14" >Image
											<span className="text-danger"></span>
										</label>
										<ReactFileReader  base64 handleFiles={this.handleImageChange} >
											<img 
												src={this.state.image || UPLOAD_ARROW} 
												className="img-upload" 
												tooltip="Click to Upload new Image"
												style={{width : "250px"}}
											/>
											</ReactFileReader>
											{this.state.image ? <>
												<Button 
													size="xs" 
													variant="danger" 
													onClick={this.handleImageRemoveClick} >
													Remove
												</Button>
											</> : null}
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={12} >
										<div className="text-right" >
											<Button
												type="submit"
												variant="success"
											>
												Save Changes
											</Button>
										</div>
									</Col>
								</Row>
								
							</Col>
							
							<Col md={8} >
								 <BraftEditor
									  value={this.state.editorState}
									  onChange={this.handleEditorChange}
									  language="en"
									  controls={[
											'undo', 'redo', 'separator',
											'font-size', 'line-height', 'letter-spacing', 'separator',
											'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
											'superscript', 'subscript', 'remove-styles',  'separator', 'text-indent', 'text-align', 'separator',
											'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
											'link', 'separator', 'hr', 'separator',
											//'media', 'separator','emoji',
											'clear'
									  ]}
								 />
							</Col>
						</Row>					
						
					</form>
				</Spin>
			</div>
		);
	}
	
}
export default AddPage;
