 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup ,
} from 'react-bootstrap';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import iziToast from 'izitoast';
import ReactFileReader from 'react-file-reader';
import PsContext from '../../context/PsContext';
import UPLOAD_IMG from '../../../assets/img/noimage-square.png';
import PhotoGallery from 'react-grid-gallery';
const { Option } = Select;

const styles = {
	imgStyle : {
		padding:'10px',border : '1px solid #d8d8d8',margin : '10px',height : '140px'
	},
	btnStyle : {
		position: 'absolute',
		top: '1px',
		right: '0px',
		borderRadius: '50px',
		cursor : 'pointer'
	}
}

class Gallery extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showAddModal: false,
			showEditModal: false,
			paginationPageSize: 30,
			dataList: [],
			dataView: [],
			images: [],
			files1: [],
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleImageChange = this.handleImageChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	handleImageChange(files) {
		if(this.state.images.length > 0){
			for(var i=0;i<(files.base64).length;i++){
				this.state.images.push(files.base64[i]);
			}
			this.setState({images : this.state.images});
		}else{
			this.setState({ images: files.base64});
		}
		
		/*//if(this.state.files1.length > 0){
			//for(var i=0;i<files.length;i++){
				this.state.files1.push(files);
			//}
			this.setState({files1 : this.state.files1});
		/*}else{
			this.setState({ files1: files});
		}*/
		
		if(this.state.files1.length > 0){
			for(var i=0;i<files.length;i++){
				this.state.files1.push(files);
			}
			this.setState({files1 : this.state.images});
		}else{
			this.setState({ files1: files});
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/gallery/save', $("#frmAddGallery_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmAddGallery_Mod").reset();
					this.setState({
						images: [],
						showLoader: false,
					});
					message.success(res['data'].message || 'Success');
					this.loadData();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({
						showLoader: false
					});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/gallery?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	handleAddClick(){
		this.setState({
			showAddModal: true,
		});
	}
	
	handleAddModalCloseClick(){
		this.setState({
			showAddModal: false,
		});
	}
	
	handleEditClick(record){
		this.setState({
			editData: record,
			showEditModal: true,
		});
		
	}
	
	handleEditModalCloseClick(){
		this.setState({
			editData: [],
			showEditModal: false,
		});
	}
	
	handleDeleteActionClick(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: `Do you want to Delete the Record?`,
			buttons: {
				Yes: function(){ 
					$this.handleDeleteConfirm(record);
				},
				No: function() { }
			}
		});
	}
	
	handleDeleteConfirm(record){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__id', record.id);
			
			axios.post(Api.Url+'v1/gallery/remove', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					/*var index = d.findIndex(function(obj){
						return(obj.id==record.id);
					});
					d.splice(index,1);*/
					var dat = d.filter(function(obj){
						return(obj.id != record.id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						showLoader: false
					});
					//this.loadData();
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			message.error('Unknown Error');
			this.setState({showLoader: false});
		}
	}
	
	handleRemove(index){

		this.state.images.splice(index,1);
		this.setState({images : this.state.images});
	}

	render(){
		
		let IMAGES = [];
		this.state.dataView.map((item,i)=>{
			IMAGES.push({
				src : Api.Url +item.image,
				thumbnail : Api.Url +item.image,
				thumbnailWidth: 20,
				thumbnailHeight: 20,
				tags: [
					{value : <div>
						<button type="button" className="btn btn-danger btn-xss" onClick={this.handleDeleteActionClick.bind(this,item)}><i className="icofont-close"></i></button>
						</div> }
				],
				caption: Api.Url +item.image}) 
		});
		
		return(
			<div>
				<Spin spinning={this.state.showLoader} >
				<Card>
					<Card.Body>
						<form 
							action=""
							method="post"
							encType="multipart/form-data" 
							id="frmAddGallery_Mod"
							onSubmit={this.handleFormSubmit}
						>	
							<input type="hidden" name="api" value={this.context.state.api} />
							<input type="hidden" name="i__image" value={JSON.stringify(this.state.files1)} />
							<Row>
								{this.state.images.map((item,i)=>{
									return(<Col md={2} style={{maxWidth : '15%'}}>
										<img src={item} width="150px" style={styles.imgStyle} />
										<button type="button" className="btn btn-danger btn-xss" style={styles.btnStyle} onClick={this.handleRemove.bind(this,i)}><i className="icofont-close"></i></button>
									</Col>);
								})}
								<Col md={2}>
									<ReactFileReader fileTypes={[".png",".jpg",".jpeg"]} multipleFiles base64 handleFiles={this.handleImageChange} >
										<img 
											src={this.state.image || UPLOAD_IMG} 
											className="img-upload" 
											tooltip="Click to Upload new Image"
											style={{width : "150px"}}
										/>
									</ReactFileReader>
								</Col>
							</Row>
							<Row className="mt-15" >
								<Col md={12} >
									<div className="text-right" >
										<Button
											type="submit"
											variant="success"
										>
											Save
										</Button>
									</div>
								</Col>
							</Row>
						
						</form>
					</Card.Body>	
				</Card>
				
				<div className="mt-10 ps-table" >
					<Row className="mt-15" >
						<Col md={12}>
							<PhotoGallery images={IMAGES}/>
						</Col>
					</Row>
				</div>
				
				</Spin>
			</div>
		);
	}
	
}
export default Gallery;
