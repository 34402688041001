import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Table, Tabs } from 'antd';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import axios from 'axios';
import { Api } from '../../../utils/Variables';
import PsContext from '../../context/PsContext';

const { TabPane } = Tabs;

class DueList extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
			dataList: [],
			dataView: [],
			tomorrowDueList: [],
			yesterdayDueList: [],
			todayCollectionList: [],
		}
	}
	
	componentDidMount(){
		
		
	}
	
	componentWillMount(){
		this.setState({
			yesterdayDueList: this.props.yesterDayDueData,
			dataList: this.props.dueData,
			dataView: this.props.dueData,
			tomorrowDueList: this.props.tomorrowDueData,
			todayCollectionList: this.props.todayCollectionData,
		});
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/dashboard/birthdaylist?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						showLoader: false,
						dataList: res['data'].data,
						dataView: res['data'].data,
					});
				}
				else{
					this.setState({showLoader: false});
				}
					
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		
		const columns = [
				{
					title: '#',
					dataIndex: 'id',
					key: 'id',
					render: (text, record, index)=>(
						<>
						{index+1}
						</>
					)
				},
				{
					title: 'Member Id',
					dataIndex: 'memberid',
					key: 'memberid',
				},
				{
					title: 'Member Name',
					dataIndex: 'firstname',
					key: 'firstname',
					render: (text, record)=>(
						<>
						{record.member_device_status=='0' ? <span className="text-danger" >{record.firstname} {record.lastname} </span>
						:  <span className="" >{record.firstname} {record.lastname} </span>}
						</>
					)
				},
				{
					title: 'Mobile',
					dataIndex: 'mobile',
					key: 'mobile',
				},
				{
					title: 'Due Date',
					dataIndex: 'current_plan_expires_on',
					key: 'current_plan_expires_on',
					render: (text, record)=>(
						<>
						{momentDate(record.current_plan_expires_on, 'DD-MM-YYYY')}
						</>
					)
				},
				{
					title: 'Plan Expires',
					dataIndex: 'current_plan_expires_on',
					key: 'current_plan_expires_on',
					render: (text, record)=>(
						<>
						{momentDate(record.current_plan_expires_on, 'DD-MM-YYYY')}
						</>
					)
				},
				
			];
			
		const todaycolumns = [
			{
				title: '#',
				dataIndex: 'id',
				key: 'id',
				render: (text, record, index)=>(
					<>
					{index+1}
					</>
				)
			},
			{
				title: 'Member Id',
				dataIndex: 'user_code',
				key: 'user_code',
			},
			{
				title: 'Member Name',
				dataIndex: 'firstname',
				key: 'firstname',
				render: (text, record)=>(
					<>
					{record.member_device_status=='0' ? <span className="text-danger" >{record.firstname} {record.lastname} </span>
					:  <span className="" >{record.firstname} {record.lastname} </span>}
					</>
				)
			},
			{
				title: 'Mobile',
				dataIndex: 'mobile',
				key: 'mobile',
			},
			{
				title: 'Amount',
				dataIndex: 'credit_amount',
				key: 'credit_amount',
			},
			
		];
			
		return(
			<React.Fragment>
				<Card>
					<Card.Body>
						<Tabs defaultActiveKey="2">
							<TabPane tab="Yesterday Due List" key="1">
								<Table 
									size="small"
									columns={columns} 
									bordered
									loading={this.state.showLoader}
									dataSource={this.state.yesterdayDueList}
								 />
							</TabPane>
							<TabPane tab="Today Due List" key="2">
								<Table 
									size="small"
									columns={columns} 
									bordered
									loading={this.state.showLoader}
									dataSource={this.state.dataView}
								 />
							</TabPane>
							<TabPane tab="Tomorrow Due List" key="3">
								<Table 
									size="small"
									columns={columns} 
									bordered
									loading={this.state.showLoader}
									dataSource={this.state.tomorrowDueList}
								 />
							</TabPane>
							{/*<TabPane tab="Today Collection List" key="4">
								<Table 
									size="small"
									columns={todaycolumns} 
									bordered
									loading={this.state.showLoader}
									dataSource={this.state.todayCollectionList}
								 />
							</TabPane>*/}
						</Tabs>
											 
					</Card.Body>
				</Card>
			</React.Fragment>
		);
	}
	
}
export default DueList;
