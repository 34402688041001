 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, Modal} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../../utils';
import iziToast from 'izitoast';

import {
  Table, Drawer, Skeleton, Spin, Select,message, Switch, 
} from 'antd';

import stateDistricts from '../../../../utils/stateDistricts.json';

import PsContext from '../../../context/PsContext';

const { Option } = Select;

class SendSms extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showChangePassword: false,
			loggedUser: [],
			stateValue: '',
			districtValue: '',
			districtList: [],
			isUnicode: 0,
			btnText: 'Send Now',
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleUnicodeChange = this.handleUnicodeChange.bind(this);
	}
	
	handleUnicodeChange(e){
		
		this.setState({isUnicode:e});
	}
	
	
	handleFormSubmit(e){
		e.preventDefault();
		try{			
			 this.setState({showLoader: true, btnText: '...'});
			axios.post(Api.Url+'v1/ma/fitness/send_sms', $("#frm_Mod_SendSms").serialize()).then(res=>{
				
				if(res['data'].status === '1'){
										
					document.getElementById("frm_Mod_SendSms").reset();
					message.success(res['data'].message || 'Success');
					this.setState({showLoader: false, btnText: 'Send Now'});
				}
				else{
					this.setState({showLoader: false, btnText: 'Send Now'});
					message.error(res['data'].message || 'Error');
				}
			});	 
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false, btnText: 'Send Now'});
		}
	}
	
		
	
	render(){

		
		
		return(
			<div>

				<form action="" method="post" encType="multipart/form-data" id="frm_Mod_SendSms" onSubmit={this.handleFormSubmit} >
					
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="fitness_key" value={this.props.editData.fitness_key} />
					<input type="hidden" name="i__unicode" value={this.state.isUnicode ? '1' : '0'} />
				
										
					<Row className="mt-15" >
						<Col md={3} >
							<label>
								Mobile
								<span className="text-danger"> *</span>
							</label>
						</Col>
						<Col md={9} >
							<Form.Control 
								type="text" 
								name="i__mobile"
								size="sm"	
								placeholder="" 
								required
								//value="6379705440"
								value={this.props.editData.contactnumber1} 
							/>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={3} >
							<label>Message
								<span className="text-danger"> *</span>
							</label>
						</Col>
						<Col md={9} >
							<Form.Control 
								as="textarea"
								name="i__message"
								size="sm"	
								rows="6"
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={3} >
							<label>Unicode Text
								<span className="text-danger"> *</span>
							</label>
						</Col>
						<Col md={9} >
							<Switch 
							value={this.state.isUnicode}
							onChange={this.handleUnicodeChange} />
						</Col>
					</Row>
					
					<hr />
					
					<Row>
						<Col md={12} >
							<div className="text-center" >
								<Button type="submit" variant="success" disabled={this.state.btnText!='Send Now'} >
									{this.state.btnText} 
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>							
			
			</div>
		);
	}
	
}
export default SendSms;
