 import React, { Component } from 'react';
import {Redirect, NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Row, Col, Button} from 'react-bootstrap';
import {getls, getSession, upperCase, momentDate} from '../../../utils';
import {Api} from '../../../utils/Variables';

const styles = {
	headerText: {
		color: '#7b7b7b',
		fontWeight: '600',
		fontSize: '16px',
		textTransform: 'uppercase',
	},
	headerText2: {
		fontSize: '14px',
	},
	rowBoder:{
		
	},
	tdBorder:{
		borderTop: '1px solid #a7a5a5', 
		borderBottom: '1px solid #a7a5a5'
	},
}

class PrintReceipt extends Component{
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
		}
		
		this.printDocument = this.printDocument.bind(this);
	}
	
	componentDidMount(){
		console.log(this.props.printData);
	}
	
	getField(fieldName){
		try{
			var d = this.props.printData;
			return d[fieldName];
		}
		catch(error){ }
	}
	
	printDocument() {
		var content = document.getElementById("label_print");
		var pri = document.getElementById("print_frame").contentWindow;
		pri.document.open();
		pri.document.write(content.innerHTML);
		pri.document.close();
		pri.focus();
		pri.print();
	}
	
	render(){

		
		
		return(
			<div>
				<Row className="mb-20" >
					<Col md={12} >
						<div className="text-center" >
							<Button
								size="sm"
								onClick={this.printDocument} 
							>
								PRINT
							</Button>
							
						</div>
						<hr />
					</Col>
				</Row>
				
				<div id="label_print">
				<table width="100%"  >
					<tr>
						<td width="70%" >
							<span style={{
								fontSize: '30px',
								color: '#7b7b7b',
								fontWeight: '600',
							}} >RECEIPT</span>
							<br />
							<b><i>{upperCase(getls('loginUser', 'name'))}</i></b> <br />
							GST : {upperCase(getls('loginUser', 'gst'))} <br />
							{upperCase(getls('loginUser', 'address'))} <br />
							{/*upperCase(getls('loginUser', 'state'))}, {upperCase(getls('loginUser', 'state'))*/} 
						</td>
						<td align="right" >
							<img src={Api.Url+getls('loginUser', 'logo')} 
								style={{
									maxWidth: '180px',
									maxHeight: '100px',
								}}
								className="pr-15"
							/>
						</td>
					</tr>
					<tr>
						<td colSpan="2"><hr /></td>
					</tr>
					<tr>
						<td width="" style={{verticalAlign: 'top'}} >
							<div style={styles.headerText} >Customer</div>
							<div style={styles.headerText2} >{this.getField('firstname')} / {this.getField('member_code')}</div>
							<div style={styles.headerText2} >{this.getField('mobile')}</div>
						</td>
						<td  style={{verticalAlign: 'top'}} >
							<div style={styles.headerText} >Receipt</div>
							<div style={styles.headerText2} >No : {this.getField('receipt_no')}</div>
							<div style={styles.headerText2} >Date : {momentDate(this.getField('receipt_date'),'DD-MMM-YYYY')}</div>
						</td>
					</tr>
					<tr>
						<td colSpan="2" >
							<br />
							<table width="100%" align="center" >
								<tr >
									<td width="60" style={styles.tdBorder} height="40" >S.No</td>
									<td align="center" style={styles.tdBorder}  >Name of the Plan</td>
									<td width="150" align="center" style={styles.tdBorder}   >Received</td>
								</tr>
								<tr>
									<td align="center" height="80" >1.</td>
									<td align="center" >{this.getField('packagename')} ({this.getField('packageamount')})</td>
									<td align="center" >{this.getField('paid_amount')}</td>
								</tr>
								<tr >
									<td colSpan="2" align="right" height="40" style={{
										borderTop: '1px solid #a7a5a5'
									}}  >
										<div style={styles.headerText} >Total : </div>
									</td>
									<td align="center" style={{
										borderTop: '1px solid #a7a5a5'
									}} >
										<div style={styles.headerText} >{this.getField('paid_amount')}</div>
									</td>
								</tr>
								{/*<tr >
									<td colSpan="2" align="right" >
										<div style={styles.headerText2} > Balance to Paid : </div>
									</td>
									<td align="center">
										<div style={styles.headerText2} >{this.getField('receipt_no')}</div>
									</td>
								</tr>
								<tr >
									<td colSpan="2" align="right" >
										<div style={styles.headerText2} > Next Installment Date : </div>
									</td>
									<td align="center">
										<div style={styles.headerText2} >
											{this.getField('next_payment')!='0000-00-00 00:00:00' ? momentDate(this.getField('next_payment'),'DD-MMM-YYYY') : null}
										</div>
									</td>
								</tr>*/}
							</table>
						</td>
					</tr>
					<tr>
						<td colSpan="2" align="center" >
							<br />
						 *** Thank you ***
						 <div style={{
							fontSize: '12px', 
						 }} > This is computer generated receipt, no signature required.  </div>
						</td>
					</tr>
				</table>
				</div>
				
			</div>
		);
	}
	
}
export default PrintReceipt;
