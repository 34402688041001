 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, ButtonGroup, Modal, ListGroup} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../../utils';
import iziToast from 'izitoast';
import stateDistricts from '../../../../utils/stateDistricts.json';
import ReactFileReader from 'react-file-reader';
import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';


import PsContext from '../../../context/PsContext';

import UPLOAD_ARROW from '../../../../assets/img/upload-arrow.png';

const { Option } = Select;

class AddCentre extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Save',
			showLoader: false,
			showAddModal: false,
			dataList: [],
			dataView: [],
			stateValue: '',
			districtValue: '',
			districtList: [],
			profilePhoto: '',
		}
		
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleDistrictChange = this.handleDistrictChange.bind(this);
		this.handlePrefixBlur = this.handlePrefixBlur.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleProfilePhotoChange = this.handleProfilePhotoChange.bind(this);
		this.handleProfilePhotoRemoveClick = this.handleProfilePhotoRemoveClick.bind(this);
		
	}
	
	
	handleProfilePhotoChange(files) {
		this.setState({profilePhoto: files.base64});
	}
	
	handleProfilePhotoRemoveClick() {
		this.setState({profilePhoto: ''});
	}
	
	
	handleStateChange(e) {
		var value = e.target.value;
		const index = stateDistricts.states.findIndex((item) => item.id === value);
		var districts = stateDistricts.states[index].districts;
		this.setState({districtList: districts, stateValue: value});
	}
	
	handleDistrictChange(e){
		var value = e.target.value;
		this.setState({districtValue: value});
	}
	
	handlePrefixBlur(e){
		try{
			var v = e.target.value;
			axios.get(Api.Url+'v1/ma/check_prefix?api='+
				this.context.state.api+'&prefix='+v).then(res=>{
					if(res['data'].status=='0'){
						message.error(res['data'].message || 'Error');
					}
				});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/savefitness', $("#frm_add_Mod_centres").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frm_add_Mod_centres").reset();
					message.success(res['data'].message || 'Success');
					this.setState({showLoader: false});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		
		return(
			<div>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
				<form 
					action=""
					method="post"
					encType="multipart/form-data" 
					id="frm_add_Mod_centres"
					onSubmit={this.handleFormSubmit}
				>
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="i__profilePhoto" value={this.state.profilePhoto} />
					
					<Row>
					<Col md={9} >
					
					<Row>
						<Col md={3} >
							<label className="font-14"  >
								Prefix 
								<span className="text-danger">*</span>
							</label>
							<Form.Control 
								type="text"
								name="i__prefix"
								size="sm"
								required
								style={{
									textTransform: 'uppercase',
								}}
								onBlur={this.handlePrefixBlur}
							/>
						</Col>
						<Col md={6} >
							<label className="font-14"  >
								Name of the Centre 
								<span className="text-danger">*</span>
							</label>
							<Form.Control 
								type="text"
								name="i__name"
								size="sm"
								autocomplete="off"
								required
								style={{
									textTransform: 'uppercase',
								}}
							/>
						</Col>
						
						<Col md={3} >
							<label className="font-14"  >
								Started On 
								<span className="text-danger">*</span>
							</label>
							<Form.Control 
								type="date"
								name="i__startedon"
								size="sm"
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={3} >
							<label className="font-14"  >
								GST No
								<span className="text-danger"></span>
							</label>
							<Form.Control 
								type="text"
								name="i__gst"
								size="sm"
							/>
						</Col>
						<Col md={3} >
							<label className="font-14"  >
								Mobile
								<span className="text-danger">*</span>
							</label>
							<Form.Control 
								type="number"
								name="i__mobile"
								size="sm"
								required
							/>
						</Col>
						<Col md={6} >
							<label className="font-14"  >
								Email Id 
								<span className="text-danger">*</span>
							</label>
							<Form.Control 
								type="email"
								name="i__email"	
								size="sm"
								autocomplete="off"
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={3} >
							<label className="font-14"  >
								State 
								<span className="text-danger">*</span>
							</label>
							<Form.Control 
								as="select"
								name="i__state"
								size="sm"
								required
								onChange={this.handleStateChange}
							>
							<option value="" > Select </option>
							 {stateDistricts && stateDistricts.states.map((state) => (
								 <option key={state.id} value={state.id}>{state.name}</option>
							  ))}
							</Form.Control>
						</Col>
						<Col md={3} >
							<label className="font-14"  >
								District 
								<span className="text-danger">*</span>
							</label>
							<Form.Control 
								as="select"
								name="i__district"
								size="sm"
								autocomplete="off"
								required
								onChange={this.handleDistrictChange}
							>
							 <option value="" > Select </option>
							 {this.state.districtList && this.state.districtList.map((item) => (
									<option key={item.id} value={item.id}>{item.name}</option>
							  ))}
							</Form.Control>
						</Col>
						<Col md={6} >
							<label className="font-14"  >
								Address
								<span className="text-danger">*</span>
							</label>
							<Form.Control 
								type="text"
								name="i__address"
								size="sm"
								required
							/>
						</Col>
					</Row>
					
					
					<Row className="mt-10" >
						<Col md={3} >
							<label className="font-14"  >
								Contact Person 
								<span className="text-danger"></span>
							</label>
							<Form.Control 
								type="text"
								name="i__contactPerson2"
								size="sm"
							/>
						</Col>
						<Col md={3} >
							<label className="font-14"  >
								ContactNumber
								<span className="text-danger"></span>
							</label>
							<Form.Control 
								type="number"
								name="i__contactNo2"
								size="sm"
								autocomplete="off"
							/>
						</Col>
						<Col md={6} >
							<label className="font-14"  >
								Website Url
								<span className="text-danger"></span>
							</label>
							<Form.Control 
								type="url"
								name="i__url"
								size="sm"
							/>
						</Col>
					</Row>
					
					</Col>
					<Col md={3} >
						<Row>
							<Col md={12} >
								<label>Center Logo</label>
								<ReactFileReader  base64 handleFiles={this.handleProfilePhotoChange} >
								<img 
									src={this.state.profilePhoto || UPLOAD_ARROW} 
									className="img-upload" 
									tooltip="Click to Upload new Image"
									style={{
										maxHeight: '110px'
									}}
								/>
								</ReactFileReader>
								{this.state.profilePhoto ? <>
									<Button 
										size="xs" 
										variant="danger" 
										onClick={this.handleProfilePhotoRemoveClick} >
										Remove
									</Button>
								</> : null}
							</Col>
						</Row>
					</Col>	
					</Row>
					
					<hr />
					
					<Row>
						<Col md={12} >
							<div className="text-right" >
								<Button 
									variant="success"
									type="submit" 
								>
									{this.state.btnText}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>
				</Spin>
			</div>
		);
	}
	
}
export default AddCentre;
