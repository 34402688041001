 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup ,
} from 'react-bootstrap';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import iziToast from 'izitoast';

import PsContext from '../../context/PsContext';

import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'

const { Option } = Select;

class AddEquipment extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Save Equipment',
			showLoader: false,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/equipment/save', $("#frmAddEquipment_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmAddEquipment_Mod").reset();
					this.setState({
						editorState: BraftEditor.createEditorState(''),
						htmlContent: '',
						showLoader: false,
					});
					message.success(res['data'].message || 'Success');
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({
						showLoader: false
					});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		return(
			<div>
				<Spin spinning={this.state.showLoader} >
					<form 
						action=""
						method="post"
						encType="multipart/form-data" 
						id="frmAddEquipment_Mod"
						onSubmit={this.handleFormSubmit}
					>	
						<input type="hidden" name="api" value={this.context.state.api} />
						
						<Row>
							<Col md={12} >
								<Row className="mt-15" >
									<Col md={12}>
										<label className="font-14" >Equipment Name
											<span className="text-danger">*</span>
										</label>
										<Form.Control
											type="text"
											size="sm"
											name="i__name"
											required
										/>
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={12}>
										<label className="font-14" >Purchase Date
											<span className="text-danger">*</span>
										</label>
										<Form.Control
											type="date"
											size="sm"
											name="i__purchaseDate"
											required
											max={momentDate((new Date()),'YYYY-MM-DD')}
										/>
									</Col>
								</Row>

								<Row className="mt-15" >
									<Col md={12}>
										<label className="font-14" >Purchase From
											<span className="text-danger">*</span>
										</label>
										<Form.Control
											type="text"
											size="sm"
											name="i__purcharsefrom"
											required
										/>
									</Col>
								</Row>

								<Row className="mt-15" >
									<Col md={12}>
										<label className="font-14" >Service Person Name
											<span className="text-danger"></span>
										</label>
										<Form.Control
											type="text"
											size="sm"
											name="i__servicePerson"
										/>
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={12}>
										<label className="font-14" >Service Person Mobile
											<span className="text-danger"></span>
										</label>
										<Form.Control
											type="text"
											size="sm"
											name="i__serviceMobile"
											onKeyPress={integerIndMobile}
										/>
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={12}>
										<label className="font-14" >Next Renewal/Service Date
											<span className="text-danger">*</span>
										</label>
										<Form.Control
											type="date"
											size="sm"
											name="i__renewalDate"
											required
											min={momentDate((new Date()),'YYYY-MM-DD')}
										/>
									</Col>
								</Row>

								<Row className="mt-15" >
									<Col md={12}>
										<label className="font-14" >Description
											<span className="text-danger"></span>
										</label>
										<Form.Control
											as="textarea"
											size="sm"
											name="i__description"
											rows="3"
										/>
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={12} >
										<div className="text-right" >
											<Button
												type="submit"
												variant="success"
											>
												Save Changes
											</Button>
										</div>
									</Col>
								</Row>
								
							</Col>
							
						</Row>					
						
					</form>
				</Spin>
			</div>
		);
	}
	
}
export default AddEquipment;
