import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../../utils';
import PsContext from '../../../context/PsContext';


class Primary extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
		
	}
	
	
	render(){
		
				
		return(
			<React.Fragment>
				
			</React.Fragment>
		);
	}
	
}
export default Primary;
