 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, Modal, ListGroup, Table} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, todayDate, groupByMultiple, numberToMonth
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Drawer, Skeleton, Spin, Select,message, Tooltip, 
} from 'antd';

import PsContext from '../../context/PsContext';
import PayrollMenu from './payrollMenu';
import PayrollSettingsNew from './payrollSettingsNew';

const { Option } = Select;

const styles = {
	table: {
		borderCollapse: 'collapse',
		border: '1px solid black',
		fontSize: '14px',
	},
	thead: {
		borderCollapse: 'collapse',
		border: '1px solid black',
		fontSize: '14px',
		fontWeight: '600',
	}
}

class SalaryAbstract extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showAddModal: false,
			dataList: [],
			dataView: [],
			selectedMonth: '',
			selectedMonthValue: '',
			selectedYearValue: '',
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleMonthChange = this.handleMonthChange.bind(this);
		this.handlePrintClick = this.handlePrintClick.bind(this);
		
	}
	
	componentDidMount(){
		
	}
	
	loadData(){
		try{
			
			if(this.state.selectedMonth.length<4)
			{
				message.error('Select Month');
				return;
			}
			this.setState({
				showLoader: true,
				dataList: [],
				dataView: [],
			});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__date', this.state.selectedMonth);
			form.append('i__month', this.state.selectedMonthValue);
			form.append('i__year', this.state.selectedYearValue);
						
			axios.post(Api.Url+'v1/payroll/listabstract', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false
					});					
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleMonthChange(e){
		var m = new Date(e.target.value).getMonth()+1;
		var y = new Date(e.target.value).getFullYear();
		
		this.setState({
			selectedMonth: e.target.value,
			selectedMonthValue: m,
			selectedYearValue: y,
		});
	}
	
	daysInMonth (month, year) {
		
		return new Date(year, month, 0).getDate();
	}
	
	getSundays(year, month, totalDays){
		
		var sun = new Array();
		for(var i=1;i<=totalDays;i++){    //looping through days in month
			var newDate = new Date(year,month,i)
			if(newDate.getDay()==0){   //if Sunday
				sun.push(i);
			}
		}
	}
	
	
	printDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.atClose)
			 this.props.atClose();
	}
	
	handlePrintClick(){
		this.printDocument("abstract_container");
	}
	
	render(){

		var sno=1;
		var basicTotal = 0
		var lossOfPayTotal = 0;
		var epfTotal = 0;
		var esiTotal = 0;
		var otTotal = 0;
		var netSalaryTotal = 0;
		
		var mon = this.state.selectedMonthValue > 9 ? this.state.selectedMonthValue : '0'+this.state.selectedMonthValue;
		
		return(
			<div>
			
			<PayrollMenu {...this.props} />
				
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} 
				>
					
					<Card>
						<Card.Body>
							<Row>
								<Col md={1} >
									<label>Month</label>
								</Col>
								<Col md={2} >
									<Form.Control
										type="month"
										size="sm"
										max={momentDate(todayDate(), 'YYYY-MM')}
										onChange={this.handleMonthChange} 
									/>
								</Col>
								<Col md={1} >
									<Button 
										type="button" 
										size="sm" 
										variant="warning" 
										onClick={this.loadData} 
									>
										Load
									</Button>
								</Col>
								<Col md={8 }>
									<div className="text-right" >
										{Object.keys(this.state.dataList).length>0 ?
											<Button
												size="sm"
												variant="secondary"
												type="button"
												onClick={this.handlePrintClick}
											>
												<i className="icofont-printer" ></i>
												&nbsp; Print Abstract
											</Button>
										: null}
									</div>
								</Col>
							</Row>
						</Card.Body>
					</Card>
					{Object.keys(this.state.dataList).length>0 ?
					<Card className="mt-15" >
						<Card.Body>
							<div id="abstract_container" >
							<table width="100%" style={styles.table} >
								<tbody style={styles.table} >
									<tr>
										<td colSpan="10" align="center" >
											<br />
											<b style={{
												fontSize: '18px',
												textTransform: 'uppercase',
											}}>{this.context.state.loggedUser.name}</b>
										</td>
									</tr>
									<tr>
										<td colSpan="10" align="center" >
											<b style={{
												textTransform: 'uppercase',
											}}>SALARY FOR THE MONTH OF {numberToMonth(mon)} {this.state.selectedYearValue}</b>
											<br />
											<br />
										</td>
									</tr>
									<tr style={styles.thead} >
										<td height="30" align="center" >S.No</td>
										<td>Employee Code</td>
										<td>Employee Name</td>
										<td align="right" >Basic Salary</td>
										<td align="right" >Loss Of Pay</td>
										<td align="right" >EPF</td>
										<td align="right" >ESI</td>
										<td align="right" >OT</td>
										<td align="right" >Net Salary</td>
										<td align="center" width="160" >Signature</td>
									</tr>
								
								{this.state.dataView.map((item,i)=>{
									basicTotal = parseFloat(basicTotal) + parseFloat(item.basic_salary);
									lossOfPayTotal = parseFloat(lossOfPayTotal) + parseFloat(item.loss_of_pay_amount);
									epfTotal = parseFloat(epfTotal) + parseFloat(item.pf_amount);
									esiTotal = parseFloat(esiTotal) + parseFloat(item.esi_amount);
									otTotal = parseFloat(otTotal) + parseFloat(item.ot_amt);
									netSalaryTotal = parseFloat(netSalaryTotal) + parseFloat(item.net_salary);
									return <tr key={i} style={styles.table} >
										<td align="center" height="35" >{sno++}</td>
										<td>{item.employee_code}</td>
										<td>{item.firstname} {item.lastname}</td>
										<td align="right" >{item.basic_salary}</td>
										<td align="right" >{item.loss_of_pay_amount}</td>
										<td align="right" >{item.pf_amount}</td>
										<td align="right" >{item.esi_amount}</td>
										<td align="right" >{item.ot_amt}</td>
										<td align="right" >{item.net_salary}</td>
										<td></td>
									</tr>;
								})									
								}
								<tr style={styles.thead} >
									<td colSpan="3" align="right"> Total &emsp;</td>
									<td align="right">{parseFloat(basicTotal).toFixed(2)}</td>
									<td align="right">{parseFloat(lossOfPayTotal).toFixed(2)}</td>
									<td align="right">{parseFloat(epfTotal).toFixed(2)}</td>
									<td align="right">{parseFloat(esiTotal).toFixed(2)}</td>
									<td align="right">{parseFloat(otTotal).toFixed(2)}</td>
									<td align="right">{parseFloat(netSalaryTotal).toFixed(2)}</td>
									<td></td>
								</tr>
								</tbody>
							</table>	
							</div>
						</Card.Body>
					</Card> : null}
					
				</Spin>
				
				
			</div>
		);
	}
	
}
export default SalaryAbstract;
