 import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';

class AccountsMenu extends Component{
		
	constructor(props){
		super(props);
		this.state = {
			
		}
	}
	
	componentDidMount(){
		
		
	}
	
	
	render(){
		
		const menus = [
			{key: '1', to: '/app/accounts', name: 'Daily Accounts Report'},
			{key: '2', to: '/app/accounts/head', name: 'Account Head'},
		];
		
		return(
			<ul className="nav nav-tabs">
			{menus.map((item,i)=>{
			  return <li className="nav-item">
				<NavLink to={item.to} className="nav-link" key={item.key} exact>{item.name}</NavLink>
			  </li>;
			})
			}
			</ul>
		);
	}
	
}
export default AccountsMenu;
