 import React, { Component } from 'react';
import {Redirect, NavLink} from 'react-router-dom';


class ReceiptMenu extends Component{
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
		}
	}
	
	render(){

		
		
		return(
			<ul className="nav nav-tabs">
			  <li className="nav-item">
				<NavLink to="/app/receipts" className="nav-link" exact>Receipts</NavLink>
			  </li>
			  <li className="nav-item">
				<NavLink to="/app/receipts/new" className="nav-link" exact>Receipt Entry</NavLink>
			  </li>
			</ul>
		);
	}
	
}
export default ReceiptMenu;
