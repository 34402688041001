 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup , Table,
} from 'react-bootstrap';
import {
  Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch, Tabs 
} from 'antd';

import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../../utils';
import iziToast from 'izitoast';

import PsContext from '../../../context/PsContext';
import AddCentre from './addCentre';

const { Option } = Select;
const { TabPane } = Tabs;

class SmsSettings extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			clubData: this.props.data,
			smsData: [],
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('fitness_key', this.getClubDetails('fitness_key'));
			axios.post(Api.Url+'v1/ma/smssettings/list', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						smsData: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getClubDetails(fieldName){
		try{
			var d = this.state.clubData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	getField(fieldName){
		try{
			var d = this.state.smsData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			
			axios.post(Api.Url+'v1/ma/settings/savesms', $("#frmSmsSettings").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					this.setState({showLoader: false});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		return(
			
			<Spin
				spinning={this.state.showLoader}
			>
			<Card>
				<Card.Body>
					<form 
						action="" 
						method="post" 
						encType="multipart/form-data" 
						id="frmSmsSettings" 
						onSubmit={this.handleFormSubmit}
					>
						<input type="hidden" name="api" value={this.context.state.api} />
						<input type="hidden" name="fitness_key" value={this.getClubDetails('fitness_key')} />
						<Row>
							<Col md={4} >
								<label className="font-13" >
									Service Request URL
									<span  className="text-danger" >
										 *
									</span>
								</label>
							</Col>
							<Col md={7} >
								<Form.Control 
									type="url"
									size="sm"
									required
									name="i__serviceUrl"
									autoComplete="off"
									defaultValue={this.getField('service_url')}
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={4} >
								<label className="font-13" >
									Sender Id
									<span  className="text-danger" >
										 *
									</span>
								</label>
							</Col>
							<Col md={7} >
								<Form.Control 
									type="text"
									size="sm"
									required
									name="i__senderId"
									autoComplete="off"
									style={{textTransform: 'uppercase'}}
									placeholder="6 digit only"
									defaultValue={this.getField('sender_id')}	
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={4} >
								<label className="font-13" >
									Api Key
									<span  className="text-danger" >
										 
									</span>
								</label>
							</Col>
							<Col md={7} >
								<Form.Control 
									type="text"
									size="sm"
									name="i__apiKey"
									autoComplete="off"
									defaultValue={this.getField('api_key')}	
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={4} >
								<label className="font-13" >
									Route
									<span  className="text-danger" >
										 
									</span>
								</label>
							</Col>
							<Col md={7} >
								<Form.Control 
									type="number"
									size="sm"
									name="i__route"
									autoComplete="off"
									defaultValue={this.getField('route')}	
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={4} >
								<label className="font-13" >
									Username
									<span  className="text-danger" >
										 
									</span>
								</label>
							</Col>
							<Col md={7} >
								<Form.Control 
									type="text"
									size="sm"
									name="i__username"
									autoComplete="off"
									defaultValue={this.getField('username')}	
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={4} >
								<label className="font-13" >
									Password
									<span  className="text-danger" >
										 
									</span>
								</label>
							</Col>
							<Col md={7} >
								<Form.Control 
									type="text"
									size="sm"
									name="i__password"
									autoComplete="off"
									defaultValue={this.getField('password')}	
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={11} >
								<div className="text-right" >
									<Button
										type="submit"
										variant="success"
										size="sm"
									>
										Save Settings
									</Button>
								</div>
							</Col>
						</Row>
					
					</form>
				</Card.Body>
			</Card>
				
			</Spin>
							
		);
	}
	
}
export default SmsSettings;
