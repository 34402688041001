 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, Modal} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';

import stateDistricts from '../../../utils/stateDistricts.json';

import PsContext from '../../context/PsContext';

const { Option } = Select;

class ChangePassword extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showChangePassword: false,
			loggedUser: [],
			stateValue: '',
			districtValue: '',
			districtList: [],
			btnText: 'Change Password',
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	
	handleFormSubmit(e){
		e.preventDefault();
		try{			
			 this.setState({showLoader: true, btnText: '...'});
			axios.post(Api.Url+'v1/pass/update_user_pass', $("#frm_Mod_ChangeUser_Pass").serialize()).then(res=>{
				
				if(res['data'].status === '1'){
										
					document.getElementById("frm_Mod_ChangeUser_Pass").reset();
					message.success(res['data'].message || 'Success');
					this.setState({showLoader: false, btnText: 'Change Password'});
				}
				else{
					this.setState({showLoader: false, btnText: 'Change Password'});
					message.error(res['data'].message || 'Error');
				}
			});	 
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false, btnText: 'Change Password'});
		}
	}
	
		
	
	render(){

		
		
		return(
			<div>

				<form action="" method="post" encType="multipart/form-data" id="frm_Mod_ChangeUser_Pass" onSubmit={this.handleFormSubmit} >
					
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="id" value={this.context.state.loggedUser.id} />
				
					<Row>
						<Col md={4} >
							<label>Old Password 
								<span className="text-danger"> *</span>
							</label>
						</Col>
						<Col md={8} >
							<Form.Control 
								type="password" 
								name="i__oldPassword"
								size="sm"	
								placeholder="Old Password" 
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={4} >
							<label>New Password 
								<span className="text-danger"> *</span>
							</label>
						</Col>
						<Col md={8} >
							<Form.Control 
								type="password" 
								name="i__newPassword"
								size="sm"	
								placeholder="New Password" 
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={4} >
							<label>Confirm Password 
								<span className="text-danger"> *</span>
							</label>
						</Col>
						<Col md={8} >
							<Form.Control 
								type="password" 
								name="i__confirmPassword"
								size="sm"	
								placeholder="Confirm Password" 
								required
							/>
						</Col>
					</Row>
					
					<hr />
					
					<Row>
						<Col md={12} >
							<div className="text-center" >
								<Button type="submit" variant="success" disabled={this.state.btnText!='Change Password'} >
									{this.state.btnText} 
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>							
			
			</div>
		);
	}
	
}
export default ChangePassword;
