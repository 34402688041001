import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import AddEnquiry from './add';
import Edit from './Edit';

class GymEnquries extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddDrawer: false,
			showDeleteLoader: false,
			showEditDrawer: false,
			editData: [],
			dataList: [],
			dataView: [],
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleAddTrainderClick = this.handleAddTrainderClick.bind(this);
		this.handleAddDrawerCloseClick = this.handleAddDrawerCloseClick.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
		this.handleEditDrawerCloseClick = this.handleEditDrawerCloseClick.bind(this);
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showDeleteLoader: true});
			axios.get(Api.Url+'v1/enquires/all?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showDeleteLoader: false
					});
				}
				else{
					this.setState({showDeleteLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showDeleteLoader: false});
		}
	}
	
	handleAddTrainderClick() {
		this.setState({showAddDrawer: true});
	}
	
	handleAddDrawerCloseClick(){
		this.setState({showAddDrawer: false});
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return( (upperCase(obj.name).indexOf(v)>-1) || 
				//(upperCase(obj.endtime).indexOf(v)>-1) || 
				(upperCase(obj.mobile).indexOf(v)>-1));
			});
			
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleDeleteActionClick(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: `Do you want to delete the enquiry ?`,
			buttons: {
				Yes: function(){ 
					$this.handleDeleteConfirm(record);
				},
				No: function() { }
			}
		});
	}
	
	handleDeleteConfirm(record){
		try{
			this.setState({showDeleteLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__id', record.id);
			
			axios.post(Api.Url+'v1/enquiry/remove', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					/*var index = d.findIndex(function(obj){
						return(obj.id==record.id);
					});
					d.splice(index,1);*/
					var dat = d.filter(function(obj){
						return(obj.id != record.id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						showDeleteLoader: false
					});
					//this.loadData();
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showDeleteLoader: false});
				}
			});
		}
		catch(error){
			message.error('Unknown Error');
			this.setState({showDeleteLoader: false});
		}
	}
	
	handleEditActionClick(record){
		this.setState({
			editData: record,
			showEditDrawer: true,
		});
	}
	
	handleEditDrawerCloseClick(){
		this.setState({
			editData: [],
			showEditDrawer: false,
		});
	}
	
	
	render(){

		if(this.state.showLoader)
			return(<SpinLoader loading={this.state.showLoader} />);
		
		const columns = [
			{
				title: 'S.No',
				dataIndex: 'sno',
				key: 'sno',
				render: (text, record, index)=>(
					<>
						{index+1}
					</>
				)
			},
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Mobile',
				dataIndex: 'mobile',
				key: 'mobile',
			},
			{
				title: 'Gender / Age',
				dataIndex: 'gender',
				key: 'gender',
				render: (text, record)=>(
					<>
					{upperCase(record.gender)} / {record.age}
					</>
				)
			},
			{
				title: 'Reason',
				dataIndex: 'reason',
				key: 'reason',
			},
			{
				title: 'Follow up date',
				dataIndex: 'next_followup',
				key: 'next_followup',
			},
			{
				title: 'Manage',
				dataIndex: 'id',
				key: 'id',
				render: (text,record)=> (
					<center>
						<Tooltip title="Click to Edit" >
							<Button type="button" size="xs" variant="warning" onClick={this.handleEditActionClick.bind(this, record)} >
								<i className="icofont-edit" />
							</Button>
						</Tooltip>
						&nbsp;
						<Tooltip title="Click to Delete" >
							<Button type="button" size="xs" variant="danger" onClick={this.handleDeleteActionClick.bind(this, record)} >
								<i className="icofont-trash" />
							</Button>
						</Tooltip >
					</center>
				)
			},
		];
				
		return(
			<React.Fragment>
				
				
					<Card>
						<Card.Body>
							<Row>
								<Col md={9} >
									<span className="input-icon">
										<i className="material-icons" >search</i>
										<Form.Control  
											size="sm" 
											placeholder="Search by Name, Mobile" 
											onChange={this.handleSearchOnChange} />
									  </span>
								 </Col>
								 <Col md={3} >
									<div className="text-right" >
										<Button size="sm" variant="secondary" onClick={this.loadData} >
											<i className="icofont-refresh" /> Refresh
										</Button>
										&nbsp;
										<Button size="sm" variant="secondary" onClick={this.handleAddTrainderClick} >
											<i className="icofont-plus" /> Add Enquiry
										</Button>
									</div>
								 </Col>
							</Row>
						</Card.Body>
					</Card>
					
					<div className="mt-10 ps-table" >
						<Table 
							size="small"
							columns={columns} 
							loading={this.state.showDeleteLoader}
							dataSource={this.state.dataView} />
					</div>
					
					<Drawer 
						visible={this.state.showAddDrawer} 
						placement="right" 
						title="Add Enquiry" 
						width="420"
						onClose={this.handleAddDrawerCloseClick} >						
						{this.state.showAddDrawer ?
							<AddEnquiry 
								afterFinish={this.loadData} />
						: null}						
					</Drawer>
					
					<Drawer 
						visible={this.state.showEditDrawer} 
						placement="right" 
						title="Edit Enquiry" 
						width="420"
						onClose={this.handleEditDrawerCloseClick} >
						
						{this.state.showEditDrawer ?
							<Edit 
								editData={this.state.editData}
								afterFinish={this.loadData} />
						: null}
					</Drawer>
			
			</React.Fragment>
		);
	}
	
}
export default GymEnquries;
