import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Tabs, Tab, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import axios from 'axios';
import { Api } from '../../../utils/Variables';
import {
	Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch
  } from 'antd';
import $ from 'jquery';
import PsContext from '../../context/PsContext';

class Offers extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			productList : [],
			product : [],
			enquiryList : [],
			showModal : false,
		}
		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/product/enquiry', $("#frmEnquiry_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmEnquiry_Mod").reset();
					this.setState({
						showModal : false,
						showLoader: false,
					});
					message.success(res['data'].message || 'Success');
					
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({
						showLoader: false
					});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}

	handleModalOpen(data){
		this.setState({product : data,showModal: true});
	}

	handleModalClose(){
		this.setState({showModal: false});
	}
	
	componentWillMount(){
		this.loadData();
		this.loadEnquiry();
	}
	
	loadData()
	{
		try{
			this.setState({showLoader: true});
			
			axios.get(Api.Url+'v1/ma/products?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					
					var dat = res['data'].data.filter(function(obj){
						return(obj.product_active_status == 'active');
					})

					this.setState({
						productList : dat,
						showLoader: false,
					});
					
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			
			this.setState({showLoader: false});
		}
	}

	loadEnquiry(){

		try{
			this.setState({showLoader: true});
			
			axios.get(Api.Url+'v1/product/enquires?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						enquiryList : res['data'].data,
						showLoader: false,
					});
					
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			
			this.setState({showLoader: false});
		}
	}

	checkenquiry(productId){

		var d = this.state.enquiryList;
		var bstate = true;
		var index = d.findIndex(function(obj){
			return(obj.product == productId);
		});

		if(index > -1){
			bstate = false;
		}
		return bstate;
	}
	
	render(){

		var p = this.state.product;

		return(
			<React.Fragment>
				<Spin spinning={this.state.showLoader} >
				<Row>
					{this.state.productList.map((item,i)=>{
						var offamt = parseFloat(item.amount*item.offer_percentage/100);
						return(<Col md={3} style={{cursor : 'pointer'}} onClick={this.handleModalOpen.bind(this,item)}>
							<Card>
								<Card.Body>
									<img src={item.image} width="250px" height="200px" /><br /><br />
									{item.name}<br />
									<i style={{fontSize : '14px'}}><b>Rs. {item.offer_percentage!='' ? item.amount-offamt : parseFloat(item.amount)}</b>&emsp;{item.offer_percentage!='' ? <span style={{color : 'grey',textDecoration : 'line-through'}}>Rs.{parseFloat(item.amount)}</span>: ''}&emsp;{item.offer_percentage!='' ? <b className="text-success">{item.offer_percentage}% off</b> : ''}</i>
								</Card.Body>
							</Card>
						</Col>);
					})}
				</Row>

				<Modal 
					show={this.state.showModal} 
					onHide={this.handleModalClose}  
					dialogClassName=""
					backdrop="static"
					size="lg" 
					keyboard={false}
				>
					<Modal.Header closeButton >
						View Product
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col md={6}>
								<img src={p.image} width="380px" height="400px" />
							</Col>
							<Col md={6}>
								<b style={{fontSize : '22px'}}>{p.name}</b><br />
								<p>{p.description}</p>
								<b>Rs. {p.offer_percentage!='' ? p.amount-parseFloat(p.amount*p.offer_percentage/100) : parseFloat(p.amount)}</b>&emsp;{p.offer_percentage!='' ? <span style={{color : 'grey',textDecoration : 'line-through'}}>Rs.{parseFloat(p.amount)}</span>: ''}&emsp;{p.offer_percentage!='' ? <b className="text-success">{p.offer_percentage}% off</b> : ''}<br /><br />
								{this.checkenquiry(p.id) ?
								<form 
									action=""
									method="post"
									encType="multipart/form-data" 
									id="frmEnquiry_Mod"
									onSubmit={this.handleFormSubmit}
								>	
									<input type="hidden" name="api" value={this.context.state.api} />
									<input type="hidden" name="i__pid" value={p.id} />
									
									<Form.Control
										as="textarea"
										size="sm"
										name="i__content"
										rows="3"
										placeholder="Enquiry content"
										required
									/>
									<br />
									<Button
										type="submit"
										variant="success"
									>
										Enquiry Now
									</Button>
								</form> : 
								<span style={{color : 'red'}}>Enquiry already sent.</span>
								}
							</Col>
						</Row>
					</Modal.Body>
				</Modal>
				</Spin>
			</React.Fragment>
		);
	}
	
}
export default Offers;
