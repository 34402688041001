const COOKIE_KEY = 'kal512';

// LIVE
/*
const Api = {
  Url: 'https://efc.hostinglive.in/api/',
  ImageUrl: 'https://efc.hostinglive.in/api/public/uploads/', // Dont put slash in last
  SiteUrl: 'https://efc.hostinglive.in',
  version: 'v1/',
};
*/

// TEST

const Api = {
  Url: 'https://excelgymsoft.com/api/',
  ImageUrl: 'https://excelgymsoft.com/api/public/uploads/', // Dont put slash in last
  SiteUrl: 'https://excelgymsoft.com/',
  version: 'v1/',
};


// LOCAL
/*
const Api = {
  Url: 'http://localhost/excel/api/',
  ImageUrl: 'http://localhost/excel/api/public/uploads', // Dont put slash in last
  SiteUrl: 'http://localhost/excel',
  version: 'v1/',
};
*/


const Var = {
  Title: 'Excel SAAS',
  Site: 'https://efc.hostinglive.in',
  Phone: '9487900120',
  Email: 'support@peacesoft.in',
  Address: '',
  City: 'Tirunelveli',
  Pincode: ' 627002',
  RegularCourseFeeForScSt: 0,
  RegularCourseFeeForOthers: 50,
  SelfCourseFeeForScSt: 50,
  SelfCourseFeeForOthers: 50,
  EnableCashPayment: true,
  EnableOnlinePayment: true,
};

export {
  COOKIE_KEY,
  Api,
  Var,
};
