 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, Modal, ListGroup} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';

import stateDistricts from '../../../utils/stateDistricts.json';

import PsContext from '../../context/PsContext';
import ReceiptMenu from './receiptMenu';
import ReceiptEntry_Form from '../Member/receiptEntry';

const { Option } = Select;

class ReceiptEntry extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			dataList: [],
			dataView: [],
		}
		
		this.loadReceipts = this.loadReceipts.bind(this);
	}
	
	componentDidMount(){
		
		this.loadReceipts();
	}
	
	loadReceipts()
	{
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/receipts/list_recent?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){

		
		
		return(
			<div>
				
				<ReceiptMenu />
				
				<Row>
					<Col md={8} >
							
						<Card>
							<Card.Body>
								
								<ReceiptEntry_Form />
								
							</Card.Body>
						</Card>
						
					</Col>
					<Col md={4} >
						
						<Spin 
							size="large"
							tip="Please wait..."
							spinning={this.state.showLoader} >
							<div 
								style={{minHeight: 'calc(100vh - 300px)'}}
								className="mt-20" 
							>
							<h5>Recent Receipts
								<div style={{float: 'right'}} >
									<Button
										type="button"
										variant="secondary" 
										size="sm" 
										onClick={this.loadReceipts}
									>
										<i className="icofont-refresh" ></i>
									</Button>
								</div>
							</h5>
							<hr style={{borderTop: '1px solid #ced8d8'}} />
								
								<ListGroup>
									{this.state.dataView.map((item,i)=>{
										return <ListGroup.Item>											
											<div>
												{item.firstname} {item.lastname}
												<span style={{float: 'right'}} >
													{item.member_code}
												</span>
											</div>
											<div className="font-13" >
												{item.recort_type} 
												<br />
												{momentDate(item.created_on, 'DD-MM-YYYY')}
												<span 
													className="font-weight-600"
													style={{
														float: 'right', 
														fontSize: '18px'
													}} 
												>
													<i className="icofont-rupee" ></i>
													{item.paid_amount}
												</span>
											</div>
										</ListGroup.Item>;
									})
									}
								</ListGroup>
								
							</div>						
						</Spin>
					</Col>
				</Row>
				
			</div>
		);
	}
	
}
export default ReceiptEntry;
