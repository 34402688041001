import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../../utils';
import PsContext from '../../../context/PsContext';


class MessageWishes extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		document.getElementById("header_title").innerHTML = 'Message Template';
	}	
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			
			axios.post(Api.Url+'v1/ma/settings/savesms', $("#frmSmsSettings").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					this.setState({showLoader: false});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
				
		return(
			<Card>
			<Card.Body>
				<form 
					action=""
					method="post"
					encType="multipart/form-data" 
					id="frmMsgWishes"
					onSubmit={this.handleFormSubmit}
				>
					<input type="hidden" name="api" value={this.context.state.api} />
					<Row>
						<Col md={12} >
							Message Template
							<hr />
								<code>[name]</code> - to display name of the member <br />
						</Col>
						
						<Col md={12} className="mt-15" >
							<label>Birthday Message</label>
							<Form.Control
								as="textarea"
								rows="6"
								required
								name="i__birthdayMessage"
							/>
						</Col>
						
						<Col md={12} className="mt-15" >
							<div className="text-right" >
								<Button
									type="submit"
									variant="success"
									size="md"
								>
									Update Template
								</Button>
							</div>
						</Col>
						
					</Row>
				</form>
			</Card.Body>
			</Card>
		);
	}
	
}
export default MessageWishes;
