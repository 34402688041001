 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, Modal, ListGroup} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';

import PsContext from '../../context/PsContext';
const { Option } = Select;

class PayrollSettingsNew extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showAddModal: false,
			dataList: [],
			dataView: [],
			employeeList: [],
			employeeFiltered: [],
			selectedEmployee: [],
			pfPercentage: '12',
			esiPercentage: '1.75',
			basicSalary: '',
			esiAmount: '',
			pfAmount: '',
		}
		
		this.handleBaiscChange = this.handleBaiscChange.bind(this);
		this.handlePfPercentageChange = this.handlePfPercentageChange.bind(this);
		this.handleEsiPercentageChange = this.handleEsiPercentageChange.bind(this);
		this.handleEmployeechange = this.handleEmployeechange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/trainers/all?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					var s = this.props.existing;
					var ar = [];
					if(!s || Object.keys(s).length<1)
					{
						ar = res['data'].data;
					}
					else
					{
						res['data'].data.map((item, i)=>{
							s.map((j,k)=>{
								if(item.id != j.employee_id)
									ar.push(item);
							});
						});
					}
					
					this.setState({
						employeeList: res['data'].data,
						employeeFiltered: ar,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleEmployeechange(e){
		try{
			var d = this.state.employeeList.filter(function(obj){
				return (obj.id==e);
			});
			this.setState({selectedEmployee: d[0]});
		}
		catch(error){ }
	}
	
	handleBaiscChange(e){
		try{
			var pf = ((parseFloat(e.target.value) / 100) * parseFloat(this.state.pfPercentage));
			var esi = ((parseFloat(e.target.value) / 100) * parseFloat(this.state.esiPercentage));
			this.setState({
				basicSalary: e.target.value,
				pfAmount: pf,
				esiAmount: esi,
			});		
		}
		catch(error){ }
	}
	
	handlePfPercentageChange(e){
		try{
			var pf = ((parseFloat(this.state.basicSalary) / 100) * parseFloat(e.target.value));
			this.setState({
				pfPercentage: e.target.value,
				pfAmount: pf,
			});
		}
		catch(error){ }
	}
	
	handleEsiPercentageChange(e){
		try{
			var esi = ((parseFloat(this.state.basicSalary) / 100) * parseFloat(e.target.value));
			this.setState({
				esiPercentage: e.target.value,
				esiAmount: esi,
			});
		}
		catch(error){ }
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if(Object.keys(this.state.selectedEmployee).length<1)
			{
				message.error('Select Employee');
				return;
			}
			
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/payroll/savesettings', $("#frm_AddPay_set").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					document.getElementById("frm_AddPay_set").reset();
					this.setState({
						selectedEmployee: [],
						pfPercentage: '12',
						esiPercentage: '1.75',
						basicSalary: '',
						esiAmount: '',
						pfAmount: '',
						showLoader: false,
					});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({
						showLoader: false,
					});
				}
			});
			
		}
		catch(error){
			this.setState({
				showLoader: false
			});
		}
	}
	
	render(){

		return(
			<div>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<form 
					action=""
					method="post"
					encType="multipart/form-data" 
					id="frm_AddPay_set" 
					onSubmit={this.handleFormSubmit} 
				>
				
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="i__id" value={this.state.selectedEmployee.id} />
					<input type="hidden" name="i__trainerId" value={this.state.selectedEmployee.trainerid} />
					
					<Row>
						<Col md={3} >
							<label>Employee</label>
						</Col>
						<Col md={9} >
							<Select
							  showSearch
							  style={{ width: '100%' }}
							  placeholder="Select a Employee"
							  optionFilterProp="children"
							  onChange={this.handleEmployeechange}
							  style={{width: '100%'}}
							  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							>
							  {this.state.employeeFiltered.map((item) => (
								<Option key={item.id} value={item.id}>{item.trainerid} - {item.firstname} {item.lastname} ({item.mobile})</Option>
							  ))}
							</Select>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={3} >
							<label>Basic</label>
						</Col>
						<Col md={9} >
							<Form.Control
								type="number"
								name="i__basic"
								size="sm"
								onKeyPress={decimalKeyPress} 
								onChange={this.handleBaiscChange}
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-5" >
						<Col md={3} >
							<label>ESI</label>
						</Col>
						<Col md={4} >
							<label className="font-13" > % </label>
							<Form.Control
								type="number"
								name="i__esiPercentage"
								size="sm"
								onKeyPress={decimalKeyPress} 
								onChange={this.handleEsiPercentageChange}
								defaultValue={this.state.esiPercentage}
								required
							/>
						</Col>
						<Col md={5} >
							<label className="font-13"  > Amount </label>
							<Form.Control
								type="number"
								name="i__esiAmount"
								size="sm"
								onKeyPress={decimalKeyPress} 
								value={this.state.esiAmount}
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={3} >
							<label>PF</label>
						</Col>
						<Col md={4} >
							<Form.Control
								type="number"
								name="i__pfPercentage"
								size="sm"
								onKeyPress={decimalKeyPress} 
								onChange={this.handlePfPercentageChange}
								defaultValue={this.state.pfPercentage}
								required
							/>
						</Col>
						<Col md={5} >
							<Form.Control
								type="number"
								name="i__pfAmount"
								size="sm"
								onKeyPress={decimalKeyPress} 
								value={this.state.pfAmount}
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={3} >
							<label>Leave per Month</label>
						</Col>
						<Col md={4} >
							<Form.Control
								type="number"
								name="i__leavePerMonth"
								size="sm"
								onKeyPress={integerKeyPress} 
								required
							/>
						</Col>
					</Row>
					
					<hr />
					
					<Row>
						<Col md={12} >
							<div className="text-center" >
								<Button
									type="submit"
									variant="success"
									size="sm"
								>
									Save Payroll Settings
								</Button>
							</div>
						</Col>
					</Row>
				
				</form>
			
			</Spin>
			</div>
		);
	}
	
}
export default PayrollSettingsNew;
