 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, Modal, ListGroup, Table} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, todayDate, groupByMultiple
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Drawer, Skeleton, Spin, Select,message, Tooltip, 
} from 'antd';

import PsContext from '../../context/PsContext';
import PayrollMenu from './payrollMenu';
import PayrollSettingsNew from './payrollSettingsNew';

const { Option } = Select;

class GenerateSalary extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showAddModal: false,
			dataList: [],
			dataView: [],
			employeeList: [],
			selectedEmployee: [],
			salaryAbstract: [],
			salary: [],
			attendance: [],
			selectedMonth: '',
			selectedMonthValue: '',
			selectedYearValue: '',
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleMonthChange = this.handleMonthChange.bind(this);
		this.handleSaveAbstractClick = this.handleSaveAbstractClick.bind(this);
		
	}
	
	componentDidMount(){
		
	}
	
	loadData(){
		try{
			
			if(this.state.selectedMonth.length<4)
			{
				message.error('Select Month');
				return;
			}
			this.setState({
				showLoader: true,
				employeeList: [],
				salary: [],
				attendance: [],
				salaryAbstract: [],
			});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__date', this.state.selectedMonth);
						
			axios.post(Api.Url+'v1/payroll/employeelogs', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						employeeList: res['data'].employees,
						salary: res['data'].salary,
						attendance: res['data'].attendance,
						showLoader: false
					});
					
					this.generateSalaryAbstract();
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleMonthChange(e){
		var m = new Date(e.target.value).getMonth()+1;
		var y = new Date(e.target.value).getFullYear();
		
		this.setState({
			selectedMonth: e.target.value,
			selectedMonthValue: m,
			selectedYearValue: y,
		});
	}
	
	daysInMonth (month, year) {
		
		return new Date(year, month, 0).getDate();
	}
	
	getSundays(year, month, totalDays){
		
		var sun = new Array();
		for(var i=1;i<=totalDays;i++){    //looping through days in month
			var newDate = new Date(year,month,i)
			if(newDate.getDay()==0){   //if Sunday
				sun.push(i);
			}
		}
	}
	
	getLeaveDays(empCode, totalDaysInMonth){
		try{
			var s = this.state.attendance;
			var d = s.filter(function(obj){
				return(obj.UserId==empCode);
			});
			
			var g = groupByMultiple(d, function(obj){
				return [momentDate(obj.LogDate, 'DD-MM-YYYY')];
			});
			
			return (totalDaysInMonth - Object.keys(g).length);
		}
		catch(error){ }
	}
	

	 calculateLossOfPayAmount(basic, totalDaysInMonth, leaveAllowed, leaveTaken){
		 try{
			 var netPay = 0;
			 var daySalary = parseFloat(basic)/parseFloat(totalDaysInMonth);
			 if(parseInt(leaveTaken) > parseInt(leaveAllowed))
			 {
				 var losDays = parseInt(leaveTaken) - parseInt(leaveAllowed);
				var daysPresent = parseInt(totalDaysInMonth) - parseFloat(losDays);
				netPay = (parseFloat(daySalary) * parseFloat(losDays)).toFixed(2);
			 }
			 
			 return netPay;
		 }
		 catch(error){
			 
		 }
	 }
	 
	 calculateNetPayAmount(item, basic, loosOfPayAmount){
		 try{
			 /* var daySalary = parseFloat(basic)/parseFloat(totalDaysInMonth);
			 var daysPresent = parseInt(totalDaysInMonth) - parseFloat(lossOfPayDays);
			 var netPay = (parseFloat(daySalary) * parseFloat(daysPresent)).toFixed(2); */
			 var netPay = parseFloat(basic);
			 var pfesi = parseFloat(item.pf_amount) + parseFloat(item.esi_amount);
			 pfesi = parseFloat(loosOfPayAmount) + parseFloat(pfesi);
			 var otamt = parseFloat(item.ot_amt);
			 return (netPay - pfesi).toFixed(2);
		 }
		 catch(error){
			 
		 }
	 }
	
	getEmployeeSalarySettings(empId, empCode){
		try{
			var d = this.state.salary;
			var s = d.filter(function(obj){
				return(obj.employee_id==empId && obj.employee_code==empCode);
			});
			
			return s[0];
		}
		catch(error){
			return false;
		}
	}
	
	calculatePfAmount(item){
		try{
			var basic = item.basic_salary;
			var pfAmount = item.pf_amount;
			return (parseFloat(basic) - parseFloat(pfAmount)).toFixed(2);
		}
		catch(error){ }
	}
	
	calculateEsiAmount(item){
		try{
			var basic = item.basic_salary;
			var esiAmount = item.esi_amount;
			return (parseFloat(basic) - parseFloat(esiAmount)).toFixed(2);
		}
		catch(error){ }
	}
	
	generateSalaryAbstract(){
		try{
			var sb = [];
			this.state.employeeList.map((employee,i)=>{
				var item = this.getEmployeeSalarySettings(employee.id, employee.trainerid);
				var totalDaysInMonth = 0;
				var basicSalary = 0;
				var leaveAllowd = 0;
				var leaveTaken = 0;
				var lossOfPay = 0;
				var lossOfPayAmount = 0;
				var netSalary = 0;
				var pfAmount = 0;
				var ot_amt = 0;
				var esiAmount = 0;
				if(item)
				{
					basicSalary = item.basic_salary;
					totalDaysInMonth = this.daysInMonth(this.state.selectedMonthValue, this.state.selectedYearValue);
					leaveAllowd = item.allowed_leave_per_month;
					leaveTaken = this.getLeaveDays(item.employee_code, totalDaysInMonth);
					lossOfPay = (leaveTaken==totalDaysInMonth) ? leaveTaken : parseInt(leaveTaken) - parseInt(leaveAllowd);
					lossOfPayAmount = this.calculateLossOfPayAmount(item.basic_salary, totalDaysInMonth, leaveAllowd, leaveTaken); 
					//netSalary = this.calculateNetPayAmount(item, item.basic_salary, totalDaysInMonth, lossOfPay);
					ot_amt = item.ot_amt;
					netSalary = this.calculateNetPayAmount(item, item.basic_salary,lossOfPayAmount);
					pfAmount = item.pf_amount; //this.calculatePfAmount(item);
					esiAmount = item.esi_amount; //this.calculateEsiAmount(item);
				}
				sb.push({
					employee_id: employee.id,
					employee_code: employee.trainerid,
					employee_name: employee.firstname + ' '+employee.lastname,
					mobile: employee.mobile,
					daysinmonth: totalDaysInMonth,
					leaveallowd: leaveAllowd,
					actual_leavetaken: leaveTaken,
					leavetaken: leaveTaken,
					lossofpaydays: lossOfPay,
					ot_amt : ot_amt,
					basicsalary: basicSalary,
					lossofpayamount: lossOfPayAmount,
					pf_amount: pfAmount,
					esi_amount: esiAmount,
					netsalary: Math.round(parseFloat(netSalary)).toFixed(2),
				});

			});
			/**
			{this.state.employeeList.map((employee,i)=>{
				var item = this.getEmployeeSalarySettings(employee.id, employee.trainerid);
				var totalDaysInMonth = 0;
				var basicSalary = 0;
				var leaveAllowd = 0;
				var leaveTaken = 0;
				var lossOfPay = 0;
				var lossOfPayAmount = 0;
				var netSalary = 0;
				if(item)
				{
					basicSalary = item.basic_salary;
					totalDaysInMonth = this.daysInMonth(this.state.selectedMonthValue, this.state.selectedYearValue);
					leaveAllowd = item.allowed_leave_per_month;
					leaveTaken = this.getLeaveDays(item.employee_code, totalDaysInMonth);
					lossOfPay = parseInt(leaveTaken) - parseInt(leaveAllowd);
					lossOfPayAmount = this.calculateLossOfPayAmount(item.basic_salary, totalDaysInMonth, lossOfPay); 
					netSalary = this.calculateNetPayAmount(item.basic_salary, totalDaysInMonth, lossOfPay); 
				}
				return <tr key={i} >
					<td>{sno++}</td>
					<td>{employee.employee_code}</td>
					<td>{employee.firstname} {employee.lastname}</td>
					<td>{employee.mobile}</td>
					<td>{totalDaysInMonth}</td>
					<td>{leaveAllowd}</td>
					<td>{leaveTaken}</td>
					<td>{lossOfPay}</td>
					<td align="right" >{basicSalary}</td>
					<td align="right" >{lossOfPayAmount}</td>
					<td align="right" >{netSalary}</td>
				</tr>;
			})
			}
			**/
			this.setState({salaryAbstract: sb});
		}
		catch(error){
			
		}
	}
	
	handleLossOfPayDaysChange(item, e){
		try{
			var s = this.state.salaryAbstract;
			var days = e.target.value;		
			var	leaveAllowed = parseInt(item.leaveallowd);
			var losDays = parseInt(days) - parseFloat(leaveAllowed);
			//var lossOfPayAmount = (parseInt(days)>0) ? this.calculateLossOfPayAmount(item.basicsalary, item.daysinmonth, losDays) : item.basicsalary;
			var lossOfPayAmount = this.calculateLossOfPayAmount(item.basicsalary, item.daysinmonth, item.leaveallowd, days);
			//var netSalary = (parseFloat(item.basicsalary) - parseFloat(lossOfPayAmount)).toFixed(2);
			var netSalary = this.calculateNetPayAmount(item, item.basicsalary, lossOfPayAmount);
			
			var index = s.findIndex(function(obj){
				return(obj.employee_id==item.employee_id);
			});
			
			if(index>-1){
				s[index]['lossofpaydays'] = days;
				s[index]['lossofpayamount'] = lossOfPayAmount;
				s[index]['netsalary'] = Math.round(parseFloat(netSalary)).toFixed(2);
			}
			
			this.setState({salaryAbstract: s}); 
		}
		catch(error){ 
			console.log(error);
		}
	}
	
	handleOTChange(item,e){
		try{
			var s = this.state.salaryAbstract;
			var amt = e.target.value;		
			
			var index = s.findIndex(function(obj){
				return(obj.employee_id==item.employee_id);
			});
			
			if(index>-1){
				s[index]['ot_amt'] = amt;
				var namt = Math.round(parseFloat(parseFloat(s[index]['netsalary'])+parseFloat(amt))).toFixed(2);
				//s[index]['netsalary'] = namt;
			}
			
			this.setState({salaryAbstract: s}); 
		}
		catch(error){ 
			console.log(error);
		}
	}
	
	handleSaveAbstractClick(e){
		e.preventDefault();
		try{
			
			if(!window.confirm('Do you want to save Salary Abstract?')){
				return false;
			}
			
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/payroll/saveabstract', $("#frm_salary_abs_Gen").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					this.setState({
						salaryAbstract: [],
						selectedMonth: '',
						selectedMonthValue: '',
						selectedYearValue: '',
						showLoader: false,
					});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){

		var sno=1;
		
		return(
			<div>
			
			<PayrollMenu {...this.props} />
				
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} 
				>
					
					<Card>
						<Card.Body>
							<Row>
								<Col md={1} >
									<label>Month</label>
								</Col>
								<Col md={2} >
									<Form.Control
										type="month"
										size="sm"
										max={momentDate(todayDate(), 'YYYY-MM')}
										onChange={this.handleMonthChange} 
									/>
								</Col>
								<Col md={1} >
									<Button 
										type="button" 
										size="sm" 
										variant="warning" 
										onClick={this.loadData} 
									>
										Generate
									</Button>
								</Col>
							</Row>
						</Card.Body>
					</Card>
					
					<Card className="mt-15" >
						<Card.Body>
							<Table
								bordered
							>
								<thead>
									<tr>
										<th>S.No</th>
										<th>Employee Code</th>
										<th>Employee Name</th>
										<th>Mobile</th>
										<th>Days in Month</th>
										<th>Leave Allowed</th>
										<th>Leave Taken</th>
										<th>Loss of Pay</th>
										<th>OT</th>
										<th>Basic</th>
										<th>Loss of Pay</th>
										<th>PF</th>
										<th>ESI</th>
										<th>Net Salary</th>
									</tr>
								</thead>
								<tbody>
								{this.state.salaryAbstract.map((item, i)=>{
									
									return <tr key={i}>
										<td>{sno++}</td>
										<td>{item.employee_code}</td>
										<td>{item.employee_name}</td>
										<td>{item.mobile}</td>
										<td>{item.daysinmonth}</td>
										<td>{item.leaveallowd}</td>
										<td>{item.leavetaken}</td>
										<td align="center" >
											<Form.Control 
												type="text"
												size="sm"
												onKeyPress={integerKeyPress}
												value={item.lossofpaydays}
												style={{
													width: '60px',
													textAlign: 'right',
												}}
												onChange={this.handleLossOfPayDaysChange.bind(this, item)} 
											/>
										</td>
										<td align="center" >
											<Form.Control 
												type="text"
												size="sm"
												onKeyPress={integerKeyPress}
												value={item.ot_amt}
												style={{
													width: '60px',
													textAlign: 'right',
												}}
												onChange={this.handleOTChange.bind(this, item)} 
											/>
										</td>
										<td align="right" >{item.basicsalary}</td>
										<td align="right" >{item.lossofpayamount}</td>
										<td align="right" >{item.pf_amount}</td>
										<td align="right" >{item.esi_amount}</td>
										<td align="right" >{parseFloat(parseFloat(item.netsalary)+parseFloat(item.ot_amt)).toFixed(2)}</td>
									</tr>;
								})									
								}
								</tbody>
							</Table>
							
							{Object.keys(this.state.salaryAbstract).length>0 ? <Row>
								<Col md={12} >
									<form 
										action=""
										method="post"
										id="frm_salary_abs_Gen"
										onSubmit={this.handleSaveAbstractClick}
									>
									
										<input type="hidden" name="api" value={this.context.state.api} />
										<input type="hidden" name="i__month" value={this.state.selectedMonthValue} />
										<input type="hidden" name="i__year" value={this.state.selectedYearValue} />
										<input type="hidden" name="i__abstract" value={JSON.stringify(this.state.salaryAbstract)} />
										
										<div className="text-right" >
											<Button
												type="submit"
												size="sm"
												variant="success"
											>											
											Save Salary Abstract
											</Button>
										</div>
									</form>
								</Col>
							</Row> : null}
						</Card.Body>
					</Card>
					
				</Spin>
				
				
			</div>
		);
	}
	
}
export default GenerateSalary;
