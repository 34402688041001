import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup ,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate, todayDate, 
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import AddMember from './addMember';
import EditMember from './editMember';
import MemberDetails from './MemberDetails';
import MemberFilter from './memberFilter';
import ReceiptEntry from './receiptEntry';
import MembersAttendance from './membersAttendance';

const { Option } = Select;

class MemberList extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddDrawer: false,
			showDeleteLoader: false,
			showEditDrawer: false,
			showViewDrawer: false,
			showPackageUpgradeModal: false,
			showFilterDrawer: false,
			showReceiptEntryModal: false,
			showAttendanceModal: false,
			paginationPageSize: 30,
			editData: [],
			dataList: [],
			dataView: [],
			trainerList: [],
			packageList: [],
			timingList: [],
			selectedRowGroup: 'ALL',
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleAddTrainderClick = this.handleAddTrainderClick.bind(this);
		this.handleAddDrawerCloseClick = this.handleAddDrawerCloseClick.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
		this.handleEditDrawerCloseClick = this.handleEditDrawerCloseClick.bind(this);
		this.handleViewDrawerCloseClick = this.handleViewDrawerCloseClick.bind(this);
		this.handleViewActionClick = this.handleViewActionClick.bind(this);
		this.handlePaginationSizeChange = this.handlePaginationSizeChange.bind(this);
		this.handleRowGroupDropDownChange = this.handleRowGroupDropDownChange.bind(this);
		this.handleFilterClick = this.handleFilterClick.bind(this);
		this.handleFilterDrawerCloseClick = this.handleFilterDrawerCloseClick.bind(this);
		this.handleReceiptModalCloseClick = this.handleReceiptModalCloseClick.bind(this);
		this.handleReceiptModalClick = this.handleReceiptModalClick.bind(this);
		this.handleAttendanceModalOpen = this.handleAttendanceModalOpen.bind(this);
		this.handleAttendanceModalClose = this.handleAttendanceModalClose.bind(this);
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	
	loadData(){
		try{
			this.setState({showDeleteLoader: true});
			axios.get(Api.Url+'v1/members/all?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showDeleteLoader: false
					});
				}
				else{
					this.setState({showDeleteLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showDeleteLoader: false});
		}
	}
	
	handleAddTrainderClick() {
		this.setState({showAddDrawer: true});
	}
	
	handleAddDrawerCloseClick(){
		this.setState({showAddDrawer: false});
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return( (upperCase(obj.memberid).indexOf(v)>-1) || 
				(upperCase(obj.firstname).indexOf(v)>-1) || 
				(upperCase(obj.lastname).indexOf(v)>-1) || 
				(upperCase(obj.mobile).indexOf(v)>-1));
			});
			
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleDeleteActionClick(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: `Do you want to Delete the Member ${record.firstname} ?`,
			buttons: {
				Yes: function(){ 
					$this.handleDeleteConfirm(record);
				},
				No: function() { }
			}
		});
	}
	
	handleDeleteConfirm(record){
		try{
			this.setState({showDeleteLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__id', record.id);
			
			axios.post(Api.Url+'v1/member/remove', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					/*var index = d.findIndex(function(obj){
						return(obj.id==record.id);
					});
					d.splice(index,1);*/
					var dat = d.filter(function(obj){
						return(obj.id != record.id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						showDeleteLoader: false
					});
					//this.loadData();
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showDeleteLoader: false});
				}
			});
		}
		catch(error){
			message.error('Unknown Error');
			this.setState({showDeleteLoader: false});
		}
	}
	
	handleEditActionClick(record){
		this.setState({
			editData: record,
			showEditDrawer: true,
		});
	}
	
	handleEditDrawerCloseClick(){
		this.setState({
			editData: [],
			showEditDrawer: false,
		});
	}
	
	handleViewActionClick(record){
		this.setState({
			editData: record,
			showViewDrawer: true,
		});
	}
		
	handleViewDrawerCloseClick(){
		this.setState({
			editData: [],
			showViewDrawer: false,
		});
	}
	
	handleReceiptModalCloseClick(){
		this.setState({
			editData: [],
			showReceiptEntryModal: false,
		});
	}
	
	handleReceiptModalClick(){
		this.setState({
			editData: [],
			showReceiptEntryModal: true,
		});
	}
	
	
	handlePaginationSizeChange(current, pageSize) {
		this.setState({paginationPageSize: pageSize});
	}
	
	handleRowGroupDropDownChange(value){
		this.setState({showDeleteLoader: true});
		var $this = this;
		setTimeout(function() { 
			$this.updateRowGroupDropdownChange(value);
		}, 2000);
	}
	
	updateRowGroupDropdownChange(value){
		try{
			this.setState({showDeleteLoader: true});
			var d = this.state.dataList;
			if(value=='DISABLED_IN_MACHINE'){
				d = this.state.dataList.filter(function(obj){
					return(obj.member_device_status=='0');
				});
			}
			else if(value=='ENABLED_IN_MACHINE'){
				d = this.state.dataList.filter(function(obj){
					return(obj.member_device_status=='1');
				});
			}
			else if(value=='PLAN_EXPIRED'){
				d = this.state.dataList.filter(function(obj){
					return(
						(new Date(obj.current_plan_expires_on).getTime())<=(new Date(todayDate()).getTime())
					)
				});
			}
			
			this.setState({
				dataView: d,
				selectedRowGroup: value,
				showDeleteLoader: false,
			});
		}
		catch(error){
			this.setState({showDeleteLoader: false});
			console.log(error);
		}
	}
	
	handleFilterClick(){
		this.setState({
			showFilterDrawer: true
		});
	}
	
	handleFilterDrawerCloseClick(){
		this.setState({
			showFilterDrawer: false
		});
	}
	
	handleAttendanceModalOpen(){
		this.setState({showAttendanceModal: true});
	}
	
	handleAttendanceModalClose(){
		this.setState({showAttendanceModal: false});
	}
	
	render(){

		if(this.state.showLoader)
			return(<SpinLoader loading={this.state.showLoader} />);
		
		const columns = [
			{
				title: '#',
				dataIndex: 'id',
				key: 'id',
				render: (text, record)=>(
					<>
					{record.profilephoto ? <Avatar src={<Image src={Api.Url + record.profilephoto} />} /> :
					<Avatar icon={<i className="icofont-ui-user" />} />}
						{record.member_device_status=='0' ? 
						<Badge variant="danger" >
							<i className="icofont-close-line-circled" ></i>
						</Badge> : null}
					</>
				)
			},
			{
				title: 'Member Id',
				dataIndex: 'memberid',
				key: 'memberid',
			},
			{
				title: 'Name',
				dataIndex: 'firstname',
				key: 'firstname',
				render: (text, record)=>(
					<>
					{record.firstname} {record.lastname}
					</>
				)
			},
			{
				title: 'Mobile / Email',
				dataIndex: 'mobile',
				key: 'mobile',
				render: (text,record)=>(
					<>{record.mobile} <br /> {record.email}</>
				)
			},
			{
				title: 'Joined',
				dataIndex: 'address',
				key: 'address',
				render: (text,record)=>(
					<>{momentDate(record.doa, 'DD-MM-YYYY')}</>
				)
			},
			{
				title: 'Yrs of Playing',
				dataIndex: 'yop',
				key: 'yop',
			},
			{
				title: 'Expectation',
				dataIndex: 'expectation',
				key: 'expectation',
			},
			{
				title: 'Package',
				dataIndex: 'packagename',
				key: 'packagename',
			},
			{
				title: 'Balance To Paid',
				dataIndex: 'balance_to_paid',
				key: 'balance_to_paid',
				render: (text, record)=>(
					<div className="text-right" >
						<i className="icofont-rupee"></i> 
						{record.balance_to_paid}
					</div>
				)
			},
			{
				title: 'Next Installment',
				dataIndex: 'next_installment_on',
				key: 'next_installment_on',
				render: (text, record)=>(
					<>
					{record.next_installment_on!='0000-00-00 00:00:00' ? momentDate(record.next_installment_on, 'DD/MMM/YYYY') : null}
					</>
				)
			},
			{
				title: 'Expire On',
				dataIndex: 'current_plan_expires_on',
				key: 'current_plan_expires_on',
				render: (text, record)=>(
					<>
					{record.current_plan_expires_on!='0000-00-00 00:00:00' ?  momentDate(record.current_plan_expires_on, 'DD/MMM/YYYY') : null}
					</>
				)
			},
			{
				title: 'Manage',
				dataIndex: 'id',
				key: 'id',
				render: (text,record)=> (
					<center>
					
						<Tooltip title="Click to View" >
							<Button type="button" size="xs" variant="info" onClick={this.handleViewActionClick.bind(this, record)} >
								<i className="icofont-eye" />
							</Button>
						</Tooltip>
						&nbsp;
						{/*<Tooltip title="Click to Edit" >
							<Button type="button" size="xs" variant="warning" onClick={this.handleEditActionClick.bind(this, record)} >
								<i className="icofont-edit" />
							</Button>
						</Tooltip>
						&nbsp;*/}
						<Tooltip title="Click to Delete" >
							<Button type="button" size="xs" variant="danger" onClick={this.handleDeleteActionClick.bind(this, record)} >
								<i className="icofont-trash" />
							</Button>
					</Tooltip >
					
				</center>
				)
			},
		];
		
		const rowGroup = [
			{key: 'ALL', value: 'Show All'},
			{key: 'PLAN_EXPIRED', value: 'Plan Expired Only'},
			{key: 'DISABLED_IN_MACHINE', value: 'Show Reocrds Disabled in Machine'},			
			{key: 'ENABLED_IN_MACHINE', value: 'Show Reocrds  Enabled in Machine'},			
		];
				
		return(
			<React.Fragment>
				
				
					<Card   style={{position:'sticky',top:'56px',zIndex:'1'}} >
						<Card.Body>
							<Row>
								<Col md={3} >
									<span className="input-icon">
										<i className="material-icons" >search</i>
										<Form.Control  
											size="sm" 
											placeholder="Search by Member Id, Name, Mobile" 
											onChange={this.handleSearchOnChange} />
									  </span>
								 </Col>
								 <Col md={3} >
									<Select
									  showSearch
									  style={{ width: 180 }}
									  placeholder="Select"
									  optionFilterProp="children"
									  style={{width: '100%'}}
									  onChange={this.handleRowGroupDropDownChange}
									  defaultValue={this.state.selectedRowGroup}
									  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									>
									  {rowGroup.map((item) => (
										<Option key={item.key} value={item.key}> {item.value} </Option>
									  ))}
									</Select>
								 </Col>
								 <Col md={6} >
									<div className="text-right" >
									{/*<Button size="sm" variant="warning" onClick={this.handleFilterClick} >
											<i className="icofont-filter" > </i>
										</Button>
									&nbsp;*/}
									<ButtonGroup >
										<Button size="sm" variant="outline-secondary" onClick={this.handleReceiptModalClick} >
											<i className="icofont-notebook" /> Receipt Entry
										</Button>
										
										<Button size="sm" variant="outline-secondary" onClick={this.handleAddTrainderClick} >
											<i className="icofont-plus" /> Add Member
										</Button>
										
										<Button size="sm" variant="outline-secondary" onClick={this.handleAttendanceModalOpen} >
											<i className="icofont-file-document" /> Attendance
										</Button>
										
										<Link to="/app/members-report" className="btn btn-outline-secondary btn-sm" >
											<i className="icofont-file-document" /> Reports
										</Link>
										
										<Button size="sm" variant="outline-secondary" onClick={this.loadData} >
											<i className="icofont-refresh" /> Refresh
										</Button>
									</ButtonGroup >
									</div>
								 </Col>
							</Row>
						</Card.Body>
					</Card>
					
					<div className="mt-10 ps-table" >
						<Table 
							size="small"
							columns={columns} 
							bordered
							title={() => `Total ${Object.keys(this.state.dataView).length} members in list`} 
							footer={() => `Total ${Object.keys(this.state.dataView).length} members in list`} 
							loading={this.state.showDeleteLoader}
							dataSource={this.state.dataView}
							pagination={{ 
								pageSize: this.state.paginationPageSize,
								onShowSizeChange: this.handlePaginationSizeChange,
								position: ['topRight', 'bottomRight'],
							}} />
					</div>
					
					<Drawer 
						visible={this.state.showAddDrawer} 
						placement="right" 
						title="Add Member" 
						width="1000"
						onClose={this.handleAddDrawerCloseClick} >						
						{this.state.showAddDrawer ?
							<AddMember 
								afterFinish={this.loadData} />
						: null}						
					</Drawer>
					
					<Drawer 
						visible={this.state.showEditDrawer} 
						placement="right" 
						title={`Edit Member - ${this.state.editData.memberid}`} 
						width="1000"
						onClose={this.handleEditDrawerCloseClick} >
						
						{this.state.showEditDrawer ?
							<EditMember 
								editData={this.state.editData}
								afterFinish={this.loadData} />
						: null}
					</Drawer>
					
					<Drawer 
						visible={this.state.showFilterDrawer} 
						placement="right" 
						title="Filter"
						width="360"
						onClose={this.handleFilterDrawerCloseClick} >
						
						<MemberFilter 
							{...this.props}
							editData={this.props.dataList}
						/>
					</Drawer>
					
					<Modal 
						show={this.state.showViewDrawer} 
						onHide={this.handleViewDrawerCloseClick}  
						dialogClassName="modal-100w"
						backdrop="static"
						keyboard={false}
					>
						<Modal.Header closeButton >
							{`Details of the Member ${this.state.editData.memberid} - ${this.state.editData.firstname} ${this.state.editData.lastname}`}
						</Modal.Header>
						<Modal.Body>
							<MemberDetails
								editData={this.state.editData}
								afterFinish={this.loadData}
							/>
						</Modal.Body>
					</Modal>
					
					<Modal 
						show={this.state.showReceiptEntryModal} 
						onHide={this.handleReceiptModalCloseClick}  
						dialogClassName=""
						backdrop="static"
						size="lg" 
						keyboard={false}
					>
						<Modal.Header closeButton >
							Receipt Entry
						</Modal.Header>
						<Modal.Body>
							<ReceiptEntry 
								afterFinish={this.loadData}
							/>
						</Modal.Body>
					</Modal>
					
					<Modal 
						show={this.state.showAttendanceModal} 
						onHide={this.handleAttendanceModalClose}  
						dialogClassName=""
						backdrop="static"
						size="100w" 
						keyboard={false}
					>
						<Modal.Header closeButton >
							Members Attendance
						</Modal.Header>
						<Modal.Body>
							<MembersAttendance 
								editData={this.state.dataList}
								afterFinish={this.loadData}
							/>
						</Modal.Body>
					</Modal>
			
			</React.Fragment>
		);
	}
	
}
export default MemberList;
