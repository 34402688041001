/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import $ from 'jquery';
import PsContext from './PsContext';
import {
  setlsItem, getlsItem, getls,setSession, getSession
} from '../../utils';

// Then create a provider Component
class PsContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: 'Wes',
      cartCount: getlsItem('cart_count') || 0,
      cartItems: getlsItem('cart_items') ? JSON.parse(getlsItem('cart_items')) : [],
      //userLogged: getSession('user_logged') || 'no',
      userLogged: getlsItem('user_logged') || 'no',
      token: getls('loginUser', 'token') || '0',
      role: getlsItem('role') || '0',
	  api: getlsItem('access_api') || null,
	  loggedUser: getlsItem('loginUser') ? JSON.parse(getlsItem('loginUser')) : [],
      cool: true,
    };

    this.updateUserLogin = this.updateUserLogin.bind(this);
    this.requestHeader = this.requestHeader.bind(this);
    this.api = this.api.bind(this);
    this.logout = this.logout.bind(this);
    this.updateAccessApi = this.updateAccessApi.bind(this);
  }

  componentDidMount() {
    $('body').addClass('sidebar-collapse');
  }

  updateUserLogin(userdata, role) {
    setlsItem('loginUser', JSON.stringify(userdata));
    setlsItem('user_logged', 'yes');
    //setSession('user_logged', 'yes');
    setlsItem('user_logged', 'yes');
    setlsItem('role', role);
    this.setState({ loggedUser: userdata, userLogged: 'yes', role: role });
  }

  logout() {
    setlsItem('loginUser', '');
    setlsItem('user_logged', 'no');
    setSession('user_logged', 'no');
    setlsItem('role', '');
    this.setState({ loggedUser: [], userLogged: 'no' });
  }

  api() {
    return getls('loginUser', 'api');
  }
  
  updateAccessApi(_api){
	  setlsItem('access_api', _api);
	  this.setState({api: _api});
  }

  requestHeader() {
    const d = {
      headers: {
        'Api-Key': getls('loginUser', 'api') || 'null',
      },
    };

    return d;
  }

  render() {
    return (
      <PsContext.Provider value={{
			state: this.state, 
			updateUserLogin: this.updateUserLogin, 
			requestHeader: this.requestHeader, 
			logout: this.logout, 
			api: this.state.api,
			updateAccessApi: this.updateAccessApi,
			role: this.state.role,
      }}
      >
        {this.props.children}
      </PsContext.Provider>
    );
  }
}

export default PsContextProvider;
