 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup , Table,
} from 'react-bootstrap';
import {
  Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch, Tabs 
} from 'antd';

import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../../utils';
import iziToast from 'izitoast';

import PsContext from '../../../context/PsContext';
import AddCentre from './addCentre';
import ClubInfo from './ClubInfo';
import SmsSettings from './SmsSettings';
import ChangePassword from './ChangePassword';
import SendSms from './SendSms';

const { Option } = Select;
const { TabPane } = Tabs;

class ViewDetails extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showAddModal: false,
			showChangePassword: false,
			showSmsModal: false,
			fitnessKey: this.props.match.params.code,
			paginationPageSize: 30,
			dataList: [],
			dataView: [],
			clubData: [],
			activeMembers: '',
			inActiveMembers: '',
			fingerNotInitiated: '',
			staffs: [],
			userPassword: '******',
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
		this.handleAddModalClose = this.handleAddModalClose.bind(this);
		this.handlePaginationSizeChange = this.handlePaginationSizeChange.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
		this.handleEditPasswordShow = this.handleEditPasswordShow.bind(this);
		this.handlePasswordCloseClick = this.handlePasswordCloseClick.bind(this);
		this.handleSmsModalOpen = this.handleSmsModalOpen.bind(this);
		this.handleSmsModalClose = this.handleSmsModalClose.bind(this);
	}
	
	componentDidMount(){
		
		document.getElementById("header_title").innerHTML = 'Club Details';
		this.loadData();
		
	}
	
	handleEditPasswordShow(){
		this.setState({
			showChangePassword: true,
		});
	}
	
	handlePasswordCloseClick(){
		this.setState({
			showChangePassword: false,
		});
	}
	
	handleSmsModalOpen(){
		this.setState({
			showSmsModal: true,
		});
	}
	
	handleSmsModalClose(){
		this.setState({
			showSmsModal: false,
		});
	}
	
	
	loadData()
	{
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('fitness_key', this.state.fitnessKey);
			axios.post(Api.Url+'v1/ma/club_info', form).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						//dataList: res['data'].data,
						//dataView: res['data'].data,
						clubData: res['data'].club,
						activeMembers: res['data'].active_members,
						inActiveMembers: res['data'].in_active_members,
						fingerNotInitiated: res['data'].finger_not_initiated,
						staffs: res['data'].staffs,
						showLoader: false,
					});
					
					document.getElementById("header_title").innerHTML = res['data'].club.name;
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleAddButtonClick(){
		this.setState({showAddModal: true});
	}
	
	handleAddModalClose(){
		this.setState({showAddModal: false});
	}
	
	handlePaginationSizeChange(current, pageSize) {
		this.setState({paginationPageSize: pageSize});
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return( (upperCase(obj.name).indexOf(v)>-1) || 
				(upperCase(obj.mail).indexOf(v)>-1) || 
				(upperCase(obj.contactnumber1).indexOf(v)>-1));
			});
			
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handlePasswordView(record){
		try{
			this.setState({showLoader: true});
			
			axios.get(Api.Url+'v1/ma/get_password?api='+
				this.context.state.api+'&id='+record).then(res=>{
					if(res['data'].status=='1'){
						//alert(res['data'].password);
						$.alert({
							title: 'Password',
							content: res['data'].pass
						});
						this.setState({showLoader: false});
					}
					else{
						message.error(res['data'].message || 'Error');
						this.setState({showLoader: false});
					}
				});
		}
		catch(error){}
	}
	
	getClubDetails(fieldName){
		try{
			var d = this.state.clubData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	getStaffTotalCount(){
		try{
			var d = this.state.staffs;
			var total = 0;
			d.map((item,i)=>{
				total = parseInt(total) + parseInt(item.total);
			});
			return parseInt(total);
		}
		catch(error){
			
		}
	}
	
	getStaffCount(fieldName){
		try{
			var d = this.state.staffs;
			var s = d.filter(function(obj){
				return (obj.employee_type==fieldName);
			});
			return s[0]['total'];
		}
		catch(error){
			
		}
	}
	
	handleMachineSwitchChange(record, e){
		
		if(e){
			this.handleMachineEnableClick(record.fitness_key);
		}
		else{
			this.handleMachineDisableClick(record.fitness_key);
		}
	}
	
	handleMachineEnableClick(fkey){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to active the Club?',
			buttons: {
				Yes: function(){
					$this.confirmMachineEnable(fkey);
				},
				No: function(){ }
			}
		});
	}
	
	confirmMachineEnable(fkey){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('fitness_key', fkey);
			form.append('status', '1');
			
			axios.post(Api.Url+'v1/ma/club_status_change',form).then(res=>{
					if(res['data'].status=='1'){
						this.setState({ 
							showLoader: false, 
							memberMachineStatus: '1', 
						});
						message.success(res['data'].message || 'Success');
						this.loadData();
						if(this.props.afterFinish)
							this.props.afterFinish();
					}
					else{
						this.setState({showLoader: false});
						message.error(res['data'].message || 'Error');
					}
			}); 
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleMachineDisableClick(fkey){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to deactivate the Club?',
			buttons: {
				Yes: function(){
					$this.confirmMachineDisable(fkey);
				},
				No: function(){ }
			}
		});
	}
	
	confirmMachineDisable(fkey){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('fitness_key', fkey);
			form.append('status', '0');
			
			axios.post(Api.Url+'v1/ma/club_status_change',form).then(res=>{
					if(res['data'].status=='1'){
						this.setState({ 
							showLoader: false, 
							memberMachineStatus: '1', 
						});
						message.success(res['data'].message || 'Success');
						this.loadData();
						if(this.props.afterFinish)
							this.props.afterFinish();
					}
					else{
						this.setState({showLoader: false});
						message.error(res['data'].message || 'Error');
					}
			}); 
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){

		
		return(
			<div>
			<Spin
				spinning={this.state.showLoader}
			>
				
				<Row>
					<Col md={3} >
						<Card>
							<Card.Body>
								<h4 className="text-success font-weight-light mb-2" >Total Members</h4>
								<hr />
								<h1 className="pricing-price">{parseInt(this.state.activeMembers) + parseInt(this.state.inActiveMembers)}</h1>
							</Card.Body>
						</Card>
					</Col>
					
					<Col md={3} >
						<Card>
							<Card.Body>
								<h4 className="text-success font-weight-light mb-2" >Active Members</h4>
								<hr />
								<h1 className="pricing-price">{this.state.activeMembers}</h1>
							</Card.Body>
						</Card>
					</Col>
					
					<Col md={3} >
						<Card>
							<Card.Body>
								<h4 className="text-danger font-weight-light mb-2" >In Active Members</h4>
								<hr />
								<h1 className="pricing-price">{this.state.inActiveMembers}</h1>
							</Card.Body>
						</Card>
					</Col>
					
					{/*<Col md={3} >
						<Card>
							<Card.Body>
								<h4 className="text-danger font-weight-light mb-2" >Finger Not Initiated</h4>
								<hr />
								<h1 className="pricing-price">{this.state.fingerNotInitiated}</h1>
							</Card.Body>
						</Card>
					</Col>*/}
					
					<Col md={3} >
						<Card>
							<Card.Body>
								<h4 className="text-danger font-weight-light mb-2" >Staffs/Trainers</h4>
								<hr />
								<h1 className="pricing-price">{this.getStaffTotalCount()} / {this.getStaffCount('TRAINER')}</h1>
							</Card.Body>
						</Card>
					</Col>
					
				</Row>
				
				<Row className="mt-15" >
				
					<Col md={8} >
						<Tabs defaultActiveKey="1" style={{marginBottom: '0px'}} >
						<TabPane tab="Club Information" key="1">
						{Object.keys(this.state.clubData).length >0 ?
							<ClubInfo data={this.state.clubData}  afterFinish={this.loadData} />
						: null}
						</TabPane>
						<TabPane tab="SMS Settings" key="2">
						  {Object.keys(this.state.clubData).length >0 ?
							<SmsSettings data={this.state.clubData} />
						: null}
						</TabPane>
						
					  </Tabs>
						
					</Col>
					
					<Col md={4} >
						<Card>
							<Card.Body>
								<Row>
									<Col md={6}>
										<label>Club Status</label>
									</Col>
									<Col md={6} >
										<div className="text-right" >
											<Switch 
												checkedChildren="Active"
												unCheckedChildren="In Active"
												checked={this.getClubDetails('status')=='1'}
												onChange={this.handleMachineSwitchChange.bind(this, this.state.clubData)}
											/>
										</div>
									</Col>
								</Row>
								<hr className="mt-5 mb-5" />
								<Row>
									<Col md={6}>
										<label>Password</label>
									</Col>
									<Col md={6}>
										<div className="text-right">
											
											<Button
												size="sm" 
												onClick={this.handlePasswordView.bind(this, this.getClubDetails('id'))}
											>
												View
											</Button>
										</div>
									</Col>
								</Row>
								
								<hr className="mt-5 mb-5" />
								<Row>
									<Col md={6}>
										<label>Message</label>
									</Col>
									<Col md={6} >
										<div className="text-right" >
											<Button
												type="button"
												size="sm"
												variant="warning"
												onClick={this.handleSmsModalOpen}
											>
												Send SMS
											</Button>
										</div>
									</Col>
								</Row>
								<hr className="mt-5 mb-5" />
								<Row>
									<Col md={6}>
										<label>Change Password</label>
									</Col>
									<Col md={6} >
										<div className="text-right" >
											<Button
												type="button"
												size="sm"
												variant="info"
												onClick={this.handleEditPasswordShow}
											>
												Change Password
											</Button>
										</div>
									</Col>
								</Row>
								
							</Card.Body>
						</Card>
					</Col>
					
				</Row>
				</Spin>
				
				
				<Modal show={this.state.showChangePassword} onHide={this.handlePasswordCloseClick} >
					<Modal.Header closeButton>
						Change Password
					</Modal.Header>
					<Modal.Body>
						<ChangePassword editData={this.state.clubData} />								
					</Modal.Body>
				</Modal>
					
				<Modal show={this.state.showSmsModal} onHide={this.handleSmsModalClose} size="lg" backdrop="static" >
					<Modal.Header closeButton>
						Send SMS
					</Modal.Header>
					<Modal.Body>
						<SendSms editData={this.state.clubData} />								
					</Modal.Body>
				</Modal>
					
								
			</div>
		);
	}
	
}
export default ViewDetails;
