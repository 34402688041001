import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Tabs,
} from 'antd';

import { Api } from '../../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../../utils';
import { SpinLoader } from '../../../elements';
import PsContext from '../../../context/PsContext';
import UpgradePlan from './upgradePlan';

const { TabPane } = Tabs;

class MemberSettings extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showUpgradeModal: false,
			editData: this.props.editData,
			showRemoveFb: false,
			remvoeFbPermanently: false,
			dataList: [],
			dataView: [],
			memberMachineStatus: this.props.editData.member_device_status,
		}
		
		this.handleMachineDisableClick = this.handleMachineDisableClick.bind(this);
		this.handleMachineEnableClick = this.handleMachineEnableClick.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	handleMachineDisableClick(){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to remove the member from all the connected Machines?',
			buttons: {
				Yes: function(){
					$this.confirmMachineDisable();
				},
				No: function(){ }
			}
		});
	}
	
	confirmMachineDisable(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/member/remove_from_machine?mid='+
				this.props.editData.id+'&per='+
				this.state.remvoeFbPermanently+'&api='+
				this.context.state.api).then(res=>{
					if(res['data'].status=='1'){
						this.setState({ 
							showLoader: false, 
							memberMachineStatus: '0', 
						});
						message.success(res['data'].message || 'Success');
						
						if(this.props.afterFinish)
							this.props.afterFinish();
					}
					else{
						this.setState({showLoader: false});
						message.error(res['data'].message || 'Error');
					}
					
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleMachineEnableClick(){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to active the member from all the connected Machines?',
			buttons: {
				Yes: function(){
					$this.confirmMachineEnable();
				},
				No: function(){ }
			}
		});
	}
	
	confirmMachineEnable(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/member/active_to_machine?mid='+
				this.props.editData.id+'&api='+
				this.context.state.api).then(res=>{
					if(res['data'].status=='1'){
						this.setState({ 
							showLoader: false, 
							memberMachineStatus: '1', 
						});
						message.success(res['data'].message || 'Success');
						
						if(this.props.afterFinish)
							this.props.afterFinish();
					}
					else{
						this.setState({showLoader: false});
						message.error(res['data'].message || 'Error');
					}
					
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	render(){
	
				
		return(
			<React.Fragment>
				
				<Row>
					<Col md={12} >
						<h5>Member Settings &nbsp;&nbsp; 
							{this.state.memberMachineStatus=='1' ? 
									<span className="badge badge-success" >
										<i className="icofont-checked" ></i> 
									</span>
								: 
								<span className="badge badge-danger" >
									<i className="icofont-close-squared" ></i> 
								</span>
							}
						</h5>
					</Col>
				</Row>
				<hr />
				
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<ul className="list-group list-group-flush " >
					<li className="list-group-item" >
						Remove Member Details From Machine
						<div className="mt-20 pl-15" >
							<Form.Check
								type="checkbox"
								id="rm_fb_per"
								label="Also remove Finger Print permanently from all the connected devices and application."
								checked={this.remvoeFbPermanently}
								onChange={()=>{
									this.setState({remvoeFbPermanently: !this.state.remvoeFbPermanently});
								}}
							/>
						</div>
						<span style={{float: 'right'}} >
							<Button 
								type="button" 
								size="sm" 
								variant="secondary" 
								onClick={this.handleMachineDisableClick} 
							>
								Disable Now
							</Button>
						</span>
					</li>
					
					<li className="list-group-item" >
						Upload Member Details To Machine
						
						<span style={{float: 'right'}} >
							<Button 
								type="button" 
								size="sm" 
								variant="success" 
								onClick={this.handleMachineEnableClick} 
							>
								Upload Now
							</Button>
						</span>
					</li>
					
					{/*<li className="list-group-item" >
							<Form.Check 
								custom
								type="checkbox"
								id="chk_refp"
								label="Permanently remove all the Finger Print data from device and application"
								onChange={(e)=>{
									this.setState({
										showRemoveFb: e.target.checked
									});
								}}
							/>
						
						{this.state.showRemoveFb ? 
						<div 
							className="mt-15 ml-35 text-danger" 
						>
							When you click the "Delete Data" button it will remove all the given Finger Print data permanently from all the connected Finger Print Machines and also from the software. In this case you are not able to retrieve the Member Finger Print details after deletion.
							<br />
							<br />
							If you want to delete please click the button below.
							<br />
							<Button
								type="button"
								variant="danger"
								size="sm"
								className="mt-15" 
							>
								Delete Finger Print Data Permanently
							</Button>
						</div> : null}
						
					</li>*/}
					
					
				</ul>
				</Spin>
				
			</React.Fragment>
		);
	}
	
}
export default MemberSettings;
