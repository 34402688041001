import React, { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import axios from 'axios';

import PsContext from '../../context/PsContext';

class NewMenu extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
		}
	}
	
	componentDidMount(){
		
		
	}
	
	render(){
	
		return(
		  <Row className="pb-20" >
				<Col md={12} >
					<ul className="nav nav-tabs">
					  <li className="nav-item">
							<NavLink to="/app/members-report" className="nav-link" exact >
								Plan Wise Report
							</NavLink>
					  </li>
					   <li className="nav-item">
							<NavLink to="/app/members-report/detailed" className="nav-link" exact >
								Detailed Report
							</NavLink>
					  </li>
					   <li className="nav-item">
							<NavLink to="/app/members-report/trainer-wise" className="nav-link" exact >
								Trainer Wise Report
							</NavLink>
					  </li>
					</ul>
				</Col>
			</Row>					
		);
	}
	
}
export default NewMenu;
