 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, Modal, ListGroup} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Table, Drawer, Skeleton, Spin, Select,message, Tooltip, 
} from 'antd';

import PsContext from '../../context/PsContext';
import PayrollMenu from './payrollMenu';
import PayrollSettingsNew from './payrollSettingsNew';

const { Option } = Select;

class PayrollSettings extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showAddModal: false,
			dataList: [],
			dataView: [],
			employeeList: [],
			selectedEmployee: [],
		}
		
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
		this.handleAddClick = this.handleAddClick.bind(this);
		this.handleAddModalClose = this.handleAddModalClose.bind(this);
		this.loadData = this.loadData.bind(this);
		this.handleDeleteActionClick = this.handleDeleteActionClick.bind(this);
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/payroll/listsettings?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return( (upperCase(obj.employee_code).indexOf(v)>-1) || 
				(upperCase(obj.firstname).indexOf(v)>-1) || 
				(upperCase(obj.lastname).indexOf(v)>-1));
			});
			
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleAddClick(){
		this.setState({
			showAddModal: true
		});
	}
	
	handleAddModalClose(){
		this.setState({
			showAddModal: false
		});
	}
	
	handleDeleteActionClick(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: `Do you want to Delete the Member ${record.firstname} ?`,
			buttons: {
				Yes: function(){ 
					$this.handleDeleteConfirm(record);
				},
				No: function() { }
			}
		});
	}
	
	handleDeleteConfirm(record){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__id', record.id);
			
			axios.post(Api.Url+'v1/payroll/removesettings', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					/*var index = d.findIndex(function(obj){
						return(obj.id==record.id);
					});
					d.splice(index,1);*/
					var dat = d.filter(function(obj){
						return(obj.id != record.id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						showLoader: false
					});
					//this.loadData();
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			message.error('Unknown Error');
			this.setState({showLoader: false});
		}
	}
	
	render(){


		const columns = [
			{
				title: 'Employee Code',
				dataIndex: 'employee_code',
				key: 'employee_code'
			},
			{
				title: 'Employee Name',
				dataIndex: 'employee_code',
				key: 'employee_code',
				render: (text, record)=>(
					<>
					{record.firstname} {record.lastname}
					</>
				)
			},
			{
				title: 'Basic Salary',
				dataIndex: 'basic_salary',
				key: 'basic_salary',
			},
			{
				title: 'ESI Amount',
				dataIndex: 'esi_amount',
				key: 'esi_amount',
			},
			{
				title: 'PF Amount',
				dataIndex: 'pf_amount',
				key: 'pf_amount',
			},
			{
				title: 'Leave Per Month',
				dataIndex: 'allowed_leave_per_month',
				key: 'allowed_leave_per_month',
			},
			{
				title: 'Manage',
				dataIndex: 'id',
				key: 'id',
				render: (text,record)=> (
					<center>
						<Tooltip title="Click to Delete" >
							<Button type="button" size="xs" variant="danger" onClick={this.handleDeleteActionClick.bind(this, record)} >
								<i className="icofont-trash" />
							</Button>
						</Tooltip >
					
					</center>
				)
			},
		];
		
		
		return(
			<div>
			
			<PayrollMenu {...this.props} />
				
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
					<Card>
						<Card.Body>
							<Row>
								<Col md={9} >
									<span className="input-icon">
										<i className="material-icons" >search</i>
										<Form.Control  
											size="sm" 
											placeholder="Search by Member Id, Name" 
											onChange={this.handleSearchOnChange} />
									  </span>
								</Col>
								<Col md={3} >
									<div  className="text-right" >
										
										<Button
											variant="outline-secondary"
											size="sm" 
											onClick={this.loadData}
										>
											<i className="icofont-refresh" /> Refresh 
										</Button>
										<Button
											variant="outline-secondary"
											size="sm" 
											onClick={this.handleAddClick}
										>
											<i className="icofont-plus" /> New 
										</Button>
										
									</div>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				
				</Spin>
				
				<div className="mt-10 ps-table" >
					<Table 
						size="small"
						loading={this.state.showLoader}
						columns={columns} 
						dataSource={this.state.dataView} 
					/>
				</div>
				
				<Modal 
					show={this.state.showAddModal}
					onHide={this.handleAddModalClose}
					backdrop="static"
				>
					<Modal.Header closeButton >
						New Payroll Settings
					</Modal.Header>
					<Modal.Body>
						<PayrollSettingsNew
							existing={this.state.dataList}
							afterFinish={this.loadData}
						/>
					</Modal.Body>					
				</Modal>
				
			</div>
		);
	}
	
}
export default PayrollSettings;
