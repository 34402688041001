 import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, ButtonGroup, Modal, ListGroup} from 'react-bootstrap';

import PsContext from '../../../context/PsContext';

class AddAccountHead extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Save',
		}
	}
	
	componentDidMount(){
		
		
	}
	
	
	render(){
		
		return(
			<React.Fragment>
				<form 
					action=""
					method="post"
					encType="multipart/form-data"
					id="frm_mod_add_Head"
				>
					<Row>
						<Col md={3} >
							<label>
								Account Head Name 
								<span className="text-danger">*</span>
							</label>
						</Col>
						<Col md={9} >
							<Form.Control
								type="text"
								size="sm"
								name="i__name"
								required
								style={{
									textTransform: 'uppercase',
								}}
							/>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={3} >
							<label>
								Description
								<span className="text-danger"></span>
							</label>
						</Col>
						<Col md={9} >
							<Form.Control
								as="textarea"
								size="sm"
								rows="5"
								name="i__description"								
							/>
						</Col>
					</Row>
					
					<Row className="mt-15" >
						<Col md={12} >
							<div className="text-right" >
								<Button
									type="submit"
									size="sm"
									variant="success"
									disabled={this.state.btnText!='Save'}
								>
								{this.state.btnText}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>
			</React.Fragment>			
		);
	}
	
}
export default AddAccountHead;
