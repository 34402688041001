import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Tabs, Tab, Table,} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, getlsItem, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import axios from 'axios';
import { Api } from '../../../utils/Variables';

import BirthdayList from './BirthdayList';
import TodayFollowUp from './TodayFollowUp';
import Offers from './Offers';
import DueList from './DueList';
import PlanWiseCount from './PlanWiseCount';
import PsContext from '../../context/PsContext';

class Dashboard extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
			activeMembers: 0,
			inActiveMembers: 0,
			fingerNotInitiated: 0,
			attendanceCount: 0,
			staffs: [],
			dues: [],
			tomorrowDues: [],
			yesterdayDues: [],
			todayCollections: [],
			planWiseMemberCount: [],
			healthTips: [],
		}
	}
	
	componentWillMount(){
		this.loadData();
		
	}
	
	loadData()
	{
		try{
			this.setState({showLoader: true});
			
			axios.get(Api.Url+'v1/dashboard/count?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						activeMembers: res['data'].active,
						inActiveMembers: res['data'].in_active,
						fingerNotInitiated: res['data'].finger_not_initiated,
						attendanceCount: res['data'].attendancecount,
						dues: res['data'].duelist,
						tomorrowDues: res['data'].tomorrow_due_list,
						yesterdayDues: res['data'].yesterday_due_list,
						todayCollections: res['data'].today_collection_list,
						staffs: res['data'].staffs,
						planWiseMemberCount: res['data'].plan_wise_count,
						healthTips: res['data'].health_tips,
						showLoader: false,
					});
					
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			
			this.setState({showLoader: false});
		}
	}
	
	getStaffTotalCount(){
		try{
			var d = this.state.staffs;
			var total = 0;
			d.map((item,i)=>{
				total = parseInt(total) + parseInt(item.total);
				
			});
			return parseInt(total);
		}
		catch(error){
			console.log(error);
		}
	}
	
	getStaffCount(fieldName){
		try{
			var d = this.state.staffs;
			var s = d.filter(function(obj){
				return (obj.employee_type==fieldName);
			});
			
			return s[0]['total'];
		}
		catch(error){
			
		}
	}
	
	render(){

		return(
			<React.Fragment>
				
				<Row>
					<Col md={9} >
					  
					  
					  <Row className="mt-15" >
							<Col md={3} >
								<Card>
									<Card.Body>
										<h5 className="text-success font-weight-light mb-2" >Total Members</h5>
										<hr />
										<h1 className="pricing-price">{parseInt(this.state.activeMembers) + parseInt(this.state.inActiveMembers)}</h1>
									</Card.Body>
								</Card>
							</Col>
							<Col md={3} >
								<Card>
									<Card.Body>
										<h5 className="text-success font-weight-light mb-2" >Active Members</h5>
										<hr />
										<h1 className="pricing-price">{this.state.activeMembers}</h1>
									</Card.Body>
								</Card>
							</Col>
							<Col md={3} >
								<Card>
									<Card.Body>
										<h5 className="text-success font-weight-light mb-2" >InActive Members</h5>
										<hr />
										<h1 className="pricing-price">{this.state.inActiveMembers}</h1>
									</Card.Body>
								</Card>
							</Col>
							<Col md={3} >
								<Card>
									<Card.Body>
										<h5 className="text-success font-weight-light mb-2" >Staffs / Trainers</h5>
										<hr />
										<h1 className="pricing-price">{this.getStaffTotalCount()} / {this.getStaffCount('TRAINER')}</h1>
									</Card.Body>
								</Card>
							</Col>
					  </Row>
					  
					  <Card className="mt-15">
						<Card.Body>								
								<Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
								  <Tab eventKey="home" title="Today Birthday List">
										<BirthdayList />
								  </Tab>
								  <Tab eventKey="profile" title="Today Follow up Enquires">
										<TodayFollowUp />
								  </Tab>
								  <Tab eventKey="offers" title="Offers">
										<Offers />
								  </Tab>
								</Tabs>
						</Card.Body>
					  </Card>

					  <div className="mt-15">
					  {Object.keys(this.state.dues).length> 0 || Object.keys(this.state.tomorrowDues).length>0  || Object.keys(this.state.yesterdayDues).length>0  || Object.keys(this.state.todayCollections).length>0 ? 
						<DueList 
							dueData={this.state.dues} 
							tomorrowDueData={this.state.tomorrowDues} 
							yesterDayDueData={this.state.yesterdayDues} 
							todayCollectionData={this.state.todayCollections} 
						/>
					  : null}
					  </div>
					  
					</Col>
					
					<Col md={3} >
						<Card bg="warning" >
							<Card.Body>
								<img src={Api.Url+getls('loginUser', 'logo')}  style={{width: '100%'}} />
									{getlsItem('role') == 'staff' ?
									
										<Link to="/app/user-profile" className="btn btn-success btn-sm btn-block mt-15 mb-15" >
											Go to User Profile &nbsp;&nbsp;
											<i className="icofont-arrow-right" />
										</Link>
									:
										<Link to="/app/gym-profile" className="btn btn-success btn-sm btn-block mt-15 mb-15" >
											Go to Gym Profile &nbsp;&nbsp;
											<i className="icofont-arrow-right" />
										</Link>
									}
								<h6>Current Subscription</h6>
								<Form.Control value="Free Plan" size="sm"  />
							</Card.Body>
						</Card>
						
						{Object.keys(this.state.planWiseMemberCount).length> 0 ? 
							<div className="mt-15" >
								<PlanWiseCount planWiseCount={this.state.planWiseMemberCount} />
							</div>
						: null}
						
						<Card className="mt-15" >
							<Card.Body style={{height: 'calc(50vh)',overflowY: 'auto'}} >
								<h6>Daily Health Tips</h6>
								<Table responsive>
									<tbody>
									{this.state.healthTips.map((item,i)=>{
										return <tr>
											<td>
												<b>{item.title}</b><br />
												<div dangerouslySetInnerHTML={{__html : item.content}}></div>
											</td>
										</tr>
									})}
									</tbody>
								</Table>
							</Card.Body>
						</Card>
						
					</Col>
					
				</Row>
				
			
			</React.Fragment>
		);
	}
	
}
export default Dashboard;
