import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';

import { Api } from '../../../utils/Variables';
import { getDateByFormat } from '../../../utils/appUtils';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, 
} from '../../../utils';
import stateDistricts from '../../../utils/stateDistricts.json';
import ReactFileReader from 'react-file-reader';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import UPLOAD_ARROW from '../../../assets/img/upload-arrow.png';

const { Option } = Select;

class EditPackage extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			stateValue: '',
			editData: this.props.editData,
			dayLimit: (new Date()),
			submitBtn: 'Update Package',
		}
		
		this.handleDaysChange = this.handleDaysChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	
	handleDaysChange(e){
		var date = new Date();
		var d = new Date(date.getTime() + (e.target.value * 24 * 60 * 60 * 1000));
		this.setState({dayLimit: d});
	}
	
	handleFormSubmit(e) {
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, submitBtn: '....'});			
			axios.post(Api.Url+'v1/package/update', $("#frm_editPackage_DForm").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					this.setState({
						showLoader: false, 
						submitBtn: 'Update Package',
					});
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false, submitBtn: 'Update Package'});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false, submitBtn: 'Update Package'});
		}
	}
	
	getFieldValue(fieldName){
		try{
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	render(){

		const date = new Date();
		const ageLimit = date.setFullYear(date.getFullYear() - 10);
  
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<form action="" method="post" encType="multipart/form-data" id="frm_editPackage_DForm" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="i__id" value={this.getFieldValue('id')} />
					
					<Row>
						<Col md={12} >
						
							<Row>
								<Col md={6} >
									<label>
										Package Name
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__name" 
										type="text" 
										size="sm"
										placeholder="Name" 
										onKeyPress={lettersOnly}
										defaultValue={this.getFieldValue('name')}
										required
									/>
								</Col>
								
								<Col md={6} >
									<label>
										No of Days
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__days" 
										type="number" 
										size="sm"
										placeholder="No of Days" 
										defaultValue={this.getFieldValue('days')}
										onChange={this.handleDaysChange}
									/>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={6} >
									<label>
										Grace Period Days
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__graceDays" 
										type="number" 
										size="sm"
										placeholder="Grace Period Days" 
										defaultValue={this.getFieldValue('gracedays')}
									/>
								</Col>
								
								<Col md={6} >
									<label>
										Dedicated Trainer
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__dedicatedTrainer"
										as="select"
										size="sm"
										required
									>
										<option value="no" selected={this.getFieldValue('dedicatedtrainer')=='no' ? 'selected' : ''} > No </option>
										<option value="yes" selected={this.getFieldValue('dedicatedtrainer')=='yes' ? 'selected' : ''} > Yes </option>
									</Form.Control>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={6} >
									<label>
										Validity To
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__validityTo" 
										type="date" 
										size="sm"
										min={getDateByFormat(this.state.dayLimit, 'YYYY-MM-DD')}
										placeholder="Validity To" 
										defaultValue={this.getFieldValue('expireddate')}
									/>
								</Col>
								
								<Col md={6} >
									<label>
										Package Amount
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__amount" 
										type="number" 
										size="sm"
										placeholder="Amount" 
										onKeyPress={decimalKeyPress}
										defaultValue={this.getFieldValue('amount')}
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={6} >
									<label>
										Allowed Part Time Payment 
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__partPayment"
										as="select"
										size="sm"
										required
									>
										<option value="no" selected={this.getFieldValue('parttimepayment')=='no' ? 'selected' : ''} > No </option>
										<option value="yes" selected={this.getFieldValue('parttimepayment')=='yes' ? 'selected' : ''}  > Yes </option>
									</Form.Control>
								</Col>
							</Row>
							
									
						</Col>
						
					</Row>
					
					<Row>
						<Col md={12} >
							<hr />
							<div className="text-center" >
								<Button type="submit" size="sm" variant="success" disabled={this.state.submitBtn!='Update Package'} >
									{this.state.submitBtn}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>	
				</Spin>				
			</React.Fragment>
		);
	}
	
}
export default EditPackage;
