import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Badge, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import AddPackage from './addPackage';
import EditPackage from './editPackage';

class PackageList extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddDrawer: false,
			showDeleteLoader: false,
			showEditDrawer: false,
			editData: [],
			dataList: [],
			dataView: [],
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleAddTrainderClick = this.handleAddTrainderClick.bind(this);
		this.handleAddDrawerCloseClick = this.handleAddDrawerCloseClick.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
		this.handleEditDrawerCloseClick = this.handleEditDrawerCloseClick.bind(this);
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showDeleteLoader: true});
			axios.get(Api.Url+'v1/packages/all?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showDeleteLoader: false
					});
				}
				else{
					this.setState({showDeleteLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showDeleteLoader: false});
		}
	}
	
	handleAddTrainderClick() {
		this.setState({showAddDrawer: true});
	}
	
	handleAddDrawerCloseClick(){
		this.setState({showAddDrawer: false});
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return( (upperCase(obj.trainerid).indexOf(v)>-1) || 
				(upperCase(obj.firstname).indexOf(v)>-1) || 
				(upperCase(obj.lastname).indexOf(v)>-1) || 
				(upperCase(obj.mobile).indexOf(v)>-1));
			});
			
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleDeleteActionClick(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: `Do you want to Delete the Package ${record.name} ?`,
			buttons: {
				Yes: function(){ 
					$this.handleDeleteConfirm(record);
				},
				No: function() { }
			}
		});
	}
	
	handleDeleteConfirm(record){
		try{
			this.setState({showDeleteLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__id', record.id);
			
			axios.post(Api.Url+'v1/package/remove', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					/*var index = d.findIndex(function(obj){
						return(obj.id==record.id);
					});
					d.splice(index,1);*/
					var dat = d.filter(function(obj){
						return(obj.id != record.id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						showDeleteLoader: false
					});
					//this.loadData();
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showDeleteLoader: false});
				}
			});
		}
		catch(error){
			message.error('Unknown Error');
			this.setState({showDeleteLoader: false});
		}
	}
	
	handleEditActionClick(record){
		this.setState({
			editData: record,
			showEditDrawer: true,
		});
	}
	
	handleEditDrawerCloseClick(){
		this.setState({
			editData: [],
			showEditDrawer: false,
		});
	}
	
	
	render(){

		if(this.state.showLoader)
			return(<SpinLoader loading={this.state.showLoader} />);
		
		const columns = [
			{
				title: 'Package Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'No fo days',
				dataIndex: 'days',
				key: 'days',
			},
			{
				title: 'Amount',
				dataIndex: 'amount',
				key: 'amount',
				render: (text, record)=>(
					<div style={{textAlign: 'right'}} >
						<i className="icofont-rupee" /> 
						{record.amount}
					</div>
				)
			},
			{
				title: 'Grace Days',
				dataIndex: 'gracedays',
				key: 'gracedays',
			},
			{
				title: 'Dedicate Trainer',
				dataIndex: 'dedicatedtrainer',
				key: 'dedicatedtrainer',
				render: (text, record)=>(
					<>
						{record.dedicatedtrainer=='no' ? 
							<Badge variant="danger" >
								{upperCase(record.dedicatedtrainer)}
							</Badge > : 
							<Badge variant="success">
								{upperCase(record.dedicatedtrainer)}
							</Badge >
						}
					</>
				)
			},
			{
				title: 'Manage',
				dataIndex: 'id',
				key: 'id',
				render: (text,record)=> (
					<center>
						<Tooltip title="Click to Edit" >
							<Button type="button" size="xs" variant="warning" onClick={this.handleEditActionClick.bind(this, record)} >
								<i className="icofont-edit" />
							</Button>
						</Tooltip>
						&nbsp;
						<Tooltip title="Click to Delete" >
							<Button type="button" size="xs" variant="danger" onClick={this.handleDeleteActionClick.bind(this, record)} >
								<i className="icofont-trash" />
							</Button>
						</Tooltip >
					</center>
				)
			},
		];
				
		return(
			<React.Fragment>
				
				
					<Card>
						<Card.Body>
							<Row>
								<Col md={9} >
									<span className="input-icon">
										<i className="material-icons" >search</i>
										<Form.Control  
											size="sm" 
											placeholder="Search by Trainer Id, Name, Mobile" 
											onChange={this.handleSearchOnChange} />
									  </span>
								 </Col>
								 <Col md={3} >
									<div className="text-right" >
										<Button size="sm" variant="secondary" onClick={this.loadData} >
											<i className="icofont-refresh" /> Refresh
										</Button>
										&nbsp;
										<Button size="sm" variant="secondary" onClick={this.handleAddTrainderClick} >
											<i className="icofont-plus" /> Add Package
										</Button>
									</div>
								 </Col>
							</Row>
						</Card.Body>
					</Card>
					
					<div className="mt-10 ps-table" >
						<Table 
							size="small"
							columns={columns} 
							loading={this.state.showDeleteLoader}
							dataSource={this.state.dataView} />
					</div>
					
					<Drawer 
						visible={this.state.showAddDrawer} 
						placement="right" 
						title="Add Package" 
						width="520"
						onClose={this.handleAddDrawerCloseClick} >						
						{this.state.showAddDrawer ?
							<AddPackage 
								afterFinish={this.loadData} />
						: null}						
					</Drawer>
					
					<Drawer 
						visible={this.state.showEditDrawer} 
						placement="right" 
						title="Edit Package" 
						width="520"
						onClose={this.handleEditDrawerCloseClick} >
						
						{this.state.showEditDrawer ?
							<EditPackage 
								editData={this.state.editData}
								afterFinish={this.loadData} />
						: null}
					</Drawer>
			
			</React.Fragment>
		);
	}
	
}
export default PackageList;
