import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Tabs,
} from 'antd';

import { Api } from '../../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../../utils';
import { SpinLoader } from '../../../elements';
import PsContext from '../../../context/PsContext';

import PersonlDetails from './personalDetails';
import PlanAndPackage from './planAndPackage';
import MemberSettings from './memberSettings';
import MemberAttendance from './memberAttendance';
import Communication from './Communication';

const { TabPane } = Tabs;

class MemberDetails extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			editData: this.props.editData,
		}
		
	}
	
	componentDidMount(){
		
	}
	
	render(){

						
		return(
			<React.Fragment>
				
				<Tabs tabPosition="left" defaultActiveKey="1"  >
					<TabPane tab="Personal Details" key="1" >
						<PersonlDetails {...this.props} />						
					</TabPane>
					
					<TabPane tab="Plan & Package" key="2" >
						<PlanAndPackage {...this.props} />
					</TabPane>
					
					<TabPane tab="Member Logs" key="3" >
						<MemberAttendance {...this.props} />
					</TabPane>
					
					<TabPane tab="Communication" key="4" >
						<Communication {...this.props} />
					</TabPane>
					
					<TabPane tab="Settings" key="5" >
						<MemberSettings {...this.props} />
					</TabPane>
					
				</Tabs>
									
					
			</React.Fragment>
		);
	}
	
}
export default MemberDetails;
