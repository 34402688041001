 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup ,
} from 'react-bootstrap';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../../utils';
import iziToast from 'izitoast';

import PsContext from '../../../context/PsContext';

import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'

const { Option } = Select;

class AddAnnouncement extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Save Announcement',
			showLoader: false,
			editorState: null,
			htmlContent: '',
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleEditorChange = this.handleEditorChange.bind(this);
	}
	
	componentDidMount(){
		
		
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/ma/announce/save', $("#frmAddAnnounce_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmAddAnnounce_Mod").reset();
					this.setState({
						editorState: BraftEditor.createEditorState(''),
						htmlContent: '',
						showLoader: false,
					});
					message.success(res['data'].message || 'Success');
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({
						showLoader: false
					});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleEditorChange(editorState){
		try{
			this.setState({ 
				editorState: editorState,
				htmlContent: editorState.toHTML(),
			});
		}
		catch(error){ console.log(error); }
	}
	
	render(){

		const types = [
			//{key: 'ANNOUNCEMENT', value: 'ANNOUNCEMENT'},
			{key: 'HEALTH_TIPS', value: 'HEALTH_TIPS'},
			{key: 'OFFER', value: 'OFFER'},
		];
		
		return(
			<div>
				<Spin spinning={this.state.showLoader} >
					<form 
						action=""
						method="post"
						encType="multipart/form-data" 
						id="frmAddAnnounce_Mod"
						onSubmit={this.handleFormSubmit}
					>	
						<input type="hidden" name="api" value={this.context.state.api} />
						<input type="hidden" name="i__content" value={this.state.htmlContent} />
						
						<Row>
							<Col md={4} >
								<Row>
									<Col md={12}>
										<label className="font-14" >Announcement Type
											<span className="text-danger">*</span>
										</label>
										<Form.Control
											as="select"
											size="sm"
											name="i__type"
											required
										>
										{types.map((item,i)=>{
											return <option value={item.key}> {item.value} </option>;
										})											
										}
										</Form.Control>
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={12}>
										<label className="font-14" >Announcement Title
											<span className="text-danger">*</span>
										</label>
										<Form.Control
											as="textarea"
											size="sm"
											name="i__title"
											rows="3"
											required
										/>
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={12}>
										<label className="font-14" >Expired On
											<span className="text-danger"></span>
										</label>
										<Form.Control
											type="date"
											size="sm"
											name="i__expiredOn"
											min={momentDate((new Date()),'YYYY-MM-DD')}
										/>
									</Col>
								</Row>
								
								<Row className="mt-15" >
									<Col md={12} >
										<div className="text-right" >
											<Button
												type="submit"
												variant="success"
											>
												Save Changes
											</Button>
										</div>
									</Col>
								</Row>
								
							</Col>
							
							<Col md={8} >
								 <BraftEditor
									  value={this.state.editorState}
									  onChange={this.handleEditorChange}
									  language="en"
									  controls={[
											'undo', 'redo', 'separator',
											'font-size', 'line-height', 'letter-spacing', 'separator',
											'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
											'superscript', 'subscript', 'remove-styles',  'separator', 'text-indent', 'text-align', 'separator',
											'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
											'link', 'separator', 'hr', 'separator',
											//'media', 'separator','emoji',
											'clear'
									  ]}
								 />
							</Col>
						</Row>					
						
					</form>
				</Spin>
			</div>
		);
	}
	
}
export default AddAnnouncement;
