import React, { Component } from 'react';
import $ from 'jquery';
import {NavLink} from 'react-router-dom';
import nav from './nav';

class Menu extends Component{
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
		
	}
	
	componentDidMount(){
		
	}
	
	render(){
		
				
		
		return(
			<div className="nav-section">
				<label className="text-muted">Menu</label>
				<nav id="navSection" className="nav flex-column">
				{nav.map((item,i)=>{
					return <NavLink 
						className="nav-link nav-link-sm"
						to={item.to} 
						key={i} 
						exact
					>
						{item.name}
					</NavLink>;
				})
				}
				</nav>
			  </div>
		);
	}
	
}
export default Menu;
