 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup ,
} from 'react-bootstrap';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch
} from 'antd';

import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import iziToast from 'izitoast';

import PsContext from '../../context/PsContext';

const { Option } = Select;

class ProductEnquiry extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			paginationPageSize: 30,
			dataList: [],
			dataView: [],
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
	}

	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/product_enquiry?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return((upperCase(obj.membername).indexOf(v)>-1) || (upperCase(obj.productname).indexOf(v)>-1) || (upperCase(obj.mobile).indexOf(v)>-1));
			});
			
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	render(){
		
		const columns = [
			{
				title: '#',
				dataIndex: 'id',
				key: 'id',
			},
			{
				title: 'Date',
				dataIndex: 'idate',
				key: 'idate',
				render: (text, record)=>(
					<span>
					{momentDate(record.idate,'DD-MM-YYYY')}
					</span>
				)
			},
			{
				title: 'Name',
				dataIndex: 'membername',
				key: 'membername'
			},
			{
				title: 'Mobile',
				dataIndex: 'mobile',
				key: 'mobile',
			},			
			{
				title: 'Product',
				dataIndex: 'productname',
				key: 'productname',
			},
			{
				title: 'Message',
				dataIndex: 'content',
				key: 'content',
			}
		];
		

		return(
			<div>
			
				<Card>
					<Card.Body>
						<Row>
							<Col md={6} >
								<span className="input-icon">
									<i className="material-icons" >search</i>
									<Form.Control  
										size="sm" 
										placeholder="Search by Name" 
										onChange={this.handleSearchOnChange} />
								  </span>
							 </Col>
							 <Col md={3} >
								
							 </Col>
							 <Col md={3} >
								<div className="text-right" >
									<ButtonGroup >
										<Button size="sm" variant="outline-secondary" onClick={this.loadData} >
											<i className="icofont-refresh" /> Refresh
										</Button>
									</ButtonGroup>
								</div>
							 </Col>
						</Row>
					</Card.Body>
				</Card>
				
				<div className="mt-10 ps-table" >
					<Table 
						size="small"
						loading={this.state.showLoader}
						columns={columns} 
						dataSource={this.state.dataView} />
				</div>
							
			</div>
		);
	}
	
}
export default ProductEnquiry;
