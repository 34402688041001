import React, { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';
import { Row,Col, Card, Form, Button, ButtonGroup, ToggleButton} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, todayDate, groupByMultiple
} from '../../../utils';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch
} from 'antd';

import { Api } from '../../../utils/Variables';

import PsContext from '../../context/PsContext';
import ReportMenu from './reportMenu';
import NewMenu from './NewMenu';

const { Option } = Select;

const styles = {
	table: {
		borderCollapse: 'collapse',
		border: '1px solid black',
		fontSize: '12px',
	},
	thead: {
		borderCollapse: 'collapse',
		border: '1px solid black',
		fontSize: '12px',
		fontWeight: '600',
	}
}

class MembersReport extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
			showLoader: true,
			dataList: [],
			dataView: [],
			btnValue: 'ALL',
			planWiseData: [],
		}
		
		this.handlePrintClick = this.handlePrintClick.bind(this);
		this.handleRowGroupDropDownChange = this.handleRowGroupDropDownChange.bind(this);
	}
	
	componentDidMount(){
		
		this.loadData();
		
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/members/plan_wise_report?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					var d = res['data'].data;
					var pd = groupByMultiple(d, function(obj){
						return [obj.current_plan];
					});
					
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						planWiseData: pd,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
					message.error(res['data'].message || 'Error');
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	printDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.atClose)
			 this.props.atClose();
	}
	
	handlePrintClick(){
		this.printDocument("emp_list");
	}
	
	handleStatusRadioChange(value,e){
		try{
			var d = this.state.dataList;
			var s = d;
			if(value=='ACTIVE'){
				s = d.filter(function(obj){
					return(obj.member_device_status=='1');
				});
			}
			else if(value=='INACTIVE'){
				s = d.filter(function(obj){
					return(obj.member_device_status=='0');
				});
			}
			else if(value=='EXPIRED'){
				s = d.filter(function(obj){
					return(
						(new Date(obj.current_plan_expires_on).getTime())<=(new Date(todayDate()).getTime())
					)
				});
			}
			else if(value=='FPNOT'){
				s = d.filter(function(obj){
					return(obj.finger_print_count=='0');
				});
			}
			
			this.setState({
				btnValue: value,
				dataView: s
			});
		}
		catch(error){
			
		}
	}
	
	exportTableToExcel(tableID, filename = ''){
		try{
			
			this.setState({
				showLoader: true
			});
				
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
			
			// Specify file name
			filename = filename?filename+'.xls':'excel_data.xls';
			
			// Create download link element
			downloadLink = document.createElement("a");
			
			document.body.appendChild(downloadLink);
			
			if(navigator.msSaveOrOpenBlob){
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob( blob, filename);
				
				this.setState({ showLoader: false });
			}else{
				// Create a link to the file
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
			
				// Setting the file name
				downloadLink.download = filename;
				
				//triggering the function
				downloadLink.click();
				
				this.setState({ showLoader: false });
			}	
			
		}
		catch(error){
			this.setState({ showLoader: false });
		}
	}
	
		handleRowGroupDropDownChange(value){
		this.setState({showLoader: true});
		var $this = this;
		setTimeout(function() { 
			$this.updateRowGroupDropdownChange(value);
		}, 2000);
	}
	
	updateRowGroupDropdownChange(value){
		try{
			this.setState({showLoader: true});
			var d = this.state.dataList;
			if(value != 'ALL'){
				d = this.state.dataList.filter(function(obj){
					return(obj.current_plan==value);
				});
			}
						
			this.setState({
				dataView: d,
				btnValue: value,
				showLoader: false,
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){

		var sno=1;
		const rowGroup = [
			{key: 'ALL', value: 'Show All'},
			{key: 'PLAN_EXPIRED', value: 'Plan Expired Only'},
			{key: 'DISABLED_IN_MACHINE', value: 'Show Reocrds Disabled in Machine'},			
			{key: 'ENABLED_IN_MACHINE', value: 'Show Reocrds  Enabled in Machine'},			
		];
		
		
		return(
			<React.Fragment>
				
				<NewMenu />
				
				<Row>
					<Col md={12} >
						
						<Spin 
							size="large"
							tip="Please wait..."
							spinning={this.state.showLoader} >
							
							<Row>
								<Col md={4} >
									<Select
									  showSearch
									  style={{ width: 180 }}
									  placeholder="Select"
									  optionFilterProp="children"
									  style={{width: '100%'}}
									  onChange={this.handleRowGroupDropDownChange}
									  defaultValue={this.state.btnValue}
									  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									>
										<Option key="ALL" value="ALL" > Show All </Option>
									  {this.state.planWiseData.map((item) => (
										<Option key={item[0].current_plan} value={item[0].current_plan}> {item[0].planname} </Option>
									  ))}
									</Select>
								</Col>
								<Col md={5} >
								
								</Col>
								<Col md={3} >
									<div className="text-right" >
										<ButtonGroup>
											<Button
												type="button"
												size="sm"
												variant="outline-secondary"
												onClick={this.handlePrintClick}
											>
												<i className="icofont-printer" ></i> Print
											</Button>
											<Button
												type="button"
												size="sm"
												variant="outline-secondary"
												onClick={this.exportTableToExcel.bind(this, 'emp_list_table', 'Reports')}
											>
												<i className="icofont-file-excel" ></i> Export to Excel
											</Button>
											
										</ButtonGroup>
									</div>
								</Col>
										
							</Row>
							
							<Card style={{minHeight: 'calc(100vh - 120px)'}} className="mt-20" >
								<Card.Body>
									<Row>
									<Col md={12} >
									
									<div id="emp_list" style={{
											overflowX: 'scroll',
									}} >
										<table width="100%" id="emp_list_table"  >
											<tbody  >
												<tr>
													<td align="center"  colSpan="2" >
														<br />
														<b style={{
															fontSize: '18px',
															textTransform: 'uppercase',
														}}>{this.context.state.loggedUser.name}</b>
														<br />Plan Wise Members Report
														<br />
														<br />
													</td>
												</tr>
												<tr>
													<td>
														Plan Name : &nbsp; 
														{Object.keys(this.state.dataView).length > 0 && this.state.btnValue!='ALL' ? 
														this.state.dataView[0].planname : this.state.btnValue}
													</td>
													<td align="right" >
														Total Members : {Object.keys(this.state.dataView).length}
													</td>
												</tr>
												<tr>
													<td colSpan="2" >
														<table width="100%" style={styles.table} >
															<thead>
															<tr style={styles.thead} >
																<th height="30" align="center" >S.No</th>
																<th>Code</th>
																<th>Name</th>
																<th>Gender</th>
																<th width="80" >DOB</th>
																<th align="" >Mobile</th>
																<th align="" >Address</th>
																<th align="" width="80">Joined</th>
																<th align="" >Expectation</th>
																<th align="" >Package</th>
																<th align="" >Balance To Paid</th>
																<th align="" >Next Installment</th>
																<th align="" width="80">Expire On</th>
																<th align="" >Machine Status</th>
															</tr>
														</thead>
														<tbody style={styles.table} >														
														{this.state.dataView.map((item,i)=>{
															return <tr key={i} style={styles.table} >
																<td>{sno++}</td>
																<td>{item.memberid}</td>
																<td>{item.firstname} {item.lastname}</td>
																<td >{upperCase(item.gender)}</td>
																<td>{momentDate(item.dob, 'DD-MM-YYYY')}</td>
																<td>{item.mobile}</td>
																<td>{item.address}</td>																
																<td>{momentDate(item.doa, 'DD-MM-YYYY')}</td>
																<td>{item.expectation}</td>
																<td>{item.planname}</td>
																<td align="right" >{item.balance_to_paid}</td>
																<td>{item.next_installment_on == '0000-00-00 00:00:00' ? '' : momentDate(item.next_installment_on, 'DD-MM-YYYY')}</td>
																<td>{momentDate(item.current_plan_expires_on, 'DD-MM-YYYY')}</td>
																<td>{item.member_device_status=='1' ? 'Active' : 'In Active'}</td>
															</tr>;
														})												
														}
														</tbody>
													</table>	
												</td>
											</tr>
											</tbody>
										</table>	
										</div>
										
										</Col>
										</Row>
										
								</Card.Body>
							</Card>
						</Spin>
						
					</Col>
				
				</Row>
				
			
			</React.Fragment>
		);
	}
	
}
export default MembersReport;
