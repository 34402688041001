 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup ,
} from 'react-bootstrap';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../../utils';
import iziToast from 'izitoast';

import PsContext from '../../../context/PsContext';
import Filemanager from '../../Filemanager';

const { Option } = Select;

const iconStyle1 = {fontSize : '30px', borderRadius : '30px', padding : '8px', marginRight : '10px', background : 'white', color : '#00acf0'}
const iconStyle2 = {fontSize : '30px', borderRadius : '30px', padding : '8px', marginRight : '10px', background : 'white', color : '#22af47'}
const iconStyle3 = {fontSize : '30px', borderRadius : '30px', padding : '8px', marginRight : '10px', background : 'white', color : '#ffbf36'}
const iconStyle4 = {fontSize : '30px', borderRadius : '30px', padding : '8px', marginRight : '10px', background : 'white', color : '#1ebccd'}
const spanStyle = {fontSize : '25px', borderRadius : '25px', padding : '0px 10px', border : '1px solid white', color : 'wheat'}

class Dashboard extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showAddModal: false,
			paginationPageSize: 30,
			gymCount : 0,
			productCount : 0,
			pageCount : 0,
			enquiryCount : 0,
		}
		
		this.loadData = this.loadData.bind(this);
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData()
	{
		try{
			this.setState({showLoader: true});
			
			axios.get(Api.Url+'v1/ma/dashboard/count?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						gymCount: res['data'].gym,
						productCount: res['data'].product,
						pageCount: res['data'].page,
						enquiryCount: res['data'].enquiry,
						showLoader: false,
					});
					
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			
			this.setState({showLoader: false});
		}
	}
	

	render(){

		return(
			<div>
			
				<Row className="mt-15" >
					<Col md={3} >
						<Link to="app/fitness-centre" style={{textDecoration : 'none', color : 'white'}}>
							<Card bg="primary">
								<Card.Body style={{paddingTop : '23px'}}>
									<i className="icofont-building" style={iconStyle1}></i>
									<span className=" mb-2" style={{fontSize : '22px'}}>Gyms</span>
									<span className="float-right" style={spanStyle}>{parseInt(this.state.gymCount)}</span>
								</Card.Body>
							</Card>
						</Link>
					</Col>
					<Col md={3} >
						<Link to="app/products" style={{textDecoration : 'none', color : 'white'}}>
							<Card bg="success">
								<Card.Body style={{paddingTop : '23px'}}>
									<i className="icofont-basket" style={iconStyle2}></i>
									<span className=" mb-2" style={{fontSize : '22px'}}>Products</span>
									<span className="float-right" style={spanStyle}>{parseInt(this.state.productCount)}</span>
								</Card.Body>
							</Card>
						</Link>
					</Col>
					<Col md={3} >
						<Link to="app/master/pages" style={{textDecoration : 'none', color : 'white'}}>
							<Card bg="warning">
								<Card.Body style={{paddingTop : '23px'}}>
									<i className="icofont-list" style={iconStyle3}></i>
									<span className=" mb-2" style={{fontSize : '22px'}}>Pages</span>
									<span className="float-right" style={spanStyle}>{parseInt(this.state.pageCount)}</span>
								</Card.Body>
							</Card>
						</Link>
					</Col>
					<Col md={3} >
						<Link to="app/master/product-enquiry" style={{textDecoration : 'none', color : 'white'}}>
							<Card bg="info">
								<Card.Body style={{paddingTop : '23px'}}>
									<i className="icofont-info" style={iconStyle4}></i>
									<span className=" mb-2" style={{fontSize : '22px'}}>Enquiries</span>
									<span className="float-right" style={spanStyle}>{parseInt(this.state.enquiryCount)}</span>
								</Card.Body>
							</Card>
						</Link>
					</Col>
				</Row>
					
			</div>
		);
	}
	
}
export default Dashboard;
