
import Primary from './Pages/Primary';
import Basic from './Pages/Basic';
import MessageWishes from './Pages/MessageWishes';

const nav = [
	{name: 'Remove Users', to:'/app/settings/', component: Primary },
	//{name: 'Remove Users', to:'/app/settings/remove-users', component: Basic },
	{name: 'Message Template', to:'/app/settings/message-template', component: MessageWishes },
];			

export default nav;
