import React, { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import axios from 'axios';

import PsContext from '../../context/PsContext';

class ReportMenu extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
		}
	}
	
	componentDidMount(){
		
		
	}
	
	render(){
	
		var menuList = [
			{to: '/app/members-report', name: 'Export All'},
			{to: '/app/members-report/active-in-machine', name: 'Active in Machine'},
			{to: '/app/members-report/inactive-in-machine', name: 'Inactive in Machine'},
		];
		
		return(
		  <div className="nav-section">
			<label className="text-muted">Report Menu</label>
				<nav id="navSection" className="nav flex-column">
					{menuList.map((item,i)=>{
						return <NavLink className="nav-link nav-link-sm" to={item.to} exact > {item.name} </NavLink>;
					})				
					}
				</nav>
		  </div>						
		);
	}
	
}
export default ReportMenu;
