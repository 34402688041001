import React, { Component } from 'react';
import {Redirect,NavLink} from 'react-router-dom';
import $ from 'jquery';

import {Row, Col, Button} from 'react-bootstrap';

import PsContext from '../../context/PsContext';


class LoginMenu extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			
		}
		
		
	}
	
	componentDidMount(){
		
	}
	
		
	render(){

		
		return(
			<ul className="nav nav-tabs">
			  <li className="nav-item">
				<NavLink to="/" className="nav-link" exact >Admin Login</NavLink>
			  </li>
			  <li className="nav-item">
				<NavLink to="/login" className="nav-link" >User Login</NavLink>
			  </li>
			</ul>			
		);
	}
	
}
export default LoginMenu;
