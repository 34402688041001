import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';

import { Api } from '../../../utils/Variables';
import { getDateByFormat } from '../../../utils/appUtils';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, 
} from '../../../utils';
import stateDistricts from '../../../utils/stateDistricts.json';
import ReactFileReader from 'react-file-reader';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import UPLOAD_ARROW from '../../../assets/img/upload-arrow.png';

const { Option } = Select;

class AddPackage extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dayLimit: (new Date()),
			submitBtn: 'Save Package',
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleDaysChange = this.handleDaysChange.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	handleDaysChange(e){
		var date = new Date();
		var d = new Date(date.getTime() + (e.target.value * 24 * 60 * 60 * 1000));
		this.setState({dayLimit: d});
	}
	
	handleFormSubmit(e) {
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, submitBtn: '....'});			
			axios.post(Api.Url+'v1/package/save', $("#frm_addPackage_DForm").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frm_addPackage_DForm").reset();
					message.success(res['data'].message || 'Success');
					this.setState({
						showLoader: false, 
						submitBtn: 'Save Package',
					});
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false, submitBtn: 'Save Package'});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false, submitBtn: 'Save Package'});
		}
	}
	
	
	render(){

		const date = new Date();
		//const dayLimit = new Date(date.getTime() + (e.target.value * 24 * 60 * 60 * 1000))
  
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<form action="" method="post" encType="multipart/form-data" id="frm_addPackage_DForm" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="api" value={this.context.state.api} />
					
					<Row>
						<Col md={12} >
						
							<Row>
								<Col md={6} >
									<label>
										Package Name
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__name" 
										type="text" 
										size="sm"
										placeholder="Name" 
										onKeyPress={lettersOnly}
										required
									/>
								</Col>
								
								<Col md={6} >
									<label>
										No of Days
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__days" 
										type="number" 
										size="sm"
										placeholder="No of Days" 
										onChange={this.handleDaysChange}
									/>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={6} >
									<label>
										Grace Period Days
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__graceDays" 
										type="number" 
										size="sm"
										placeholder="Grace Period Days" 
									/>
								</Col>
								
								<Col md={6} >
									<label>
										Dedicated Trainer
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__dedicatedTrainer"
										as="select"
										size="sm"
										required
									>
										<option value="no"> No </option>
										<option value="yes"> Yes </option>
									</Form.Control>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={6} >
									<label>
										Validity To
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__validityTo" 
										type="date" 
										size="sm"
										min={getDateByFormat(this.state.dayLimit, 'YYYY-MM-DD')}
										placeholder="Validity To" 
									/>
								</Col>
								
								<Col md={6} >
									<label>
										Package Amount
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__amount" 
										type="number" 
										size="sm"
										placeholder="Amount" 
										onKeyPress={decimalKeyPress}
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={6} >
									<label>
										Allowed Part Time Payment 
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__partPayment"
										as="select"
										size="sm"
										required
									>
										<option value="no"> No </option>
										<option value="yes"> Yes </option>
									</Form.Control>
								</Col>
							</Row>
							
									
						</Col>
						
					</Row>
					
					<Row>
						<Col md={12} >
							<hr />
							<div className="text-center" >
								<Button type="submit" size="sm" variant="success" disabled={this.state.submitBtn!='Save Package'} >
									{this.state.submitBtn}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>	
				</Spin>				
			</React.Fragment>
		);
	}
	
}
export default AddPackage;
