import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, Select,message, TimePicker,
} from 'antd';

import { Api } from '../../../utils/Variables';
import { getDateByFormat } from '../../../utils/appUtils';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress,
} from '../../../utils';
import stateDistricts from '../../../utils/stateDistricts.json';
import ReactFileReader from 'react-file-reader';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import UPLOAD_ARROW from '../../../assets/img/upload-arrow.png';

const { Option } = Select;

class AddEnquiry extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			startTime: '',
			endTime: '',
			submitBtn: 'Save Enquiry',
		}
		
	
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.onStartTimeChange = this.onStartTimeChange.bind(this);
		this.onEndTimeChange = this.onEndTimeChange.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	onStartTimeChange(time, timeString){
		try{
			this.setState({
				startTime: timeString
			});
		}
		catch(error){
			
		}
	}
	
	onEndTimeChange(time, timeString){
		try{
			this.setState({
				endTime: timeString
			});
		}
		catch(error){
			
		}
	}
	
	
	handleFormSubmit(e) {
		e.preventDefault();
		try{
		
			this.setState({showLoader: true, submitBtn: '....'});			
			axios.post(Api.Url+'v1/enquiry/save', $("#frmAdd_Enquiry_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmAdd_Enquiry_Mod").reset();
					message.success(res['data'].message || 'Success');
					this.setState({
						showLoader: false, 
						submitBtn: 'Save Enquiry',
						startTime: '',
						endTime: '',
					});
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false, submitBtn: 'Save Enquiry'});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false, submitBtn: 'Save Enquiry'});
		}
	}
	
	
	render(){

		const date = new Date();
		const ageLimit = date.setFullYear(date.getFullYear() - 10);
  
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<form action="" method="post" encType="multipart/form-data" id="frmAdd_Enquiry_Mod" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="api" value={this.context.state.api} />
					
					<Row>
						<Col md={12} >
							<label>
								Name of the Person
								<span className="text-danger">*</span>
							</label>
							<Form.Control
								type="text"
								size="sm"
								name="i__name"
								autoComplete="off"
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={6} >
							<label>
								Mobile Number
								<span className="text-danger">*</span>
							</label>
							<Form.Control
								type="number"
								size="sm"
								name="i__mobile"
								autoComplete="off"
								onKeyPress={integerIndMobile}
								required
							/>
						</Col>
						<Col md={6} >
							<label>
								Next Follow up date
								<span className="text-danger">*</span>
							</label>
							<Form.Control
								type="date"
								size="sm"
								name="i__nextDate"
								autoComplete="off"
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={6} >
							<label>
								Gender
								<span className="text-danger">*</span>
							</label>
							<Form.Control
								as="select"
								name="i__gender"
								size="sm" 
								required
							>
								<option value="Male">Male</option>
								<option value="Female">Female</option>
							</Form.Control>
						</Col>
						<Col md={6} >
							<label>
								Age
								<span className="text-danger"></span>
							</label>
							<Form.Control
								type="number"
								size="sm"
								name="i__age"
								autoComplete="off"
								onKeyPress={integerKeyPress}
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<label>
								Reason for visit
								<span className="text-danger">*</span>
							</label>
							<Form.Control
								type="text"
								size="sm"
								name="i__reason"
								autoComplete="off"
								required
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<label>
								Address
								<span className="text-danger">*</span>
							</label>
							<Form.Control
								as="textarea"
								size="sm"
								name="i__address"
								autoComplete="off"
								rows="4"
							/>
						</Col>
					</Row>
					
					<Row>
						<Col md={12} >
							<hr />
							<div className="text-center" >
								<Button type="submit" size="sm" variant="success" disabled={this.state.submitBtn!='Save Enquiry'} >
									{this.state.submitBtn}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>	
				</Spin>				
			</React.Fragment>
		);
	}
	
}
export default AddEnquiry;
