import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import iziToast from 'izitoast';
import {Row, Col, Button, Card, Carousel} from 'react-bootstrap';

import PsContext from '../../context/PsContext';

import LOGO from '../../../assets/img/logo.png';
import SLIDER_1 from '../../../assets/img/slider_1.png';
import LoginMenu from './LoginMenu';

class Login extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			isRedirect: false,
			loggedUser: [],
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCheckClick = this.handleCheckClick.bind(this);		
	}
	
	componentDidMount(){
		//console.log(process.env.NODE_ENV);
	}
		
	handleFormSubmit(e){
		e.preventDefault();
		try{			
			 this.setState({showLoader: true, btnText: 'Validating..'});
			axios.post(Api.Url+'v1/login', $("#frmLogin").serialize()).then(res=>{
				
				if(res['data'].status === '1'){
					
					this.context.updateUserLogin(res['data'].data, res['data'].role);
					this.context.updateAccessApi(res['data'].api);
					notify.show(res['data'].message,'success');
					//setlsItem('user_logged', 'YES');
					///setls('user', res['data'].data);
					//this.setState({isRedirect: true, loggedUser: res['data'].data});
					//window.location.href='';
				}
				else{
					//notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnText: 'Login'});
					iziToast.show({
						message: res['data'].message,
						position: 'topCenter',
						color: 'red',
					});
				}
			});	 
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false, btnText: 'Login'});
		}
	}
	
	componentWillUpdate(){
		
	}
	
	handleCheckClick(){
		
		/* $.get('http://159.89.175.196:8080/pst/index.html', function(obj){
			console.log(obj);
		},'json'); */
		/* axios.get('http://159.89.175.196:8080/pst/index.html', {
		 headers: {"Access-Control-Allow-Origin": "true"}
		})
		.then((res) => {
		  console.log(res.data)
		})
		.catch((error) => {
		  console.error(error)
		}); */
		fetch('http://159.89.175.196:8080/pst/index.html', {
		  mode: 'cors',
		  credentials: 'include'
		});
	}
		
	render(){

		//if(this.state.isRedirect || getlsItem('user_logged')=='YES'){
		
		if(this.context.state.userLogged=='yes'){
			return(<Redirect to="/app" />);
		}
		
		return(

			<div className="" >
			
			<Notifications />			
			
		
			<div className="container" >
			
			<Row  className="mt-30 bg-white" >
				<Col md={5} >
										
						<center>
							<img src={LOGO} 
								
								className="pt-10 pb-10" 
							/>
						</center>
						<br /><br />
						<LoginMenu />
						<Card>
						<Card.Body>
						<form onSubmit={this.handleFormSubmit} method="post" id="frmLogin" encype="multipart/form-data" >
						  <div className="form-group">
							<div className="floating-label input-icon">
							  <i className="material-icons">person_outline</i>
							  <input type="text" className="form-control" placeholder="Username" id="i__userName" name="i__userName" required autocomplete="off" autoFocus={true} />
							  <label for="username">Username</label>
							</div>
						  </div>
						  <div className="form-group">
							<div className="floating-label input-icon">
							  <i className="material-icons">lock_open</i>
							  <input type="password" className="form-control" placeholder="Password" id="i__password" name="i__password" required />
							  <label for="password">Password</label>
							</div>
						  </div>
						  <div className="form-group d-flex justify-content-between align-items-center">
							<div className="custom-control custom-checkbox">
							  <input type="checkbox" className="custom-control-input" id="remember" />
							  <label className="custom-control-label" for="remember">Remember me</label>
							</div>
							{/*<a href="forgot-password.html" className="text-primary text-decoration-underline small">Forgot password ?</a>*/}
						  </div>
						  <button 
								type="submit" 
								className="mt-20 btn btn-danger btn-block btn-lg" 
								disabled={this.state.btnText!='Login'} 
							>
								{this.state.btnText}
							</button>
						</form>
						</Card.Body>
						</Card>
						
					
					
				</Col>
				
				<Col md={7} className="pr-0"  >
					<Carousel>
					  <Carousel.Item>
						<img
						  className="d-block w-100"
						  src={SLIDER_1}
						  alt="First slide"
						/>
						
					  </Carousel.Item>
					  </Carousel>
				</Col>
			</Row>
			
			</div>
				  
			</div>
		);
	}
	
}
export default Login;
