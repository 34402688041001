import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {Row,Col,Card, Navbar, NavDropdown, Nav } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {getlsItem, setls, setlsItem, getls} from '../../../utils';
import {Var} from '../../../utils/Variables';

class Footer extends Component{
	constructor(props){
		super(props);
		this.state = {
			
		}		
	}
	
	render(){
		
		return(
			<footer className="py-4 bg-light mt-auto">
				<div className="container-fluid">
					<div className="d-flex align-items-center justify-content-between small">
						<div className="text-muted">Copyright &copy; {Var.Title} 2020</div>
						<div>
							<a href="" target="_blank" >Privacy Policy</a>
							&middot;
							<a href="" target="_blank" >Terms &amp; Conditions</a>
						</div>
					</div>
				</div>
			</footer>
		);
	}
	
}
export default Footer;
