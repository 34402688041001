import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge ,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import AddMember from './addMember';
import EditMember from './editMember';
import MemberDetails from './MemberDetails';

const { Option } = Select;

class MemberFilter extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: this.props.editData,
			dataView: [],
			trainerList: [],
			packageList: [],
			timingList: [],
			selectedRowGroup: 'ALL',
			selectedPlan: '',
			selectedTrainer: '',
			selectedTiming: '',
		}
		
		
	}
	
	componentDidMount(){
		
		this.loadList();
	}
	
	loadList() {
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/members/options?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						trainerList: res['data'].data.trainers,
						packageList: res['data'].data.packages,
						timingList: res['data'].data.timings,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	updateRowGroupDropdownChange(value){
		try{
			this.setState({showDeleteLoader: true});
			var d = this.state.dataList;
			if(value=='DISABLED_IN_MACHINE'){
				d = this.state.dataList.filter(function(obj){
					return(obj.member_device_status=='0');
				});
			}
			else if(value=='ENABLED_IN_MACHINE'){
				d = this.state.dataList.filter(function(obj){
					return(obj.member_device_status=='1');
				});
			}
			
			this.setState({
				dataView: d,
				selectedRowGroup: value,
				showDeleteLoader: false,
			});
		}
		catch(error){
			this.setState({showDeleteLoader: false});
			console.log(error);
		}
	}
	
		
	render(){

		const rowGroup = [
			{key: 'ALL', value: 'Show All'},
			{key: 'DISABLED_IN_MACHINE', value: 'Show Reocrds Disabled in Machine'},			
			{key: 'ENABLED_IN_MACHINE', value: 'Show Reocrds  Enabled in Machine'},			
		];
				
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
					<Row>
						<Col md={12} >
							<label>Package</label>
							<Select
								  showSearch
								  style={{ width: 180 }}
								  placeholder="Select"
								  optionFilterProp="children"
								  style={{width: '100%'}}
								  onChange={this.handleRowGroupDropDownChange}
								  defaultValue={this.state.selectedRowGroup}
								  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
									<Option key="0" value="ALL"> All </Option>
								  {this.state.packageList && this.state.packageList.map((item) => (
									<Option key={item.key} value={item.id}> {item.name} - {item.amount} </Option>
								  ))}
								</Select>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<Select
								  showSearch
								  style={{ width: 180 }}
								  placeholder="Select"
								  optionFilterProp="children"
								  style={{width: '100%'}}
								  onChange={this.handleRowGroupDropDownChange}
								  defaultValue={this.state.selectedRowGroup}
								  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
								  {rowGroup.map((item) => (
									<Option key={item.key} value={item.key}> {item.value} </Option>
								  ))}
								</Select>
						</Col>
					</Row>
					
					
				</Spin>				
			</React.Fragment>
		);
	}
	
}
export default MemberFilter;
