import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Tabs, Select,
} from 'antd';

import { Api } from '../../../../utils/Variables';
import {
  getls, upperCase, momentDate, todayDate, decimalKeyPress, 
} from '../../../../utils';
import { SpinLoader } from '../../../elements';
import PsContext from '../../../context/PsContext';

const { TabPane } = Tabs;
const { Option } = Select;

class UpgradePlan extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			editData: this.props.editData,
			memberUId: this.props.id,
			memberId: this.props.memberId,
			editData: this.props.editData,
			selectedPackageId: '',
			selectedTiming: '',
			selectedTrainer: '',
			trainerList: [],
			packageList: [],
			timingList: [],
			selectedPackage: [],
			amountReceived: '',
			btnText: 'Upgrade Now',
		}
		
		this.handlePackageChange = this.handlePackageChange.bind(this);
		this.handleTrainerChange = this.handleTrainerChange.bind(this);
		this.handleAmountReceivedChange = this.handleAmountReceivedChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadList();
	}
	
	loadList() {
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/members/options?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						trainerList: res['data'].data.trainers,
						packageList: res['data'].data.packages,
						timingList: res['data'].data.timings,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handlePackageChange(value){
		try{
			var d = this.state.packageList.filter(function(obj){
				return(obj.id==value);
			});
			if(d){
				this.setState({
					selectedPackage: d[0],
					selectedPackageId: d[0]['id'],
				});
			}
		}
		catch(error){
			
		}
	}
	
	handleTrainerChange(value){
		try{
			var d = this.state.trainerList.filter(function(obj){
				return(obj.id==value);
			});
			if(d){
				this.setState({
					selectedTrainer: d[0],
					selectedTrainerId: d[0]['id'],
				});
			}
		}
		catch(error){
			
		}
	}
	
	handleAmountReceivedChange(e){
		try{
			var v = e.target.value;
			var amt = parseFloat(this.state.selectedPackage.amount);
			if(parseFloat(v)>amt){
				message.error('Received amount must less than or equal to package amount');
				this.setState({amountReceived: 0});
				return;
			}
			
			this.setState({amountReceived: parseFloat(v)});
		}
		catch(error){ }
	}
	

	getData(fieldName){
		try{
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			var amt = parseFloat(this.state.selectedPackage.amount);
			var v = parseFloat(this.state.amountReceived);
			if(v == 0 || v > amt){
				message.error('Received amount must less than or equal to package amount');
				return;
			}
			
			this.setState({
				showLoader: true, 
				btnText: '...'
			});
			axios.post(Api.Url+'v1/member/package_update', $("#frm_upgradePlan_Mod").serialize()).then(res=>{
				document.getElementById("frm_upgradePlan_Mod").reset();
				if(res['data'].status=='1'){
					this.setState({
						showLoader: false, 
						btnText: 'Upgrade Now',
						selectedPackage: [],
						selectedPackageId: '',
						selectedTrainer: [],
						selectedTrainerId: '',
						amountReceived: '0',
					});
					message.success(res['data'].message || 'Success');
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					this.setState({
						showLoader: false, 
						btnText: 'Upgrade Now'
					});
					message.danger(res['data'].message || 'Error');
				}
			});
			
		}
		catch(error){
			this.setState({
				showLoader: false, 
				btnText: 'Upgrade Now'
			});
		}
	}
	
	render(){

						
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<form 
					action="" 
					method="post" 
					encType="multipart/form-data" 
					id="frm_upgradePlan_Mod" 
					onSubmit={this.handleFormSubmit} 
				>
				
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="i__memberId" value={this.getData('id')} />
					<input type="hidden" name="i__packageId" value={this.state.selectedPackageId} />
					<input type="hidden" name="i__trainerId" value={this.state.selectedTrainerId} />
					<input type="hidden" name="i__planDays" value={this.state.selectedPackage.days} />
					
					<Row>
						<Col md={4} >
							<label>Member Id</label>
						</Col>
						<Col md={8} >
							<Form.Control
								type="text" 
								name="i__memberCode" 
								size="sm"
								value={this.getData('memberid')} 
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={4} >
							<label>Name</label>
						</Col>
						<Col md={8} >
							<Form.Control
								type="text" 
								name="i__memberName"
								size="sm"
								value={this.getData('firstname')+' '+this.getData('lastname')} 
							/>
						</Col>
					</Row>
					{this.props.editData.balance_to_paid == '0.00' ? 
					<Row className="mt-10" >
						<Col md={4} >
							<label>Pacakge</label>
						</Col>
						<Col md={8} >
							<Select
							  showSearch
							  style={{ width: 180 }}
							  placeholder="Select a Package"
							  optionFilterProp="children"
							  onChange={this.handlePackageChange}
							  style={{width: '100%'}}
							  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
							>
							  {this.state.packageList && this.state.packageList.map((item) => (
								<Option key={item.id} value={item.id}>{item.name} ({item.amount})</Option>
							  ))}
							</Select>
						</Col>
					</Row> : 
						<Row className="mt-10" >
							<Col md={12} >
								<center style={{color : 'red'}}>Dues Available. So you can't add new plan</center>
							</Col>
						</Row>
					}
					
					{Object.keys(this.state.selectedPackage).length>0 ? <>
						<hr />
						<Row >
							<Col md={4} >
								<label>Amount</label>
							</Col>
							<Col md={8} >
								<Form.Control
									type="text" 
									size="md"
									name="i__packageAmount"
									style={{fontWeight: '600'}} 
									className="bg-yellow "
									value={this.state.selectedPackage.amount} 
								/>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={4} >
								<label>Activated From</label>
							</Col>
							<Col md={8} >
								<Form.Control
									type="date" 
									size="sm"
									name="i__activatedFrom"
									required
								/>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={4} >
								<label>Total Days</label>
							</Col>
							<Col md={3} >
								Days
								<Form.Control
									type="text" 
									name="i__packageDays"
									size="sm"
									value={this.state.selectedPackage.days} 
								/>
							</Col>
							<Col md={2} >
								Grace
								<Form.Control
									type="text" 
									size="sm"
									name="i__graceDays"
									value={this.state.selectedPackage.gracedays} 
								/>
							</Col>
							<Col md={3} >
								Total
								<Form.Control
									type="text" 
									size="sm"
									name="i__totalDays"
									className="bg-yellow  "
									value={parseInt(this.state.selectedPackage.days) + parseInt(this.state.selectedPackage.gracedays)} 
								/>
							</Col>
						</Row>
						
					</> : null}
					
					{Object.keys(this.state.selectedPackage).length>0 
						&& this.state.selectedPackage 
						&& this.state.selectedPackage.parttimepayment=='yes' ? <>
						<Row className="mt-10"  >
							<Col md={4} >
								<label>Next Payment</label>
							</Col>
							<Col md={8} >
								<Form.Control
									type="date" 
									size="sm"
									name="i__nextPaymentDate"
									min={todayDate()}
								/>
							</Col>
						</Row>
					</> : null}
					
					{Object.keys(this.state.selectedPackage).length>0 
						&& this.state.selectedPackage 
						&& this.state.selectedPackage.dedicatedtrainer=='yes' ? <>
						<Row className="mt-10"  >
							<Col md={4} >
								<label>Trainer</label>
							</Col>
							<Col md={8} >
								<Select
									  showSearch
									  style={{ width: 180 }}
									  placeholder="Select a Trainer"
									  optionFilterProp="children"
									  onChange={this.handleTrainerChange}
									  style={{width: '100%'}}
									  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									>
									  {this.state.trainerList && this.state.trainerList.map((item) => (
											<Option key={item.id} value={item.id}>{item.firstname} {item.lastname}</Option>
									  ))}
									</Select>
							</Col>
						</Row>
					</> : null}
					
					
					{Object.keys(this.state.selectedPackage).length>0  ?
					<>
					<Row className="mt-10" >
						<Col md={4} >
							<label>Amount Received</label>
						</Col>
						<Col md={8} >
							<Form.Control
								type="number" 
								name="i__amountReceived"
								size="md"
								required 
								style={{fontWeight: '600'}} 
								onKeyPress={decimalKeyPress} 
								value={this.state.amountReceived}
								onChange={this.handleAmountReceivedChange}
							/>
						</Col>
					</Row>
					</> : null}
					
					{Object.keys(this.state.selectedPackage).length>0 ? 
					<>
					<hr />
					<Row>
						<Col md={12} >
							<div className="text-center" >
								<Button 
									type="submit"
									size="md"
									variant="success" 
									disabled={this.state.btnText!='Upgrade Now'}
								>
								{this.state.btnText}
								</Button>
							</div>
						</Col>
					</Row>
					</> : null}
					
				</form>									
				
				</Spin>
			</React.Fragment>
		);
	}
	
}
export default UpgradePlan;
