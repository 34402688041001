import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import $ from 'jquery';
import { Row,Col, Card, Form, Button, ButtonGroup, ToggleButton} from 'react-bootstrap';
import {Helmet} from "react-helmet";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, todayDate
} from '../../../utils';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch, Tabs
} from 'antd';

import { Api } from '../../../utils/Variables';

import PsContext from '../../context/PsContext';
import ReportMenu from './reportMenu';

const { TabPane } = Tabs;

const styles = {
	table: {
		borderCollapse: 'collapse',
		border: '1px solid black',
		fontSize: '14px',
	},
	thead: {
		borderCollapse: 'collapse',
		border: '1px solid black',
		fontSize: '14px',
		fontWeight: '600',
	}
}

class MemberDueList extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
			showLoader: false,
			dataList: [],
			dataView: [],
			allDueList: [],
			btnValue: 'ALL',
			btnText: 'Search',
		}
		
		this.handlePrintClick = this.handlePrintClick.bind(this);
		this.handleAllPrintClick = this.handleAllPrintClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadDueData();
		
	}
	
	loadDueData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/members/allduelist?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						allDueList: res['data'].data,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
					message.error(res['data'].message || 'Error');
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/members/all?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
					message.error(res['data'].message || 'Error');
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	printDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.atClose)
			 this.props.atClose();
	}
	
	handlePrintClick(){
		this.printDocument("emp_list");
	}
	
	handleAllPrintClick(){
		this.printDocument("all_list");
	}
	
	handleStatusRadioChange(value,e){
		try{
			var d = this.state.dataList;
			var s = d;
			if(value=='ACTIVE'){
				s = d.filter(function(obj){
					return(obj.member_device_status=='1');
				});
			}
			else if(value=='INACTIVE'){
				s = d.filter(function(obj){
					return(obj.member_device_status=='0');
				});
			}
			else if(value=='EXPIRED'){
				s = d.filter(function(obj){
					return(
						(new Date(obj.current_plan_expires_on).getTime())<=(new Date(todayDate()).getTime())
					)
				});
			}
			else if(value=='FPNOT'){
				s = d.filter(function(obj){
					return(obj.finger_print_count=='0');
				});
			}
			
			this.setState({
				btnValue: value,
				dataView: s
			});
		}
		catch(error){
			
		}
	}
	
	exportTableToExcel(tableID, filename = ''){
		try{
			
			this.setState({
				showLoader: true
			});
				
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
			
			// Specify file name
			filename = filename?filename+'.xls':'excel_data.xls';
			
			// Create download link element
			downloadLink = document.createElement("a");
			
			document.body.appendChild(downloadLink);
			
			if(navigator.msSaveOrOpenBlob){
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob( blob, filename);
				
				this.setState({ showLoader: false });
			}else{
				// Create a link to the file
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
			
				// Setting the file name
				downloadLink.download = filename;
				
				//triggering the function
				downloadLink.click();
				
				this.setState({ showLoader: false });
			}	
			
		}
		catch(error){
			this.setState({ showLoader: false });
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/members/duelist', $("#frmdueInput").serialize()).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
					message.error(res['data'].message || 'Error');
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){

		var sno=1;
		
		return(
			<React.Fragment>
				<Card>
					<Card.Body>
						<Tabs defaultActiveKey="1">
							<TabPane tab="All Due List" key="1">
								<Row>
									<Col md={12} >
										<div className="text-right" >
										{this.state.allDueList && Object.keys(this.state.allDueList).length>0 ?
										<ButtonGroup>
												<Button
													type="button"
													size="sm"
													variant="outline-secondary"
													onClick={this.handleAllPrintClick}
												>
													<i className="icofont-printer" ></i> Print
												</Button>
												<Button
													type="button"
													size="sm"
													variant="outline-secondary"
													onClick={this.exportTableToExcel.bind(this, 'all_list_table', 'Reports')}
												>
													<i className="icofont-file-excel" ></i> Export to Excel
												</Button>
												{/*<ReactHTMLTableToExcel
												 id="test-table-xls-button"
												className="btn btn-outline-secondary btn-sm"
												table="emp_list_table"
												filename="Employee_List"
												sheet="all"
												buttonText={<span><i className="icofont-file-excel" ></i> Export to Excel</span>}/>*/}
											</ButtonGroup>
										: null}
										</div>
									</Col>
									<Col md={12} >
										<div id="all_list" style={{
												overflowX: 'scroll',
										}} >
											<table width="100%" style={styles.table} id="all_list_table">
												<thead>
												<tr style={styles.thead} >
														<th>S.No</th>
														<th>Code</th>
														<th>Name</th>
														<th>Gender</th>
														<th>DOB</th>
														<th>Mobile</th>
														<th>Package</th>
														<th>Due Amount</th>
													</tr>
												</thead>
												<tbody style={styles.table} >
												{this.state.allDueList.map((item,i)=>{
													return <tr>
														<td>{i+1}</td>
														<td>{item.memberid}</td>
														<td>{item.firstname} {item.lastname}</td>
														<td>{upperCase(item.gender)}</td>
														<td>{momentDate(item.dob, 'DD-MM-YYYY')}</td>
														<td>{item.mobile}</td>
														<td>{item.packagename}</td>
														<td>{item.balance_to_paid}</td>
													</tr>
												})}
												</tbody>
											</table>
										</div>
									</Col>
								</Row>
							</TabPane>
							<TabPane tab="Date Wise Due List" key="2">
								<Row>
									<Col md={12} >
										
										<Spin 
											size="large"
											tip="Please wait..."
											spinning={this.state.showLoader} >
											
											<Row>
												<Col md={9} >
													<form action="" method="post" encType="multipart/form-data" id="frmdueInput" onSubmit={this.handleFormSubmit} >
														<input type="hidden" name="api" value={this.context.state.api} />
														<Row>
															<Col md={4} >
																<label>From Date</label>
																<Form.Control 
																	type="date"
																	size="sm"
																	name="i__from"
																	max={todayDate()}
																/>
															</Col>
															<Col md={4} >
																<label>To Date</label>
																<Form.Control 
																	type="date"
																	size="sm"
																	name="i__to"
																	max={todayDate()}
																/>
															</Col>
															<Col md={4} >
																<label></label>
																<Button
																	type="submit"
																	variant="warning"
																	size="sm"
																	className="mt-30"
																>
																	{this.state.btnText}
																</Button>
															</Col>
														</Row>
													</form>
												</Col>
												<Col md={3} >
													<div className="text-right" >
													{this.state.dataList && Object.keys(this.state.dataList).length>0 ?
													<ButtonGroup>
															<Button
																type="button"
																size="sm"
																variant="outline-secondary"
																onClick={this.handlePrintClick}
															>
																<i className="icofont-printer" ></i> Print
															</Button>
															<Button
																type="button"
																size="sm"
																variant="outline-secondary"
																onClick={this.exportTableToExcel.bind(this, 'emp_list_table', 'Reports')}
															>
																<i className="icofont-file-excel" ></i> Export to Excel
															</Button>
															{/*<ReactHTMLTableToExcel
															 id="test-table-xls-button"
															className="btn btn-outline-secondary btn-sm"
															table="emp_list_table"
															filename="Employee_List"
															sheet="all"
															buttonText={<span><i className="icofont-file-excel" ></i> Export to Excel</span>}/>*/}
														</ButtonGroup>
													: null}
													</div>
												</Col>
														
											</Row>
											
											{this.state.dataList && Object.keys(this.state.dataList).length>0 ?
											<Card style={{minHeight: 'calc(100vh - 120px)'}} className="mt-20" >
												<Card.Body>
													<Row>
													<Col md={12} >
													
													<div id="emp_list" style={{
															overflowX: 'scroll',
													}} >
														<table width="100%" id="emp_list_table"  >
															<tbody  >
																<tr>
																	<td align="center" >
																		<br />
																		<b style={{
																			fontSize: '18px',
																			textTransform: 'uppercase',
																		}}>{this.context.state.loggedUser.name}</b>
																		<br />Members Report
																		<br />
																		<br />
																	</td>
																</tr>
																<tr>
																	<td>
																		<table width="100%" style={styles.table} >
																			<thead>
																			<tr style={styles.thead} >
																				<th height="30" align="center" >S.No</th>
																				<th>Code</th>
																				<th>Name</th>
																				<th>Gender</th>
																				<th width="200" >DOB</th>
																				<th align="" >Mobile</th>
																				<th align="" >Address</th>
																				<th align="" >Joined</th>
																				<th align="" >Expectation</th>
																				<th align="" >Package</th>
																				<th align="" >Balance To Paid</th>
																				<th align="" >Next Installment</th>
																				<th align="" >Expire On</th>
																				<th align="" >Machine Status</th>
																			</tr>
																		</thead>
																		<tbody style={styles.table} >														
																		{this.state.dataView.map((item,i)=>{
																			return <tr key={i} style={styles.table} >
																				<td>{sno++}</td>
																				<td>{item.memberid}</td>
																				<td>{item.firstname} {item.lastname}</td>
																				<td >{upperCase(item.gender)}</td>
																				<td>{momentDate(item.dob, 'DD-MM-YYYY')}</td>
																				<td>{item.mobile}</td>
																				<td>{item.address}</td>																
																				<td>{momentDate(item.doa, 'DD-MM-YYYY')}</td>
																				<td>{item.expectation}</td>
																				<td>{item.packagename}</td>
																				<td align="right" >{item.balance_to_paid}</td>
																				<td>{momentDate(item.next_installment_on, 'DD-MM-YYYY')}</td>
																				<td>{momentDate(item.current_plan_expires_on, 'DD-MM-YYYY')}</td>
																				<td>{item.member_device_status=='1' ? 'Active' : 'In Active'}</td>
																			</tr>;
																		})												
																		}
																		</tbody>
																	</table>	
																</td>
															</tr>
															</tbody>
														</table>	
														</div>
													
														
														</Col>
														</Row>
														
												</Card.Body>
											</Card>
											: null}
										</Spin>
										
									</Col>
								
								</Row>
							</TabPane>
						</Tabs>
											 
					</Card.Body>
				</Card>
			</React.Fragment>
		);
	}
	
}
export default MemberDueList;
