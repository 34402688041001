import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Modal,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';

import { Api } from '../../../utils/Variables';
import { getDateByFormat } from '../../../utils/appUtils';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress,
} from '../../../utils';
import stateDistricts from '../../../utils/stateDistricts.json';
import ReactFileReader from 'react-file-reader';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import UPLOAD_ARROW from '../../../assets/img/upload-arrow.png';

const { Option } = Select;

class AddTrainer extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			errorModal: false,
			stateValue: '',
			districtValue: '',
			districtList: [],
			errors: [],
			profilePhoto: '',
			identityProof: '',
			submitBtn: 'Save Staff',
			selectedRole: '',
		}
		
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleDistrictChange = this.handleDistrictChange.bind(this);
		this.handleProfilePhotoChange = this.handleProfilePhotoChange.bind(this);
		this.handleProfilePhotoRemoveClick = this.handleProfilePhotoRemoveClick.bind(this);
		this.handleIdentityProofChange = this.handleIdentityProofChange.bind(this);
		this.handleIdentityProofRemoveClick = this.handleIdentityProofRemoveClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleRoleChange = this.handleRoleChange.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	handleStateChange(value) {
		const index = stateDistricts.states.findIndex((item) => item.id === value);
		var districts = stateDistricts.states[index].districts;
		this.setState({districtList: districts, stateValue: value});
	}
	
	handleDistrictChange(value){
		this.setState({districtValue: value});
	}
	
	handleProfilePhotoChange(files) {
		this.setState({profilePhoto: files.base64});
	}
	
	handleProfilePhotoRemoveClick() {
		this.setState({profilePhoto: ''});
	}
	
	handleIdentityProofChange(files) {
		this.setState({identityProof: files.base64});
	}
	
	handleIdentityProofRemoveClick() {
		this.setState({identityProof: ''});
	}
	
	handleRoleChange(e){
		this.setState({selectedRole: e});
	}
	
	handleFormSubmit(e) {
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, submitBtn: '....'});			
			axios.post(Api.Url+'v1/trainer/save', $("#frm_addTrainer_DForm").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frm_addTrainer_DForm").reset();
					message.success(res['data'].message || 'Success');
					this.setState({
						showLoader: false, 
						submitBtn: 'Save Staff',
						stateValue: '',
						districtValue: '',
						profilePhoto: '',
						identityProof: '',
					});
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					//message.error(res['data'].message || 'Error');
					this.setState({showLoader: false, errorModal : true,errors: res['data'].errors, submitBtn: 'Save Staff'});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false, submitBtn: 'Save Staff'});
		}
	}
		
	
	render(){

		const date = new Date();
		const ageLimit = date.setFullYear(date.getFullYear() - 10);
		
		var roles = [
			{key: 'TRAINER', value: 'TRAINER'},
			{key: 'STAFF', value: 'STAFF'},
			{key: 'MANAGER', value: 'MANAGER'},
		];
  
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<form action="" method="post" encType="multipart/form-data" id="frm_addTrainer_DForm" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="i__stateId" value={this.state.stateValue} />
					<input type="hidden" name="i__districtId" value={this.state.districtValue} />
					<input type="hidden" name="i__profilePhoto" value={this.state.profilePhoto} />
					<input type="hidden" name="i__identityProof" value={this.state.identityProof} />
					<input type="hidden" name="i__role" value={this.state.selectedRole} />
					<input type="hidden" name="i__prefix" value={getls('loginUser', 'prefix')} />
					
					<Row>
						<Col md={9} >
						
							<Row>
								<Col md={6} >
									<label>
										Role
										<span className="text-danger">*</span>
									</label>
									 <Select
									  showSearch
									  style={{ width: 180 }}
									  placeholder="Select a Role"
									  optionFilterProp="children"
									  onChange={this.handleRoleChange}
									  style={{width: '100%'}}
									  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									>
									  {roles.map((item) => (
										<Option key={item.key} value={item.key}>{item.value}</Option>
									  ))}
									</Select>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={6} >
									<label>
										First Name 
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__firstName" 
										type="text" 
										size="sm"
										placeholder="First Name" 
										onKeyPress={lettersOnly}
										required
									/>
								</Col>
								
								<Col md={6} >
									<label>
										Last Name 
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__lastName" 
										type="text" 
										size="sm"
										placeholder="Last Name" 
										onKeyPress={lettersOnly}
									/>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={6} >
									<label>
										Mobile
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__mobile" 
										type="number" 
										size="sm"
										placeholder="Mobile Number" 
										onKeyPress={integerIndMobile}
										required
									/>
								</Col>
								
								<Col md={6} >
									<label>
										Email Id
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__email" 
										type="email" 
										size="sm"
										placeholder="Email Id" 
									/>
								</Col>
							</Row>
							
							<Row  className="mt-10" >
								<Col md={6} >
									<label>
										Address
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__address"
										as="textarea" 
										size="sm"
										rows="4"
										placeholder="Address" 
										required
									/>
								</Col>
								
								<Col md={6} >
									<label>
										State
										<span className="text-danger">*</span>
									</label>
									 <Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select a State"
										  optionFilterProp="children"
										  onChange={this.handleStateChange}
										  style={{width: '100%'}}
										  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
										  {stateDistricts && stateDistricts.states.map((state) => (
											<Option key={state.id} value={state.id}>{state.name}</Option>
										  ))}
										</Select>
										
										
									<label>
										District
										<span className="text-danger">*</span>
									</label>
									 <Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select a District"
										  optionFilterProp="children"
										  onChange={this.handleDistrictChange}
										  style={{width: '100%'}}
										  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
										>
										  {this.state.districtList && this.state.districtList.map((item) => (
												<Option key={item.id} value={item.id}>{item.name}</Option>
										  ))}
										</Select>
										
								</Col>
							</Row>
							
							
							<Row  className="mt-10" >
								<Col md={6} >
									<label>
										Date of Birth
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__dateOfBirth"
										type="date" 
										size="sm"
										placeholder="Date of Birth" 
										max={getDateByFormat(ageLimit, 'YYYY-MM-DD')}
										required
									/>
								</Col>
								
								<Col md={6} >
									<label>
										Date of Joining
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__dateOfJoining"
										type="date" 
										size="sm"
										placeholder="Date of Birth" 
										max={getDateByFormat((new Date()), 'YYYY-MM-DD')}
										required
									/>
								</Col>
							</Row>
							
							<Row  className="mt-10" >
								<Col md={6} >
									<label>
										Gender
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__gender"
										as="select"
										size="sm"
										placeholder="" 
										required
									>
										<option value="male"> Male </option>
										<option value="female" > Female </option>
									</Form.Control>
								</Col>
								
								<Col md={6} >
									<label>
										Educational Qualification
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__qualification"
										type="text" 
										size="sm"
										placeholder="Educational Qualification" 
										required
									/>
								</Col>
							</Row>
							
							<Row  className="mt-10" >
								<Col md={6} >
									<label>
										Years of Experience
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__experience"
										type="number" 
										size="sm"
										placeholder="Years of Experience" 
										required
									/>
								</Col>
								
								<Col md={6} >
									<label>
										Specialized In
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__specialized"
										type="text" 
										size="sm"
										placeholder="Specialized In" 
									/>
								</Col>
							</Row>
							
							<Row  className="mt-10" >
								<Col md={6} >
									<label>
										Aadhar No
										<span className="text-danger"></span>
									</label>
									<Form.Control 
										name="i__aadhar"
										type="text" 
										size="sm"
										placeholder="Aadhar No" 
										onKeyPress={integerAadhar}
									/>
								</Col>
								
								<Col md={6} >
									<label>
										Refer By
										<span className="text-danger">*</span>
									</label>
									<Form.Control 
										name="i__referBy"
										type="text" 
										size="sm"
										placeholder="required" 
									/>
								</Col>
							</Row>
														
						</Col>
						
						<Col md={3} >
							<Row>
								<Col md={12} >
									<label>Profile Photo</label>
									<ReactFileReader  base64 handleFiles={this.handleProfilePhotoChange} >
									<img 
										src={this.state.profilePhoto || UPLOAD_ARROW} 
										className="img-upload" 
										tooltip="Click to Upload new Image"
									/>
									</ReactFileReader>
									{this.state.profilePhoto ? <>
										<Button 
											size="xs" 
											variant="danger" 
											onClick={this.handleProfilePhotoRemoveClick} >
											Remove
										</Button>
									</> : null}
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={12} >
									<label>Idenity Proof</label>
									<ReactFileReader  base64 handleFiles={this.handleIdentityProofChange} >
									<img 
										src={this.state.identityProof || UPLOAD_ARROW} 
										className="img-upload" 
										tooltip="Click to Upload new Image"
									/>
									</ReactFileReader>
									{this.state.identityProof ? <>
										<Button 
											size="xs" 
											variant="danger" 
											onClick={this.handleIdentityProofRemoveClick} >
											Remove
										</Button>
									</> : null}
								</Col>
							</Row>
						</Col>
					</Row>
					
					<Row>
						<Col md={12} >
							<hr />
							<div className="text-center" >
								<Button type="submit" size="sm" variant="success" disabled={this.state.submitBtn!='Save Staff'} >
									{this.state.submitBtn}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>	
				</Spin>	
				<Modal
					size="sm"
					backdrop="static"
					show={this.state.errorModal}
					onHide={()=>{this.setState({errorModal : false, errors : []})}} 
					
				>
					<Modal.Header 
						closeButton 
					>
						Error
					</Modal.Header>
					<Modal.Body>
						<ul>
						{this.state.errors.map((item,i)=>{
							return <li>{item}</li>
						})}
						</ul>
					</Modal.Body>
				</Modal>				
			</React.Fragment>
		);
	}
	
}
export default AddTrainer;
