import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, Select,message, TimePicker, 
} from 'antd';
import moment from 'moment';
import { Api } from '../../../utils/Variables';
import { getDateByFormat } from '../../../utils/appUtils';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress,
} from '../../../utils';
import stateDistricts from '../../../utils/stateDistricts.json';
import ReactFileReader from 'react-file-reader';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import UPLOAD_ARROW from '../../../assets/img/upload-arrow.png';

const { Option } = Select;

class EditTiming extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			stateValue: '',
			editData: this.props.editData,
			submitBtn: 'Update Timing',
			startTime: this.props.editData.starttime,
			endTime: this.props.editData.endtime,
		}
		
		this.onStartTimeChange = this.onStartTimeChange.bind(this);
		this.onEndTimeChange = this.onEndTimeChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount() {
		
		this.setState({
			profilePhoto: this.props.editData.profilephoto,
			//starttime: this.props.editData.starttime,
			//endtime: this.props.editData.endtime,
		});
	}
	
	onStartTimeChange(time, timeString){
		try{
			this.setState({
				startTime: timeString
			});
		}
		catch(error){
			
		}
	}
	
	onEndTimeChange(time, timeString){
		try{
			this.setState({
				endTime: timeString
			});
		}
		catch(error){
			
		}
	}
	
	
	handleFormSubmit(e) {
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, submitBtn: '....'});			
			axios.post(Api.Url+'v1/timing/update', $("#frm_editTiming_DForm").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					this.setState({
						showLoader: false, 
						submitBtn: 'Update Timing',
					});
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false, submitBtn: 'Update Timing'});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false, submitBtn: 'Update Timing'});
		}
	}
	
	getFieldValue(fieldName){
		try{
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	render(){

		const date = new Date();
		const ageLimit = date.setFullYear(date.getFullYear() - 10);
  
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<form action="" method="post" encType="multipart/form-data" id="frm_editTiming_DForm" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="i__id" value={this.getFieldValue('id')} />
					<input type="hidden" name="i__startTime" value={this.state.startTime} />
					<input type="hidden" name="i__endTime" value={this.state.endTime} />
					
					
					<Row>
						<Col md={12} >
							<label>
								Start Time 
								<span className="text-danger">*</span>
							</label>
							<TimePicker 
								use12Hours 
								format="h:mm A" 
								defaultValue={moment(this.state.startTime, 'HH:mm:ss')}
								onChange={this.onStartTimeChange} 
								style={{ width: '100%' }} />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<label>
								End Time 
								<span className="text-danger">*</span>
							</label>
							<TimePicker 
								use12Hours 
								format="h:mm A" 
								defaultValue={moment(this.getFieldValue('endtime'), 'HH:mm:ss')}
								onChange={this.onEndTimeChange} 
								style={{ width: '100%' }} />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<label>
								Preferable
								<span className="text-danger"></span>
							</label>
							<Form.Control
								as="select"
								name="i__preferable"
								size="sm" 
							>
								<option 
									value="Unisex Time" 
									selected={this.getFieldValue('preferable')=='Unisex Time' ? 'selected' : ''} >
										Unisex Time
								</option>
								<option 
									value="Pink Time"
									selected={this.getFieldValue('preferable')=='Pink Time' ? 'selected' : ''}  >
										Pink Time
								</option>
								<option 
									value="Men Time"
									selected={this.getFieldValue('preferable')=='Men Time' ? 'selected' : ''}  >
										Men Time
								</option>
							</Form.Control>
						</Col>
					</Row>
					
					<Row>
						<Col md={12} >
							<hr />
							<div className="text-center" >
								<Button type="submit" size="sm" variant="success" disabled={this.state.submitBtn!='Update Timing'} >
									{this.state.submitBtn}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>	
				</Spin>				
			</React.Fragment>
		);
	}
	
}
export default EditTiming;
