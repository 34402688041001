import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , Table, ButtonGroup,Tab,Tabs
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import { Api } from '../../../utils/Variables';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import {
  getls, upperCase, momentDate, todayDate, groupByMultiple
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

const { Option } = Select;
const styles = {
	table: {
		borderCollapse: 'collapse',
		border: '1px solid black',
		fontSize: '14px',
	},
	thead: {
		borderCollapse: 'collapse',
		border: '1px solid black',
		fontSize: '14px',
		fontWeight: '600',
	}
}

class MembersAttendance extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddDrawer: false,
			showEditDrawer: false,
			showLogModal: false,
			editData: this.props.editData,
			dataList: [],
			dataView: [],
			absentList : [],
			absentView : [],
			selectedRowGroup: 'ALL',
			selectedMonth: '',
			selectedMonthValue: '',
			selectedYearValue: '',
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleMonthChange = this.handleMonthChange.bind(this);
		this.handlePrintClick = this.handlePrintClick.bind(this);
		this.handleAbsentPrintClick = this.handleAbsentPrintClick.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);		
		this.handleAbsentSearchOnChange = this.handleAbsentSearchOnChange.bind(this);		
	}
	
	componentDidMount(){
		
	}
	
	handleMonthChange(e){
		var m = new Date(e.target.value).getMonth();
		var y = new Date(e.target.value).getFullYear();
		
		this.setState({
			selectedMonth: e.target.value,
			selectedMonthValue: m+1,
			selectedYearValue: y,
		});
	}
	
	loadData(){
		try{
			
			this.setState({
				showLoader: true, 
				dataList: [], 
				dataView: [],
				absentList : [],
				absentView : []
			});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__date', this.state.selectedMonth);
			
			axios.post(Api.Url+'v1/iclock/members/attnlogs', form).then(res=>{
				if(res['data'].status=='1'){
					var d = res['data'].data;
					var s = groupByMultiple(d, function(obj){
						return [obj.UserId];
					});
					
					var m = this.props.editData;
					for(var i=0;i<m.length;i++){
						for(var j=0;j<d.length;j++){
							if(m[i].memberid == d[j].UserId){
								m.splice(i,1);
							}
						}
					}
					this.setState({
						dataList: res['data'].data,
						dataView: s, //res['data'].data,
						absentView : m,
						absentList : m,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
					message.error(res['data'].message || 'Error');
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	daysInMonth (month, year) {
		return new Date(year, month, 0).getDate();
	}
	
	getSundays(year, month, totalDays){
		
		var sun = new Array();
		for(var i=1;i<=totalDays;i++){    //looping through days in month
			var newDate = new Date(year,month,i)
			if(newDate.getDay()==0){   //if Sunday
				sun.push(i);
			}
		}
	}
		
	printDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.atClose)
			 this.props.atClose();
	}
	
	handlePrintClick(){
		this.printDocument("emp_atten_logs_list");
	}
		
	printAbsentDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.atClose)
			 this.props.atClose();
	}
	
	handleAbsentPrintClick(){
		this.printAbsentDocument("emp_absent_logs_list");
	}
	
	getTimeList(lists){
		var s = [];
		lists.map((item,i)=>{
			s.push(`${momentDate(item.LogDate, 'HH:mm A')} -`);
		});
		return s; //.slice(0,-1);
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return (obj.UserId.indexOf(v)>-1 ||
				obj.EmployeeName.indexOf(v)>-1);
			});
			var s = groupByMultiple(d, function(obj){
				return [obj.UserId];
			});
			this.setState({dataView:s});
		}
		catch(error){ }
	}
	
	handleAbsentSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.absentList.filter(function(obj){
				return (obj.memberid.indexOf(v)>-1 ||
				obj.firstname.indexOf(v)>-1);
			});
			
			this.setState({absentView:d});
		}
		catch(error){ }
	}
	
	render(){
				
		var sno=1;
		var asno=1;
		
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
				<div style={{minHeight: 'calc(100vh - 200px)'}}>
					
					
					<Row>
						<Col md={1} >
							<label>Date</label>
						</Col>
						<Col md={3} >
							<Form.Control
								type="date"
								size="sm"
								max={momentDate(todayDate(), 'YYYY-MM-DD')}
								onChange={this.handleMonthChange} 
							/>
						</Col>
						<Col md={1} >
							<Button
								type="button"
								size="sm"
								variant="warning"
								onClick={this.loadData} 
								
							>
								Search
							</Button>
						</Col>
					</Row>	
					<Tabs defaultActiveKey="present" id="uncontrolled-tab-example">
					  <Tab eventKey="present" title="Present">
						{this.state.dataList && Object.keys(this.state.dataList).length>0 ? 
							<Row className="mt-20" >
								<Col md={4} >
									<span className="input-icon">
										<i className="material-icons" >search</i>
										<Form.Control  
											size="sm" 
											placeholder="Search by Member Id, Name" 
											onChange={this.handleSearchOnChange} />
									  </span>
								</Col>
								<Col md={8} >
									<div className="text-right" >
										<ButtonGroup>
											<Button
												type="button"
												size="sm"
												variant="outline-secondary"
												onClick={this.handlePrintClick}
											>
												<i className="icofont-printer" ></i> Print
											</Button>
											<ReactHTMLTableToExcel
												id="test-table-xls-button"
												className="btn btn-outline-secondary btn-sm"
												table="emp_atten_logs"
												filename="Members Attendance"
												sheet="all"
												buttonText={<span><i className="icofont-file-excel" ></i> Export to Excel</span>} />
										</ButtonGroup>
									</div>
								</Col>
								<Col md={12} >
									<div id="emp_atten_logs_list" style={{
											overflowX: 'scroll',
									}} >
										<table width="100%" id="emp_atten_logs"  >
											<tbody  >
												<tr>
													<td align="center" >
														<br />
														<b style={{
															fontSize: '18px',
															textTransform: 'uppercase',
														}}>{this.context.state.loggedUser.name}</b>
														<br />Members Attendance report for the date of {momentDate(this.state.selectedMonth, 'DD MMM YYYY')}
														<br />
														<br />
													</td>
												</tr>
												<tr>
													<td>
														<table width="100%" style={styles.table} >
																<thead>
																<tr style={styles.thead} >
																	<th>S.No</th>
																	<th>Code</th>
																	<th>Name</th>
																	<th>Time</th>
																</tr>
															</thead>
															<tbody style={styles.table} >														
															{this.state.dataView.map((items,i)=>{
																var item = items[0];
																return <tr key={i} style={styles.table} >
																	<td>{sno++}</td>
																		<td>{item.UserId}</td>
																		<td>{item.EmployeeName}</td>
																			{/*<td>{momentDate(item.LogDate, 'HH:mm A')}</td>*/}
																			<td>
																			{this.getTimeList(items)}
																			</td>
																	</tr>;
															})												
															}
															</tbody>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									
								</Col>
							</Row>
						: null}
					  </Tab>
					  <Tab eventKey="absent" title="Absent">
						{this.state.absentList && Object.keys(this.state.absentList).length>0 ? 
							<Row className="mt-20" >
								<Col md={4} >
									<span className="input-icon">
										<i className="material-icons" >search</i>
										<Form.Control  
											size="sm" 
											placeholder="Search by Member Id, Name" 
											onChange={this.handleAbsentSearchOnChange} />
									  </span>
								</Col>
								<Col md={8} >
									<div className="text-right" >
										<ButtonGroup>
											<Button
												type="button"
												size="sm"
												variant="outline-secondary"
												onClick={this.handleAbsentPrintClick}
											>
												<i className="icofont-printer" ></i> Print
											</Button>
											<ReactHTMLTableToExcel
												id="test-table-xls-button"
												className="btn btn-outline-secondary btn-sm"
												table="emp_absent_logs"
												filename="Members Absent List"
												sheet="all"
												buttonText={<span><i className="icofont-file-excel" ></i> Export to Excel</span>} />
										</ButtonGroup>
									</div>
								</Col>
								<Col md={12}>
									<div id="emp_absent_logs_list" style={{overflowX: 'scroll'}} >
										<table width="100%" id="emp_absent_logs"  >
											<tbody  >
												<tr>
													<td align="center" >
														<br />
														<b style={{
															fontSize: '18px',
															textTransform: 'uppercase',
														}}>{this.context.state.loggedUser.name}</b>
														<br />Members Absent report for the date of {momentDate(this.state.selectedMonth, 'DD MMM YYYY')}
														<br />
														<br />
													</td>
												</tr>
												<tr>
													<td>
														<table width="100%" style={styles.table} >
															<thead>
																<tr style={styles.thead} >
																	<th>S.No</th>
																	<th>Code</th>
																	<th>Name</th>
																</tr>
															</thead>
															<tbody style={styles.table} >														
															{this.state.absentView.map((item,i)=>{
																
																return <tr key={i} style={styles.table} >
																	<td>{asno++}</td>
																	<td>{item.memberid}</td>
																	<td>{item.firstname} {item.lastname}</td>
																</tr>;
															})}
															</tbody>
														</table>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Col>
							</Row>
						: null}
					  </Tab>
					</Tabs>

				</div>
				</Spin>
			</React.Fragment>
		);
	}
	
}
export default MembersAttendance;
