 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, ButtonGroup, Modal, ListGroup} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';


import PsContext from '../../context/PsContext';

const { Option } = Select;

class AddDevice extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Save',
			showLoader: false,
			showAddModal: false,
			dataList: [],
			dataView: [],
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			
			axios.post(Api.Url+'v1/device/new', $("#frm_add_Mod_Devices").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frm_add_Mod_Devices").reset();
					message.success(res['data'].message || 'Success');
					this.setState({showLoader: false});
				}
				else{
					this.setState({showLoader: false});
					message.error(res['data'].message || 'Error');
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){

		
		
		return(
			<div>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<form 
					action=""
					method="post"
					encType="multipart/form-data" 
					id="frm_add_Mod_Devices"
					onSubmit={this.handleFormSubmit} 
				>
					<input type="hidden" name="api" value={this.context.state.api} />
					
					<Row>
						<Col md={4} >
							<label className="font-13" >
								Serial No
								<span className="text-danger">*</span>
							</label>
						</Col>
						<Col md={8} >
							<Form.Control
								type="text"
								style={{textTransform: 'uppercase'}}
								size="sm"
								required
								name="i__serialNo" 
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={4} >
							<label className="font-13" >
								Device Name
								<span className="text-danger">*</span>
							</label>
						</Col>
						<Col md={8} >
							<Form.Control
								type="text"
								style={{textTransform: 'uppercase'}}
								size="sm"
								required
								name="i__deviceName" 
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={4} >
							<label className="font-13" >
								Device Location
								<span className="text-danger">*</span>
							</label>
						</Col>
						<Col md={8} >
							<Form.Control
								type="text"
								size="sm"
								required
								name="i__deviceLocation" 
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={4} >
							<label className="font-13" >
								Device IP
								<span className="text-danger">*</span>
							</label>
						</Col>
						<Col md={8} >
							<Form.Control
								type="text"
								size="sm"
								required
								name="i__deviceIp" 
							/>
						</Col>
					</Row>
					
					<hr />
					
					<Row>
						<Col md={12} >
							<div className="text-right" >
								<Button 
									type="submit"
									variant="success"
									size="md"
								>
								{this.state.btnText}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>
				
				</Spin>
			</div>
		);
	}
	
}
export default AddDevice;
