 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup ,
} from 'react-bootstrap';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../../utils';
import iziToast from 'izitoast';
import ReactFileReader from 'react-file-reader';
import PsContext from '../../../context/PsContext';
import UPLOAD_ARROW from '../../../../assets/img/upload-arrow.png';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'

const { Option } = Select;

class EditCategory extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Update Category',
			showLoader: false,
			editData: this.props.editData,
			selectedImage : '',
			image : '',
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleImageChange = this.handleImageChange.bind(this);
		this.handleImageRemoveClick = this.handleImageRemoveClick.bind(this);
	}
	
	handleImageChange(files) {
		this.setState({
			image: files.base64,
			selectedImage: files.base64
		});
	}
	
	handleImageRemoveClick() {
		this.setState({
			image: '',
			selectedImage: ''
		});
	}
	componentDidMount(){
		
		this.setState({
			image : this.props.editData.image,
		});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/ma/category/update', $("#frmUpdCat_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						showLoader: false,
					});
					message.success(res['data'].message || 'Success');
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({
						showLoader: false
					});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getFieldValue(fieldName){
		try{
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	render(){

		return(
			<div>
				<Spin spinning={this.state.showLoader} >
					<form 
						action=""
						method="post"
						encType="multipart/form-data" 
						id="frmUpdCat_Mod"
						onSubmit={this.handleFormSubmit}
					>	
						<input type="hidden" name="api" value={this.context.state.api} />
						<input type="hidden" name="i__id" value={this.getFieldValue('id')} />
						<input type="hidden" name="i__content" value={this.state.htmlContent} />
						<input type="hidden" name="i__image" value={this.state.selectedImage} />
						
						<Row>
							<Col md={12}>
								<label className="font-14" >Category Name
									<span className="text-danger">*</span>
								</label>
								<Form.Control
									type="text"
									size="sm"
									name="i__category"
									rows="3"
									defaultValue={this.getFieldValue('category')}
									required
								/>
							</Col>
						</Row>
								
								
						<Row className="mt-15" >
							<Col md={12}>
								<label className="font-14" >Image
									<span className="text-danger">*</span>
								</label>
								<ReactFileReader  base64 handleFiles={this.handleImageChange} >
									<img 
										src={this.state.selectedImage  ? 
										this.state.selectedImage : 
										this.state.image ? 
										Api.Url + this.state.image 
										: UPLOAD_ARROW
										} 
										className="img-upload" 
										tooltip="Click to Upload new Image"
										style={{width : "250px"}}
									/>
									</ReactFileReader>
									{this.state.image ? <>
										<Button 
											size="xs" 
											variant="danger" 
											onClick={this.handleImageRemoveClick} >
											Remove
										</Button>
									</> : null}
							</Col>
						</Row>
								
						<Row className="mt-15" >
							<Col md={12} >
								<div className="text-right" >
									<Button
										type="submit"
										variant="success"
									>
										Update Changes
									</Button>
								</div>
							</Col>
						</Row>
						
					</form>
				</Spin>
			</div>
		);
	}
	
}
export default EditCategory;
