import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Badge, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import axios from 'axios';
import { Api } from '../../../utils/Variables';
import {
	Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch
  } from 'antd';
import PsContext from '../../context/PsContext';

class Offers extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
			dataList: [],
			dataView: [],
			product: [],
			enquiryList: [],
			showModal: false,
		}
		this.handleModalClose = this.handleModalClose.bind(this);
	}
	
	handleModalOpen(data){
		this.setState({product : data,showModal: true});
	}
	
	handleModalClose(){
		this.setState({showModal: false});
	}
	
	componentDidMount(){
		
		this.loadData();
		this.loadEnquiry();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/ma/products?api='+this.context.state.api).then(res=>{
					if(res['data'].status=='1'){
						this.setState({
							showLoader: false,
							dataList: res['data'].data,
							dataView: res['data'].data,
						});
					}
					else{
						this.setState({showLoader: false});
					}
					
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	loadEnquiry(){

		try{
			this.setState({showLoader: true});
			
			axios.get(Api.Url+'v1/product/enquires?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						enquiryList : res['data'].data,
						showLoader: false,
					});
					
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			
			this.setState({showLoader: false});
		}
	}
	
	checkenquiry(productId){

		var d = this.state.enquiryList;
		var bstate = true;
		var index = d.findIndex(function(obj){
			return(obj.product == productId);
		});

		if(index > -1){
			bstate = false;
		}
		return bstate;
	}
	
	render(){
		
		const columns = [
			{
				title: '#',
				dataIndex: 'id',
				key: 'id',
				render: (text, record, index)=>(
					<>
					{index+1}
					</>
				)
			},
			{
				title: 'Product',
				dataIndex: 'name',
				render: (text, record)=>(
					<>
					{record.image ? <Avatar src={<Image src={record.image} />} />  :
					<Avatar icon={<i className="icofont-ui-image" />} />}
						{record.status=='0' ? 
						<Badge variant="danger" >
							<i className="icofont-close-line-circled" ></i>
						</Badge> : null}
					</>
				)
			},
			{
				title: 'Name',
				dataIndex: 'name',
				key: 'name'
			},
			{
				title: 'Amount',
				dataIndex: 'amount',
				key: 'amount',
			},			
			{
				title: 'Offer (%)',
				dataIndex: 'offer_percentage',
				key: 'offer_percentage',
			},			
			{
				title: 'Manage',
				dataIndex: 'id',
				render: (text, record)=>(
					<center>
						<Tooltip title="Enquiry" >
							<Button type="button" size="xs" variant="warning" onClick={this.handleModalOpen.bind(this,record)} >
								<i className="icofont-question" />
							</Button>
						</Tooltip>
					</center>
				)
			}
			
		];
		
		var p = this.state.product;
			
		return(
			<React.Fragment>
			
				<Table 
					size="small"
					columns={columns} 
					bordered
					loading={this.state.showLoader}
					dataSource={this.state.dataView}
				 />
				<Modal 
					show={this.state.showModal} 
					onHide={this.handleModalClose}  
					dialogClassName=""
					backdrop="static"
					size="lg" 
					keyboard={false}
				>
					<Modal.Header closeButton >
						View Product
					</Modal.Header>
					<Modal.Body>
						<Row>
							<Col md={6}>
								<img src={p.image} width="380px" height="400px" />
							</Col>
							<Col md={6}>
								<b style={{fontSize : '22px'}}>{p.name}</b><br />
								<p>{p.description}</p>
								<b>Rs. {p.offer_percentage!='' ? p.amount-parseFloat(p.amount*p.offer_percentage/100) : parseFloat(p.amount)}</b>&emsp;{p.offer_percentage!='' ? <span style={{color : 'grey',textDecoration : 'line-through'}}>Rs.{parseFloat(p.amount)}</span>: ''}&emsp;{p.offer_percentage!='' ? <b className="text-success">{p.offer_percentage}% off</b> : ''}<br /><br />
								{this.checkenquiry(p.id) ?
								<form 
									action=""
									method="post"
									encType="multipart/form-data" 
									id="frmEnquiry_Mod"
									onSubmit={this.handleFormSubmit}
								>	
									<input type="hidden" name="api" value={this.context.state.api} />
									<input type="hidden" name="i__pid" value={p.id} />
									
									<Form.Control
										as="textarea"
										size="sm"
										name="i__content"
										rows="3"
										placeholder="Enquiry content"
										required
									/>
									<br />
									<Button
										type="submit"
										variant="success"
									>
										Enquiry Now
									</Button>
								</form> : 
								<span style={{color : 'red'}}>Enquiry already sent.</span>
								}
							</Col>
						</Row>
					</Modal.Body>
				</Modal>		 
			</React.Fragment>
		);
	}
	
}
export default Offers;
