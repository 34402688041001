import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, } from 'react-bootstrap';
import {Table} from 'antd';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import axios from 'axios';
import { Api } from '../../../utils/Variables';

import PsContext from '../../context/PsContext';

class BirthdayList extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			count: [],
			dataList: [],
			dataView: [],
		}
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/dashboard/birthdaylist?api='+this.context.state.api).then(res=>{
					if(res['data'].status=='1'){
						this.setState({
							showLoader: false,
							dataList: res['data'].data,
							dataView: res['data'].data,
						});
					}
					else{
						this.setState({showLoader: false});
					}
					
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		const columns = [
				{
					title: '#',
					dataIndex: 'id',
					key: 'id',
					render: (text, record, index)=>(
						<>
						{index+1}
						</>
					)
				},
				{
					title: 'Member Id',
					dataIndex: 'memberid',
					key: 'memberid',
				},
				{
					title: 'Member Name',
					dataIndex: 'firstname',
					key: 'firstname',
					render: (text, record)=>(
						<>
						{record.member_device_status=='0' ? <span className="text-danger" >{record.firstname} {record.lastname} </span>
						:  <span className="" >{record.firstname} {record.lastname} </span>}
						</>
					)
				},
				{
					title: 'DOB',
					dataIndex: 'dob',
					key: 'dob',
					render: (text, record)=>(
						<>
						{momentDate(record.dob, 'DD-MM-YYYY')}
						</>
					)
				},
				{
					title: 'Mobile',
					dataIndex: 'mobile',
					key: 'mobile',
				},
				{
					title: 'Plan Expires',
					dataIndex: 'current_plan_expires_on',
					key: 'current_plan_expires_on',
					render: (text, record)=>(
						<>
						{momentDate(record.current_plan_expires_on, 'DD-MM-YYYY')}
						</>
					)
				},
				
			];
			
		return(
			<React.Fragment>
			{/*<Card>
					<Card.Body>
						<h6>Today Birthday List</h6>
			<hr />*/}
						<Table 
							size="small"
							columns={columns} 
							bordered
							loading={this.state.showLoader}
							dataSource={this.state.dataView}
						 />
						 
						 {/*</Card.Body>
						 </Card>*/}
			</React.Fragment>
		);
	}
	
}
export default BirthdayList;
