 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup ,
} from 'react-bootstrap';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../../utils';
import iziToast from 'izitoast';

import PsContext from '../../../context/PsContext';
import AddCategory from './AddCategory';
import EditCategory from './EditCategory';

const { Option } = Select;

class Category extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showAddModal: false,
			showEditModal: false,
			paginationPageSize: 30,
			dataList: [],
			dataView: [],
			editData: [],
		}
		
		this.handleAddClick = this.handleAddClick.bind(this);
		this.handleAddModalCloseClick = this.handleAddModalCloseClick.bind(this);
		this.loadData = this.loadData.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
		this.handleEditModalCloseClick = this.handleEditModalCloseClick.bind(this);
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/ma/category?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	handleAddClick(){
		this.setState({
			showAddModal: true,
		});
	}
	
	handleAddModalCloseClick(){
		this.setState({
			showAddModal: false,
		});
	}
	
	handleEditClick(record){
		this.setState({
			editData: record,
			showEditModal: true,
		});
		
	}
	
	handleEditModalCloseClick(){
		this.setState({
			editData: [],
			showEditModal: false,
		});
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return((upperCase(obj.category).indexOf(v)>-1));
			});
			
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleDeleteActionClick(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: `Do you want to Delete the Record?`,
			buttons: {
				Yes: function(){ 
					$this.handleDeleteConfirm(record);
				},
				No: function() { }
			}
		});
	}
	
	handleDeleteConfirm(record){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__id', record.id);
			
			axios.post(Api.Url+'v1/ma/category/remove', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					/*var index = d.findIndex(function(obj){
						return(obj.id==record.id);
					});
					d.splice(index,1);*/
					var dat = d.filter(function(obj){
						return(obj.id != record.id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						showLoader: false
					});
					//this.loadData();
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			message.error('Unknown Error');
			this.setState({showLoader: false});
		}
	}

	render(){
		
		const columns = [
			{
				title: '#',
				dataIndex: 'id',
				key: 'id',
				render: (text, record, index)=>(
					<>
					{index+1}
					</>
				)
			},
			{
				title: 'Image',
				dataIndex: 'id',
				key: 'id',
				render: (text, record)=>(
					<>
					{record.image ? <Avatar src={<Image src={Api.Url +record.image} />} />  :
					<Avatar icon={<i className="icofont-ui-image" />} />}
						{record.status=='0' ? 
						<Badge variant="danger" >
							<i className="icofont-close-line-circled" ></i>
						</Badge> : null}
					</>
				)
			},
			{
				title: 'Category',
				dataIndex: 'category',
				key: 'category',
			},		
			{
				title: 'Manage',
				dataIndex: 'id',
				key: 'id',
				render: (text,record)=> (
					<center>
						
						<Tooltip title="Click to Edit" >
							<Button type="button" size="xs" variant="warning" onClick={this.handleEditClick.bind(this, record)} >
								<i className="icofont-edit" />
							</Button>
						</Tooltip>
						&nbsp;
						<Tooltip title="Click to Delete" >
							<Button type="button" size="xs" variant="danger" onClick={this.handleDeleteActionClick.bind(this, record)} >
								<i className="icofont-trash" />
							</Button>
						</Tooltip >
					</center>
				)
			},
		];
		

		return(
			<div>
			
				<Card>
					<Card.Body>
						<Row>
							<Col md={6} >
								<span className="input-icon">
									<i className="material-icons" >search</i>
									<Form.Control  
										size="sm" 
										placeholder="Search by Category" 
										onChange={this.handleSearchOnChange} />
								  </span>
							 </Col>
							 <Col md={3} >
								
							 </Col>
							 <Col md={3} >
								<div className="text-right" >
									<ButtonGroup >
										<Button size="sm" variant="outline-secondary" onClick={this.loadData} >
											<i className="icofont-refresh" /> Refresh
										</Button>
										<Button size="sm" variant="outline-secondary" onClick={this.handleAddClick} >
											<i className="icofont-plus" /> Add Category
										</Button>
									</ButtonGroup>
								</div>
							 </Col>
						</Row>
					</Card.Body>
				</Card>
				
				<div className="mt-10 ps-table" >
					<Table 
						size="small"
						loading={this.state.showLoader}
						columns={columns} 
						dataSource={this.state.dataView} />
				</div>
				
				<Modal show={this.state.showAddModal} onHide={this.handleAddModalCloseClick} size="50w" >
					<Modal.Header closeButton >
						Add Category
					</Modal.Header>
					<Modal.Body>
						<AddCategory afterFinish={this.loadData} />
					</Modal.Body>
				</Modal>
				
				<Modal show={this.state.showEditModal} onHide={this.handleEditModalCloseClick} size="50w" >
					<Modal.Header closeButton >
						Update Category
					</Modal.Header>
					<Modal.Body>
						<EditCategory editData={this.state.editData} afterFinish={this.loadData} />
					</Modal.Body>
				</Modal>
							
			</div>
		);
	}
	
}
export default Category;
