import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Row,Col, Card, Form, Button, Tabs, Tab, Modal } from 'react-bootstrap';
import {Helmet} from "react-helmet";
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase
} from '../../../utils';
import axios from 'axios';
import { Api } from '../../../utils/Variables';
import {
	Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch
  } from 'antd';
import $ from 'jquery';
import PsContext from '../../context/PsContext';

class Communication extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader : false,
			type : '',
			stype: '',
			mebmercount : 0,
			planList : [],
			memberList : [],
			planId : '',
		}
		this.handleTypeChange = this.handleTypeChange.bind(this);
		this.handleRadioChange = this.handleRadioChange.bind(this);
		this.handlePlanChange = this.handlePlanChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/communication/sendsms', $("#frmGroupSms_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmGroupSms_Mod").reset();
					this.setState({
						showLoader: false,
					});
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({
						showLoader: false
					});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}

	handleRadioChange(e){
		try{
			if(e.target.value == 'plan'){
				this.loadPlan();
			}
			else{
				
				this.loadData(e.target.value);
			}
			this.setState({planId : '',stype : e.target.value});
		}
		catch(error){

		}
	}

	handleTypeChange(e){
		try{

			var val = e.target.value;
			this.setState({stype:'',planId:'',type : val});
		}
		catch(error){

		}
	}

	handlePlanChange(e){
		var val = e.target.value;
		this.setState({planId : val});
		this.loadMember(val);
	}

	loadMember(planId){
		try{
			this.setState({showLoader: true});
			
			axios.get(Api.Url+'v1/members/listmemberbyplan?api='+this.context.state.api+'&planId='+planId).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						memberList : res['data'].data,
						showLoader: false,
					});
					
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			
			this.setState({showLoader: false});
		}
	}

	loadData(val){
		try{
			this.setState({showLoader: true});
			var type = '';
			if(val == 'allmember'){
				type = 'all';
			}else if(val == 'activemember'){
				type = 'active';
			}else if(val == 'inactivemember'){
				type = 'inactive';
			}
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('type', type);
			axios.post(Api.Url+'v1/members/count',form).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						mebmercount : res['data'].data[0]['mcount'],
						showLoader: false,
					});
					
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			
			this.setState({showLoader: false});
		}
	}

	loadPlan(){
		try{
			this.setState({showLoader: true});
			
			axios.get(Api.Url+'v1/members/planlist?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						planList : res['data'].data,
						showLoader: false,
					});
					
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			
			this.setState({showLoader: false});
		}
	}

    render(){

		const types = [
			{key: '', value: 'Select Type'},
			{key: 'member', value: 'Member'},
			{key: 'staff', value: 'Staff'},
		];

        return(
			<React.Fragment>
				<Spin spinning={this.state.showLoader} >
					<Row>
						<Col md={3}>

						</Col>
						<Col md={6}>
							<Card>
								<Card.Body>
								<form 
									action=""
									method="post"
									encType="multipart/form-data" 
									id="frmGroupSms_Mod"
									onSubmit={this.handleFormSubmit}
								>	
									<input type="hidden" name="api" value={this.context.state.api} />

									<Row>
										<Col md={12}>
											<Form.Control
												as="select"
												size="sm"
												name="i__type"
												required
												onChange={this.handleTypeChange}
											>
												{types.map((item,i)=>{
													return <option value={item.key}> {item.value} </option>;
												})}
											</Form.Control>
										</Col>
									</Row><br />
									{this.state.type == 'member' ?
										<Row>
											<Col md={12}>
												<center>
													<div key={`inline-radio`} className="mb-3">
														<Form.Check inline label="All Member" required name="i__stype" value="allmember" type="radio" id={`inline-radio-1`} onChange={this.handleRadioChange} />
														<Form.Check inline label="Active Members" required name="i__stype" value="activemember" type="radio" id={`inline-radio-3`} onChange={this.handleRadioChange} />
														<Form.Check inline label="Inactive Members" required name="i__stype" value="inactivemember" type="radio" id={`inline-radio-4`} onChange={this.handleRadioChange} />
														<Form.Check inline label="Plan" name="i__stype" required value="plan" type="radio" id={`inline-radio-2`} onChange={this.handleRadioChange} />
													</div>
												</center>
											</Col>
										</Row>
									: null}
									<Row>
										{this.state.type == 'member' && (this.state.stype == 'allmember' || this.state.stype == 'activemember' || this.state.stype == 'inactivemember') ? 
											<Col md={12}><center><b style={{color : 'red'}}>{this.state.mebmercount} Members</b></center></Col>
										: this.state.stype == 'plan' ? 
											<Col md={12}>
												<Form.Control
													as="select"
													size="sm"
													name="i__plan"
													required
													onChange={this.handlePlanChange}
												>
													<option value="">-Select Plan-</option>
													{this.state.planList.map((item,i)=>{
														return <option value={item.id}> {item.name} </option>;
													})}
												</Form.Control>
											</Col>
										: null}
									</Row><br />
									{this.state.planId !='' ?
										<Row>
											<Col md={12}>
												<Form.Control
													as="select"
													size="sm"
													name="i__member"
													required
												>
													<option value="">-Select Member-</option>
													{this.state.memberList.length > 0 ?
														<option value="all">All</option>
													: null}
													{this.state.memberList.map((item,i)=>{
														return <option value={item.id}> {item.firstname} {item.lastname}</option>;
													})}
												</Form.Control>
											</Col>
										</Row>
									: null}
									<br />
									<Row>
										<Col md={12}>
											<Form.Control
												as="textarea"
												size="sm"
												name="i__message"
												rows="4"
												required
												placeholder="Enter message here..."
											/>
										</Col>
									</Row><br />
									<Row>
										<Col md={12}>
											<Button
												type="submit"
												variant="success"
												size="sm"
												style={{float : 'right'}}
											>
												Send
											</Button>
										</Col>
									</Row>
									</form>
								</Card.Body>
							</Card>
						</Col>
						<Col md={3}>
							
						</Col>
					</Row>
                </Spin>
            </React.Fragment>
		);
    }
	
}
export default Communication;