 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup ,
} from 'react-bootstrap';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../../utils';
import iziToast from 'izitoast';

import PsContext from '../../../context/PsContext';
import AddCentre from './addCentre';

const { Option } = Select;

class CentersList extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showAddModal: false,
			paginationPageSize: 30,
			dataList: [],
			dataView: [],
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
		this.handleAddModalClose = this.handleAddModalClose.bind(this);
		this.handlePaginationSizeChange = this.handlePaginationSizeChange.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData()
	{
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/getcenters?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleAddButtonClick(){
		this.setState({showAddModal: true});
	}
	
	handleAddModalClose(){
		this.setState({showAddModal: false});
	}
	
	handlePaginationSizeChange(current, pageSize) {
		this.setState({paginationPageSize: pageSize});
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return( (upperCase(obj.name).indexOf(v)>-1) || 
				(upperCase(obj.mail).indexOf(v)>-1) || 
				(upperCase(obj.contactnumber1).indexOf(v)>-1));
			});
			
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handlePasswordView(record){
		try{
			this.setState({showLoader: true});
			
			axios.get(Api.Url+'v1/ma/get_password?api='+
				this.context.state.api+'&id='+record.id).then(res=>{
					if(res['data'].status=='1'){
						//alert(res['data'].password);
						$.alert({
							title: 'Password',
							content: res['data'].pass
						});
						this.setState({showLoader: false});
					}
					else{
						message.error(res['data'].message || 'Error');
						this.setState({showLoader: false});
					}
				});
		}
		catch(error){}
	}
	
	render(){

		const columns = [
			{
				title: '#',
				dataIndex: 'id',
				key: 'id',
				render: (text, record)=>(
					<>
					{record.logo ? <Avatar src={<Image src={Api.Url + record.logo} />} /> :
					<Avatar icon={<i className="icofont-ui-user" />} />}
						{record.member_device_status=='0' ? 
						<Badge variant="danger" >
							<i className="icofont-close-line-circled" ></i>
						</Badge> : null}
					</>
				)
			},
			{
				title: 'Prefix',
				dataIndex: 'prefix',
				key: 'prefix',
			},
			{
				title: 'Centre Name',
				dataIndex: 'name',
				key: 'name',
			},
			{
				title: 'Mobile / Email',
				dataIndex: 'mobile',
				key: 'mobile',
				render: (text,record)=>(
					<>{record.contactnumber1} <br /> {record.mail}</>
				)
			},
			{
				title: 'Password',
				dataIndex: 'password',
				key: 'password',
				render: (text, record)=>(
				<>{`*****`}
					<div style={{float: 'right'}} >
					<Button
						type="button"
						size="xs"
						onClick={this.handlePasswordView.bind(this,record)}
					>
						<i className="icofont-eye" ></i>
					</Button>
					</div>
				</>
				)
			},
			{
				title: 'Started On',
				dataIndex: 'startedon',
				key: 'startedon',
				render: (text, record)=>(
					<>
						{momentDate(record.startedon, 'DD-MM-YYYY')}
					</>
				)
			},
			{
				title: 'Address',
				dataIndex: 'address',
				key: 'address',
			},
			{
				title: 'Manage',
				dataIndex: 'id',
				key: 'id',
				render: (text,record)=> (
				<center>
					<Link to={`/app/fitness-centre/${record.id}/${record.fitness_key}`} >
						View Details
					</Link>					
				</center>
				)
			},
		];
		
		return(
			<div>
			
				
					
					
				<Card   style={{position:'sticky',top:'56px',zIndex:'1'}} >
					<Card.Body>
						<Row>
							<Col md={8} >
								<span className="input-icon">
									<i className="material-icons" >search</i>
									<Form.Control  
										size="sm" 
										placeholder="Search by Centre Name, Email, Mobile" 
										onChange={this.handleSearchOnChange} />
								  </span>
							</Col>
							<Col md={4} >
								<div className="text-right" >
									<ButtonGroup>
										<Button
											type="button"
											size="sm"
											variant="outline-secondary"
											onClick={this.loadData}
										>
											<i className="icofont-refresh" ></i> Refresh
										</Button>
										
										<Button
											type="button"
											size="sm"
											variant="outline-secondary"
											onClick={this.handleAddButtonClick} 
										>
											<i className="icofont-plus" ></i> New Centre
										</Button>
										
									</ButtonGroup>
								</div>
							</Col>
						</Row>
					</Card.Body>
				</Card>
				
				<div className="mt-10 ps-table" >
					<Table 
						size="small"
						columns={columns} 
						bordered
						title={() => `Total ${Object.keys(this.state.dataView).length} centers in list`} 
						footer={() => `Total ${Object.keys(this.state.dataView).length} centers in list`} 
						loading={this.state.showLoader}
						dataSource={this.state.dataView}
						pagination={{ 
							pageSize: this.state.paginationPageSize,
							onShowSizeChange: this.handlePaginationSizeChange,
							position: ['topRight', 'bottomRight'],
						}} />
				</div>
				
				<Modal
					show={this.state.showAddModal}
					onHide={this.handleAddModalClose}
					backdrop="static" 
					size="100w"
				>
					<Modal.Header closeButton >
						Add New Center
					</Modal.Header>
					<Modal.Body>
						<AddCentre 
							afterFinish={this.loadData} 
						/>
					</Modal.Body>
				</Modal>
				
			</div>
		);
	}
	
}
export default CentersList;
