 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, Modal, ListGroup, Table} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, todayDate
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Drawer, Skeleton, Spin, Select,message, 
} from 'antd';

import stateDistricts from '../../../utils/stateDistricts.json';

import PsContext from '../../context/PsContext';
import ReceiptEntry_Form from '../Member/receiptEntry';
import AccountsMenu from './AccountsMenu';

const { Option } = Select;

class AccountReport extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			dataList: [],
			dataView: [],
			btnSearchText: 'Search',
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		
	}
	
	loadReceipts()
	{
		try{
			this.setState({
				showLoader: true,
				btnSearchText: '...',
				dataList: [],
				dataView: [],
			});
			axios.post(Api.Url+'v1/accounts/dailydata', $("#frm_accounts_Inp").serialize()).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
						btnSearchText: 'Search',
					});
				}
				else{
					this.setState({
						showLoader: false,
						btnSearchText: 'Search',
					});
				}
			});
		}
		catch(error){
			this.setState({
				showLoader: false,
				btnSearchText: 'Search',
			});
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({
				showLoader: true,
				btnSearchText: '...',
				dataList: [],
				dataView: [],
			});
			axios.post(Api.Url+'v1/accounts/dailydata', $("#frm_accounts_Inp").serialize()).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
						btnSearchText: 'Search',
					});
				}
				else{
					this.setState({
						showLoader: false,
						btnSearchText: 'Search',
					});
				}
			});
		}
		catch(error){
			this.setState({
				showLoader: false,
				btnSearchText: 'Search',
			});
		}
	}
	
	getOpeningBalance(){
		try{
			var d = this.state.dataView;
			if(parseFloat(d[0]['credit_amount'])>0)
				return (parseFloat(d[0]['balance']) - parseFloat(d[0]['credit_amount'])).toFixed(2);
			else
				return (parseFloat(d[0]['debit_amount']) + parseFloat(d[0]['balance'])).toFixed(2);
		}
		catch(error){
			return '0.00';
		}
	}
	
	getClosingBalance(){
		try{
			var d = this.state.dataView;
			return parseFloat(d[Object.keys(d).length-1]['balance']).toFixed(2);
		}
		catch(error){
			return '0.00';
		}
	}
	
	render(){

		var sno=1;
		
		return(
			<div>
				
				<AccountsMenu />
				
				<Card   style={{position:'sticky',top:'56px',zIndex:'1'}} >
					<Card.Body>
						<form
							action=""
							method="post"
							encType="multipart/form-data" 
							id="frm_accounts_Inp"
							onSubmit={this.handleFormSubmit}
						>
							<input type="hidden" name="api" value={this.context.state.api} />
							<Row>
								<Col md={2} >
									<label className="font-12" >From Date</label>
									<Form.Control 
										type="date"
										size="sm"
										name="i__from"
										max={todayDate()}
									/>
								</Col>
								
								<Col md={2} >
									<label className="font-12" >To Date</label>
									<Form.Control 
										type="date"
										size="sm"
										name="i__to"
										max={todayDate()}
									/>
								</Col>
								
								<Col md={2} >
									<Button
										type="submit"
										variant="warning"
										size="sm"
										className="mt-30"
									>
										{this.state.btnSearchText}
									</Button>
								</Col>
							</Row>
						</form>
					</Card.Body>
				</Card>
				
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<Card className="mt-15" >
					<Card.Body>
						<Table
							size="md" 
							bordered 
						>
							<thead>
								<tr>
									<th width="60" >S.No</th>
									<th>Date</th>
									<th>Type</th>
									<th>Description</th>
									<th width="150" >Income</th>
									<th width="150" >Expense</th>
									<th width="150" >Balance</th>
								</tr>
							</thead>
							<tbody>
							<tr>
								<td colSpan="6" align="right" >Opening Balance</td>
								<td align="right">{this.getOpeningBalance()}</td>
							</tr>
							{this.state.dataView.map((item,i)=>{
								return <tr>
									<td>{sno++}</td>
									<td>{item.ref_date}</td>
									<td>{item.account_type}</td>
									<td>{item.narration}</td>
									<td align="right" >{item.credit_amount}</td>
									<td align="right" >{item.debit_amount}</td>
									<td align="right" >{item.balance}</td>
								</tr>;
							})								
							}
							<tr>
								<td colSpan="6" align="right" >Closing Balance</td>
								<td align="right">{this.getClosingBalance()}</td>
							</tr>
							</tbody>
						</Table>
					</Card.Body>
				</Card>
				</Spin>
				
			</div>
		);
	}
	
}
export default AccountReport;
