 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, Modal} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';

import stateDistricts from '../../../utils/stateDistricts.json';

import PsContext from '../../context/PsContext';
import ChangePassword from './ChangePassword';

const { Option } = Select;

class UserProfile extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showChangePassword: false,
			showRunFp: false,
			loggedUser: [],
			stateValue: '',
			districtValue: '',
			districtList: [],
			btnText: 'Change Password',
		}
		
		this.handleEditPasswordShow = this.handleEditPasswordShow.bind(this);
		this.handlePasswordCloseClick = this.handlePasswordCloseClick.bind(this);
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleDistrictChange = this.handleDistrictChange.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.updateMemberFpStatus = this.updateMemberFpStatus.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	handleStateChange(value) {
		const index = stateDistricts.states.findIndex((item) => item.id === value);
		var districts = stateDistricts.states[index].districts;
		this.setState({districtList: districts, stateValue: value});
	}
	
	handleDistrictChange(value){
		this.setState({districtValue: value});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{			
			 this.setState({showLoader: true, btnText: 'Validating..'});
			axios.post(Api.Url+'v1/login', $("#frmLogin").serialize()).then(res=>{
				
				if(res['data'].status === '1'){
					
					this.context.updateUserLogin(res['data'].data, res['data'].role);
					this.context.updateAccessApi(res['data'].api);
					notify.show(res['data'].message,'success');
					//setlsItem('user_logged', 'YES');
					///setls('user', res['data'].data);
					//this.setState({isRedirect: true, loggedUser: res['data'].data});
					//window.location.href='';
				}
				else{
					//notify.show(res['data'].message,'error');
					this.setState({showLoader: false, btnText: 'Login'});
					iziToast.show({
						message: res['data'].message,
						position: 'topCenter',
						color: 'red',
					});
				}
			});	 
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false, btnText: 'Login'});
		}
	}
	
	handleEditPasswordShow(){
		this.setState({
			showChangePassword: true,
		});
	}
	
	handlePasswordCloseClick(){
		this.setState({
			showChangePassword: false,
		});
	}
	
	updateMemberFpStatus(){
		try{
			axios.get(Api.Url+'v1/iclock/employee/fpstatus?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({showRunFp: false});
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
				}
			});
		}
		catch(error){
			
		}
	}
	
	render(){

		
		
		return(
			<div>
				<Row>
					<Col md={8} >
						
						<Card>
							<Card.Body>
								<Row>
									<Col md={3} >
										User Name
									</Col>
									<Col md={9} >
										<div className="text-secondary" >
											{this.context.state.loggedUser.firstname} {this.context.state.loggedUser.lastname}
										</div>
									</Col>
								</Row>
								<hr />
								
								<Row>
									<Col md={3} >
										Mobile
									</Col>
									<Col md={9} >
										<div className="text-secondary" >
											{this.context.state.loggedUser.mobile}
										</div>
									</Col>
								</Row>
								<hr />
								
								<Row>
									<Col md={3} >
										Email
									</Col>
									<Col md={9} >
										<div className="text-secondary" >
											{this.context.state.loggedUser.mail}
										</div>
									</Col>
								</Row>
								<hr />
								
								<Row>
									<Col md={3} >
										Address
									</Col>
									<Col md={9} >
										<div className="text-secondary" >
											{this.context.state.loggedUser.address}
										</div>
									</Col>
								</Row>
								<hr />
								
								<Row>
									<Col md={12} >
										<Button 
											size="sm" 
											onClick={this.handleEditPasswordShow} 
										>
											Change Login Password
										</Button>
									</Col>
								</Row>
								
							</Card.Body>
						</Card>
						
						<Modal show={this.state.showChangePassword} onHide={this.handlePasswordCloseClick} >
							<Modal.Header closeButton>
								Change Password
							</Modal.Header>
							<Modal.Body>
								<ChangePassword />								
							</Modal.Body>
						</Modal>
						
						
						<Modal show={this.state.showRunFp} onHide={()=>{this.setState({showRunFp: false})}} >
							<Modal.Header closeButton>
								Run Members Finger Print Status
							</Modal.Header>
							<Modal.Body>
								<p>
									It will take some time to update the status. 
									<br />
									Do you want to run this ?
								</p>					
								<div className="text-center">
									<hr />
									<Button
										type="button"
										size="sm"
										onClick={this.updateMemberFpStatus} 
									>
										Yes! Run Now
									</Button>
								</div>
							</Modal.Body>
						</Modal>
						
						
					</Col>
				</Row>
			
			</div>
		);
	}
	
}
export default UserProfile;
