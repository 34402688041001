 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup ,
} from 'react-bootstrap';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';

import PsContext from '../../context/PsContext';

import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'

const { Option } = Select;

class AddProduct extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Save Product',
			showLoader: false,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/product/save', $("#frmAddProduct_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmAddProduct_Mod").reset();
					this.setState({
						showLoader: false,
					});
					message.success(res['data'].message || 'Success');
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({
						showLoader: false
					});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){

		return(
			<div>
				<Spin spinning={this.state.showLoader} >
					<form 
						action=""
						method="post"
						encType="multipart/form-data" 
						id="frmAddProduct_Mod"
						onSubmit={this.handleFormSubmit}
					>	
						<input type="hidden" name="api" value={this.context.state.api} />
						
						<Row>
							<Col md={12}>
								<label className="font-14" >Product Name
									<span className="text-danger">*</span>
								</label>
								<Form.Control
									type="text"
									size="sm"
									name="i__name"
									autoComplete="off"
									required 
								/>
							</Col>
						</Row>

						<Row>
							<Col md={12}>
								<label className="font-14" >Amount
									<span className="text-danger">*</span>
								</label>
								<Form.Control
									type="text"
									size="sm"
									name="i__amount"
									onKeyPress={decimalKeyPress}
									required 
								/>
							</Col>
						</Row>

						<Row>
							<Col md={12}>
								<label className="font-14" >Image URL
									<span className="text-danger">*</span>
								</label>
								<Form.Control
									type="url"
									size="sm"
									name="i__image"
									required 
								/>
							</Col>
						</Row>

						<Row>
							<Col md={12}>
								<label className="font-14" >Offer Percentage
								</label>
								<Form.Control
									type="number"
									size="sm"
									name="i__offer"
									onKeyPress={integerKeyPress}
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={12}>
								<label className="font-14" >Description
								</label>
								<Form.Control
									as="textarea"
									size="sm"
									name="i__desc"
									rows="3"
								/>
							</Col>
						</Row>
						
						<Row className="mt-15" >
							<Col md={12} >
								<div className="text-right" >
									<Button
										type="submit"
										variant="success"
									>
										Save Changes
									</Button>
								</div>
							</Col>
						</Row>
												
						
					</form>
				</Spin>
			</div>
		);
	}
	
}
export default AddProduct;
