 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, Modal} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';

import stateDistricts from '../../../utils/stateDistricts.json';

import PsContext from '../../context/PsContext';

const { Option } = Select;

class Filemanager extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	
	render(){

		
		
		return(
			<div className="inner-wrapper">
										
				<div className="inner-main" style={{left: '0'}} >
					<div className="inner-main-header">
						<a className="nav-link nav-icon rounded-circle nav-link-faded mr-3 d-md-none" href="#" data-toggle="inner-sidebar"><i className="material-icons">arrow_forward_ios</i></a>
						<h5 className="mb-0">All Files</h5>
					  </div>
				</div>
				
				
			</div>
		);
	}
	
}
export default Filemanager;
