import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Alert, Collapse, InputGroup,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';

import { Api } from '../../../utils/Variables';
import { getDateByFormat } from '../../../utils/appUtils';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress,
} from '../../../utils';
import stateDistricts from '../../../utils/stateDistricts.json';
import ReactFileReader from 'react-file-reader';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import UPLOAD_ARROW from '../../../assets/img/upload-arrow.png';

const { Option } = Select;

class LoginStatus extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			stateValue: '',
			showNewLogin: false,
			showUpdatePass: false,
			editData: this.props.editData,
			districtValue: '',
			districtList: [],
			profilePhoto: '',
			identityProof: '',
			submitBtn: 'Save Staff',
			selectedRole: '',
			currentPassword: '******',
		}
		
		this.handleStateChange = this.handleStateChange.bind(this);
		this.handleDistrictChange = this.handleDistrictChange.bind(this);
		this.handleProfilePhotoChange = this.handleProfilePhotoChange.bind(this);
		this.handleProfilePhotoRemoveClick = this.handleProfilePhotoRemoveClick.bind(this);
		this.handleIdentityProofChange = this.handleIdentityProofChange.bind(this);
		this.handleIdentityProofRemoveClick = this.handleIdentityProofRemoveClick.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleRoleChange = this.handleRoleChange.bind(this);
		this.handleShowNewLoginClick = this.handleShowNewLoginClick.bind(this);
		this.handleUpdatePasswordFormSubmit = this.handleUpdatePasswordFormSubmit.bind(this);
		this.handleViewPasswordClick = this.handleViewPasswordClick.bind(this);
		this.handlePassUpdateShowClick = this.handlePassUpdateShowClick.bind(this);
		this.handleDisableUserLogin = this.handleDisableUserLogin.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	handleStateChange(value) {
		const index = stateDistricts.states.findIndex((item) => item.id === value);
		var districts = stateDistricts.states[index].districts;
		this.setState({districtList: districts, stateValue: value});
	}
	
	handleDistrictChange(value){
		this.setState({districtValue: value});
	}
	
	handleProfilePhotoChange(files) {
		this.setState({profilePhoto: files.base64});
	}
	
	handleProfilePhotoRemoveClick() {
		this.setState({profilePhoto: ''});
	}
	
	handleIdentityProofChange(files) {
		this.setState({identityProof: files.base64});
	}
	
	handleIdentityProofRemoveClick() {
		this.setState({identityProof: ''});
	}
	
	handleRoleChange(e){
		this.setState({selectedRole: e});
	}
	
	getFieldValue(fieldName){
		try{
			var d = this.props.editData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	handleShowNewLoginClick(){
		this.setState({
			showNewLogin: !this.state.showNewLogin
		});
	}
	
	handleFormSubmit(e) {
		e.preventDefault();
		try{
			
			this.setState({showLoader: true, submitBtn: '....'});			
			axios.post(Api.Url+'v1/trainer/save', $("#frm_addTrainer_DForm").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frm_addTrainer_DForm").reset();
					message.success(res['data'].message || 'Success');
					this.setState({
						showLoader: false, 
						submitBtn: 'Save Staff',
						stateValue: '',
						districtValue: '',
						profilePhoto: '',
						identityProof: '',
					});
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false, submitBtn: 'Save Staff'});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false, submitBtn: 'Save Staff'});
		}
	}
		
	handleUpdatePasswordFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			
			axios.post(Api.Url+'v1/trainer/setnewpassword', $("#frm_Mod_StaffNewLogin").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frm_Mod_StaffNewLogin").reset();
					message.success(res['data'].message || 'Success');
					this.setState({
						showLoader: false,
						showNewLogin: false,
						showUpdatePass: false,
					});
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false, submitBtn: 'Save Staff'});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleViewPasswordClick(){
		try{			
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/trainer/viewpassword?i__id='+this.getFieldValue('id')+'&api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						showLoader: false,
						currentPassword: res['data'].data,
					});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false, submitBtn: 'Save Staff'});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handlePassUpdateShowClick(){
		this.setState({
			showUpdatePass: !this.state.showUpdatePass
		});
	}
	
	handleDisableUserLogin(){
		try{	

			if(!window.confirm('Do you want to disable the user login?')){
				return;
			}
			
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/trainer/disablelogin?i__id='+this.getFieldValue('id')+'&api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					this.setState({
						showLoader: false,
					});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false, submitBtn: 'Save Staff'});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	render(){

		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
			
			{this.getFieldValue('has_login')=='1' ?
				<form action="" method="post" encType="multipart/form-data" id="frm_Mod_StaffNewLogin" onSubmit={this.handleUpdatePasswordFormSubmit} >
				
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="i__id" value={this.getFieldValue('id')} />
					
							
					<Row className="mt-10" >
						<Col md={12} >
							<Button
								type="button"
								variant="primary"
								size="sm"
								onClick={this.handleDisableUserLogin} 
							>
								<i className="icofont-error" ></i> Disable User Login
							</Button>
							<hr />
						</Col>
					</Row>
					
					
					<Row className="mt-10" >
						<Col md={6} >
							<label>
								Username
								<span className="text-danger">*</span>
							</label>
							<Form.Control 
								name="i__userName" 
								type="text" 
								size="sm"
								placeholder="Username" 
								defaultValue={this.getFieldValue('trainerid')}
								readOnly
							/>
						</Col>
						
						<Col md={6} >
							<label>
								Current Password
								<span className="text-danger"></span>
							</label>
							<InputGroup className="mb-3" size="sm" >
								<Form.Control 
									name="i__currentPassword" 
									type="text" 
									size="sm"
									placeholder="*****" 
									value={this.state.currentPassword}
								/>
								<InputGroup.Prepend>
								  <Button
									type="button"
									variant="primary"
									size="sm"
									onClick={this.handleViewPasswordClick} 
								  >
									<i className="icofont-eye" ></i>
								  </Button>
								</InputGroup.Prepend>
							</InputGroup>
						</Col>
					</Row>

					<Card>
						<h6 className="px-10 py-10" style={{
							cursor: 'pointer',
						}} 
						onClick={this.handlePassUpdateShowClick} 
						>
							Update Password
							<span className="pull-right" >
							{this.state.showUpdatePass ? <i className="icofont-thin-up" ></i> : <i className="icofont-thin-down" ></i>}
							</span>
						</h6>
						<hr className="mt-0 mb-0" />
						<Collapse  in={this.state.showUpdatePass} >
							<div id="example-collapse-text">
							<Card.Body>
								<Row className="" >
									<Col md={12} >
										<label className="font-14" >Password</label>
										<Form.Control
											type="password"
											size="sm"
											name="i__password"
											required
										/>
									</Col>
								</Row>
								
								<Row className="mt-10" >
									<Col md={12} >
										<label  className="font-14" >Confirm Password</label>
										<Form.Control
											type="password"
											size="sm"
											name="i__confirmPassword"
											required
										/>
									</Col>
								</Row>
								
								<Row className="mt-10" >
									<Col md={12} >
										<hr />
										<div className="text-center" >
											<Button type="submit" size="sm" variant="success" >
												Update Password
											</Button>
										</div>
									</Col>
								</Row>
								
							</Card.Body>
							</div>
						</Collapse>
					</Card>
					
					
				</form>	
				 : <div>
					<Row>
						<Col md={12} >
							<Alert variant="info" >
								Login not enabled for this user..
							</Alert>
						</Col>
						
						<Col md={12} >
							<Button
								type="button"
								size="sm"
								variant="info"
								onClick={this.handleShowNewLoginClick}
							>
								<i className="icofont-check" ></i> Enable User Login
							</Button>
						</Col>
						
						<Col md={12} >
							<Collapse  in={this.state.showNewLogin} >
								 <div id="example-collapse-text">
									<form 
										action=""
										method="post"
										encType="multipart/form-data" 
										id="frm_Mod_StaffNewLogin"
										onSubmit={this.handleUpdatePasswordFormSubmit} 
									>
									
										<input type="hidden" name="api" value={this.context.state.api} />
										<input type="hidden" name="i__id" value={this.getFieldValue('id')} />
					
										<Row className="mt-15" >
											<Col md={12} >
												<label className="font-14" >Password</label>
												<Form.Control
													type="password"
													size="sm"
													name="i__password"
													required
												/>
											</Col>
										</Row>
										
										<Row className="mt-10" >
											<Col md={12} >
												<label  className="font-14" >Confirm Password</label>
												<Form.Control
													type="password"
													size="sm"
													name="i__confirmPassword"
													required
												/>
											</Col>
										</Row>
										
										<Row className="mt-10" >
											<Col md={12} >
												<div className="text-right" >
													<Button
														type="submit"
														variant="success"
														size="sm"
													>
														Update New Password
													</Button>
												</div>
											</Col>
										</Row>
										
									</form>									
								 </div>
							</Collapse >
						</Col>
					</Row>
					
					
				 </div>
			}
				</Spin>				
			</React.Fragment>
		);
	}
	
}
export default LoginStatus;
