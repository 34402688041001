 import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import $ from 'jquery';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, ButtonGroup, Modal, ListGroup} from 'react-bootstrap';

import AccountsMenu from '../AccountsMenu';
import AddAccountHead from '../AddAccountHead';
import PsContext from '../../../context/PsContext';

class AccountHead extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddHeadModal: false,
		}
		
		this.handleAddClick = this.handleAddClick.bind(this);
		this.handleAddModalClose = this.handleAddModalClose.bind(this);
	}
	
	componentDidMount(){

	}
	
	handleAddClick(){
		this.setState({
			showAddHeadModal: true,
		});
	}
	
	handleAddModalClose(){
		this.setState({
			showAddHeadModal: false,
		});
	}
	
	
	render(){
		
		return(
			<React.Fragment>
				
				<AccountsMenu />
				
				<Card  style={{position:'sticky',top:'56px',zIndex:'1'}}  >
					<Card.Body>
						<Row>
							<Col md={8} >
							
							</Col>
							<Col md={4} >
								<div className="text-right" >
									<ButtonGroup>
										<Button
											type="button"
											size="sm"
											variant="outline-secondary"
										>
											<i className="icofont-refresh" ></i> Refresh
										</Button>
										
										<Button
											type="button"
											size="sm"
											variant="outline-secondary"
											onClick={this.handleAddClick}
										>
											<i className="icofont-plus" ></i> New Device
										</Button>
										
									</ButtonGroup>
								</div>
							</Col>
						</Row>
					</Card.Body>
				</Card>
				
				
				<Modal 
					show={this.state.showAddHeadModal} 
					onHide={this.handleAddModalClose}  
					dialogClassName=""
					backdrop="static"
					size="lg" 
					keyboard={false}
				>
					<Modal.Header closeButton >
						Add Account Head
					</Modal.Header>
					<Modal.Body>
						<AddAccountHead 
							
						/>
					</Modal.Body>
				</Modal>
				
			</React.Fragment>			
		);
	}
	
}
export default AccountHead;
