import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import AddTrainer from './addTrainer';
import EditTrainer from './editTrainer';
import TrainerAttendance from './trainerAttendance';
import LoginStatus from './LoginStatus';
import AttendanceSummary from './AttendanceSummary';
import TrainerPerformanceAssesment from './TrainerPerformanceAssesment';

const { Option } = Select;

class TrainerList extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddDrawer: false,
			showEditDrawer: false,
			showLogModal: false,
			showLoginModal: false,
			showAttendanceModal : false,
			showAssesmentModal : false,
			editData: [],
			dataList: [],
			dataView: [],
			trainerList : [],
			selectedRowGroup: 'ALL',
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleAddTrainderClick = this.handleAddTrainderClick.bind(this);
		this.handleAttendanceClick = this.handleAttendanceClick.bind(this);
		this.handleAddDrawerCloseClick = this.handleAddDrawerCloseClick.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
		this.handleEditDrawerCloseClick = this.handleEditDrawerCloseClick.bind(this);
		this.handleRowGroupDropDownChange = this.handleRowGroupDropDownChange.bind(this);
		this.handleShowAddModalClick = this.handleShowAddModalClick.bind(this);
		this.handleShowAddModalCloseClick = this.handleShowAddModalCloseClick.bind(this);
		this.handleLoginModalClose = this.handleLoginModalClose.bind(this);
		this.handleShowAttendanceModalCloseClick = this.handleShowAttendanceModalCloseClick.bind(this);
		this.handleAssesmentClick = this.handleAssesmentClick.bind(this);
		this.handleShowAssesmentModalCloseClick = this.handleShowAssesmentModalCloseClick.bind(this);
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/trainers/all?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleAddTrainderClick() {
		this.setState({showAddDrawer: true});
	}
	
	handleAttendanceClick() {
		this.setState({showAttendanceModal: true});
	}

	handleAssesmentClick(){

		var d = this.state.dataList.filter(function(obj){
			return(obj.employee_type == 'TRAINER');
		});
		this.setState({trainerList : d,showAssesmentModal: true});
	}
	
	handleAddDrawerCloseClick(){
		this.setState({showAddDrawer: false});
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return( (upperCase(obj.trainerid).indexOf(v)>-1) || 
				(upperCase(obj.firstname).indexOf(v)>-1) || 
				(upperCase(obj.lastname).indexOf(v)>-1) || 
				(upperCase(obj.mobile).indexOf(v)>-1));
			});
			
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleDeleteActionClick(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: `Do you want to Delete the trainer ${record.firstname} ?`,
			buttons: {
				Yes: function(){ 
					$this.handleDeleteConfirm(record);
				},
				No: function() { }
			}
		});
	}
	
	handleDeleteConfirm(record){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__id', record.id);
			
			axios.post(Api.Url+'v1/trainer/remove', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					/*var index = d.findIndex(function(obj){
						return(obj.id==record.id);
					});
					d.splice(index,1);*/
					var dat = d.filter(function(obj){
						return(obj.id != record.id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						showLoader: false
					});
					//this.loadData();
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			message.error('Unknown Error');
			this.setState({showLoader: false});
		}
	}
	
	handleEditActionClick(record){
		this.setState({
			editData: record,
			showEditDrawer: true,
		});
	}
	
	handleEditDrawerCloseClick(){
		this.setState({
			editData: [],
			showEditDrawer: false,
		});
	}
	
	
	handleMachineDisableClick(trainerId){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to remove the staff from all the connected Machines?',
			buttons: {
				Yes: function(){
					$this.confirmMachineDisable(trainerId);
				},
				No: function(){ }
			}
		});
	}
	
	confirmMachineDisable(trainerId){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/trainer/remove_from_machine?mid='+
				trainerId+'&api='+
				this.context.state.api).then(res=>{
					if(res['data'].status=='1'){
						this.setState({ 
							showLoader: false, 
							memberMachineStatus: '0', 
						});
						message.success(res['data'].message || 'Success');
						this.loadData();
						if(this.props.afterFinish)
							this.props.afterFinish();
					}
					else{
						this.setState({showLoader: false});
						message.error(res['data'].message || 'Error');
					}
					
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	handleMachineEnableClick(trainerId){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to active the member in all the connected Machines?',
			buttons: {
				Yes: function(){
					$this.confirmMachineEnable(trainerId);
				},
				No: function(){ }
			}
		});
	}
	
	confirmMachineEnable(trainerId){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/trainer/activate_to_machine?mid='+
				trainerId+'&api='+
				this.context.state.api).then(res=>{
					if(res['data'].status=='1'){
						this.setState({ 
							showLoader: false, 
							memberMachineStatus: '1', 
						});
						message.success(res['data'].message || 'Success');
						this.loadData();
						if(this.props.afterFinish)
							this.props.afterFinish();
					}
					else{
						this.setState({showLoader: false});
						message.error(res['data'].message || 'Error');
					}
					
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleRowGroupDropDownChange(value){
		this.setState({showLoader: true});
		var $this = this;
		setTimeout(function() { 
			$this.updateRowGroupDropdownChange(value);
		}, 2000);
	}
	
	updateRowGroupDropdownChange(value){
		try{
			this.setState({showLoader: true});
			var d = this.state.dataList;
			if(value !='ALL'){
				
				d = this.state.dataList.filter(function(obj){
					return(obj.employee_type == value);
				});
			}
			
			this.setState({
				dataView: d,
				selectedRowGroup: value,
				showLoader: false,
			});
		}
		catch(error){
			this.setState({showLoader: false});
			console.log(error);
		}
	}
	
	handleShowAddModalClick(record){
		this.setState({
			editData: record,
			showLogModal: true,
		});
	}
	
	handleShowAddModalCloseClick(){
		this.setState({
			editData: [],
			showLogModal: false,
		});
	}
	
	handleMachineSwitchChange(record, e){
		
		if(e){
			this.handleMachineEnableClick(record.id);
		}
		else{
			this.handleMachineDisableClick(record.id);
		}
	}
	
	handleLoginModalOpen(record){
		this.setState({
			editData: record,
			showLoginModal: true,
		});
	}
	
	handleLoginModalClose(){
		this.setState({
			editData: [],
			showLoginModal: false,
		});
	}

	handleShowAttendanceModalCloseClick(){
		this.setState({
			showAttendanceModal: false,
		});
	}

	handleShowAssesmentModalCloseClick(){
		this.setState({
			showAssesmentModal: false,
			trainerList : [],
		});
	}
	
	render(){
		
		const columns = [
			{
				title: '#',
				dataIndex: 'id',
				key: 'id',
				render: (text, record)=>(
					<>
					{record.profilephoto ? <Avatar src={<Image src={Api.Url + record.profilephoto} />} /> :
					<Avatar icon={<i className="icofont-ui-user" />} />}
					</>
				)
			},
			{
				title: 'Role',
				dataIndex: 'employee_type',
				key: 'employee_type'
			},
			{
				title: 'Staff Id',
				dataIndex: 'trainerid',
				key: 'trainerid',
			},
			{
				title: 'Name',
				dataIndex: 'firstname',
				key: 'firstname',
				render: (text, record)=>(
					<>
					{record.firstname} {record.lastname}
					</>
				)
			},
			{
				title: 'Mobile / Email',
				dataIndex: 'mobile',
				key: 'mobile',
				render: (text,record)=>(
					<>{record.mobile} <br /> {record.email}</>
				)
			},
			{
				title: 'Joined',
				dataIndex: 'address',
				key: 'address',
				render: (text,record)=>(
					<>{momentDate(record.doa, 'DD-MM-YYYY')}</>
				)
			},
			{
				title: 'Yrs of Experience',
				dataIndex: 'yoe',
				key: 'yoe',
			},
			{
				title: 'Specialised in',
				dataIndex: 'special',
				key: 'special',
			},
			{
				title: 'Login',
				dataIndex: 'has_login',
				key: 'has_login',
				render: (text, record)=>(
					<center>
					<Switch 
						checkedChildren="Enabled"
						unCheckedChildren="Disabled"
						checked={record.has_login=='1'}
						onChange={this.handleLoginModalOpen.bind(this, record)}
					/>
					</center>
				)
			},
			{
				title: 'Machine Status',
				dataIndex: 'employee_device_status',
				key: 'employee_device_status',
				render: (text, record)=>(
					<center>
					<Switch 
						checkedChildren="Active"
						unCheckedChildren="InActive"
						checked={record.employee_device_status=='1'}
						onChange={this.handleMachineSwitchChange.bind(this, record)}
					/>
					{/*record.employee_device_status == '1' ?
						<div>
							<Tooltip title="Click to Disable" >
								<Button
									type="button"
									variant="danger"
									size="xs"
									onClick={this.handleMachineDisableClick.bind(this, record.id)}
								>
									<i className="icofont-ui-remove" ></i>
								</Button>
							</Tooltip>
							<span 
								className="badge badge-success"
								style={{
									float: 'right',
								}}
							>
								Active
							</span>
						</div>
						:
						<div>		
							<Tooltip title="Click to Enable" >
								<Button
									type="button"
									variant="success"
									size="xs"
									onClick={this.handleMachineEnableClick.bind(this, record.id)}
								>
									<i className="icofont-tick-mark" ></i>
								</Button>
							</Tooltip>
							<span 
								className="badge badge-danger"
								style={{
									float: 'right',
								}}
							>
								In Active
							</span>
						</div>
					*/}
					</center>
				)
			},
			{
				title: 'Manage',
				dataIndex: 'id',
				key: 'id',
				render: (text,record)=> (
					<center>
						<Tooltip title="Click to View Logs" >
							<Button type="button" size="xs" variant="info" onClick={this.handleShowAddModalClick.bind(this, record)} >
								<i className="icofont-card" />
							</Button>
						</Tooltip>
						&nbsp;
						<Tooltip title="Click to Edit" >
							<Button type="button" size="xs" variant="warning" onClick={this.handleEditActionClick.bind(this, record)} >
								<i className="icofont-edit" />
							</Button>
						</Tooltip>
						&nbsp;
						<Tooltip title="Click to Delete" >
							<Button type="button" size="xs" variant="danger" onClick={this.handleDeleteActionClick.bind(this, record)} >
								<i className="icofont-trash" />
							</Button>
						</Tooltip >
					</center>
				)
			},
		];
		
		var roles = [
			{key: 'ALL', value: 'SHOW ALL'},
			{key: 'TRAINER', value: 'TRAINER'},
			{key: 'STAFF', value: 'STAFF'},
			{key: 'MANAGER', value: 'MANAGER'},
		];
				
		return(
			<React.Fragment>
				
				
					<Card>
						<Card.Body>
							<Row>
								<Col md={5} >
									<span className="input-icon">
										<i className="material-icons" >search</i>
										<Form.Control  
											size="sm" 
											placeholder="Search by Staff Id, Name, Mobile" 
											onChange={this.handleSearchOnChange} />
									  </span>
								 </Col>
								 <Col md={2} >
									<Select
									  showSearch
									  style={{ width: 180 }}
									  placeholder="Select"
									  optionFilterProp="children"
									  style={{width: '100%'}}
									  onChange={this.handleRowGroupDropDownChange}
									  defaultValue={this.state.selectedRowGroup}
									  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
									>
									  {roles.map((item) => (
										<Option key={item.key} value={item.key}> {item.value} </Option>
									  ))}
									</Select>
								 </Col>
								 <Col md={5} >
									<div className="text-right" >
										<ButtonGroup >
											<Button size="sm" variant="outline-secondary" onClick={this.loadData} >
												<i className="icofont-refresh" /> Refresh
											</Button>
											<Button size="sm" variant="outline-secondary" onClick={this.handleAddTrainderClick} >
												<i className="icofont-plus" /> Add Staff
											</Button>
											<Button size="sm" variant="outline-secondary" onClick={this.handleAttendanceClick} >
												<i className="icofont-calendar" /> Attendance
											</Button>
											<Button size="sm" variant="outline-secondary" onClick={this.handleAssesmentClick} >
												<i className="icofont-gym" /> Trainer Assesment
											</Button>
										</ButtonGroup>
									</div>
								 </Col>
							</Row>
						</Card.Body>
					</Card>
					
					<div className="mt-10 ps-table" >
						<Table 
							size="small"
							loading={this.state.showLoader}
							columns={columns} 
							dataSource={this.state.dataView} />
					</div>
					
					<Drawer 
						visible={this.state.showAddDrawer} 
						placement="right" 
						title="Add Staff" 
						width="820"
						onClose={this.handleAddDrawerCloseClick} >						
						{this.state.showAddDrawer ?
							<AddTrainer 
								afterFinish={this.loadData} />
						: null}						
					</Drawer>
					
					<Drawer 
						visible={this.state.showEditDrawer} 
						placement="right" 
						title={`Edit Staff - ${this.state.editData.trainerid}`}
						width="820"
						onClose={this.handleEditDrawerCloseClick} >
						
						{this.state.showEditDrawer ?
							<EditTrainer 
								editData={this.state.editData}
								afterFinish={this.loadData} />
						: null}
					</Drawer>
					
					<Modal
						size="xl"
						 backdrop="static"
						show={this.state.showLogModal}
						onHide={this.handleShowAddModalCloseClick} 
						
					>
					<Modal.Header 
							closeButton 
						>
							{`View Attendance Logs of ${this.state.editData.trainerid}`}
						</Modal.Header>
						<Modal.Body>
							<TrainerAttendance 
								editData={this.state.editData}
								mid={this.state.editData.trainerid}
							/>
						</Modal.Body>
					</Modal>
					
					<Modal
						size="md"
						 backdrop="static"
						show={this.state.showLoginModal}
						onHide={this.handleLoginModalClose} 
						
					>
						<Modal.Header 
							closeButton 
						>
							{`Login Status of ${this.state.editData.trainerid}`}
						</Modal.Header>
						<Modal.Body>
							<LoginStatus 
								editData={this.state.editData}
								mid={this.state.editData.trainerid}
								afterFinish={this.loadData}
							/>
						</Modal.Body>
					</Modal>
					
					<Modal
						size="xl"
						 backdrop="static"
						show={this.state.showAttendanceModal}
						onHide={this.handleShowAttendanceModalCloseClick} 
						
					>
					<Modal.Header 
							closeButton 
						>
							{`Attendance Summary`}
						</Modal.Header>
						<Modal.Body>
							<AttendanceSummary />
						</Modal.Body>
					</Modal>
					
					<Modal
						size="xl"
						 backdrop="static"
						show={this.state.showAssesmentModal}
						onHide={this.handleShowAssesmentModalCloseClick} 
						
					>
					<Modal.Header 
							closeButton 
						>
							{`Trainer Performance Assesment`}
						</Modal.Header>
						<Modal.Body>
							<TrainerPerformanceAssesment trainerList={this.state.dataList} />
						</Modal.Body>
					</Modal>
			
			</React.Fragment>
		);
	}
	
}
export default TrainerList;
