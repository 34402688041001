export default {
  top: [
    {
      name: 'Dashboard', url: '/app', icon: 'Home', ionicon: 'icofont-ui-home', exact: true,
    },
    {
      name: 'Club Management', url: '/app/fitness-centre', icon: 'Home', ionicon: 'icofont-building', exact: false,
    },
	{
      name: 'Announcements', url: '/app/announcements', icon: 'Home', ionicon: 'icofont-bullhorn', exact: true,
    },
	{
      name: 'Pages', url: '/app/master/pages', icon: 'Home', ionicon: 'icofont-list', exact: true,
    },
    {
      name: 'Products', url: '/app/products', icon: 'Home', ionicon: 'icofont-basket', exact: true,
    },
	{
      name: 'Product Enquiry', url: '/app/master/product-enquiry', icon: 'Home', ionicon: 'icofont-info', exact: false,
    },
	{
      name: 'Gallery', url: '/app/master/gallery', icon: 'Home', ionicon: 'icofont-image', exact: true,
    },
	{
      name: 'Category', url: '/app/master/category', icon: 'Home', ionicon: 'icofont-file-document', exact: true,
    },
	{
      name: 'Video', url: '/app/master/video', icon: 'Home', ionicon: 'icofont-video', exact: true,
    },
	{
      name: 'Devices', url: '/app/fitness-centres', icon: 'Home', ionicon: 'icofont-surface-tablet', exact: true,
    },
  ],
  admin: [
    {
      name: 'Dashboard', url: '/app', icon: 'Home', ionicon: 'icofont-ui-home', exact: true,
    },
	 {
      name: 'Members', url: '/app/members', icon: 'Home', ionicon: 'icofont-users', exact: true,
    },
	 {
      name: 'Members Report', url: '/app/members-report', icon: 'Home', ionicon: 'icofont-file-document', exact: false,
    },
	 {
      name: 'Due List', url: '/app/members-due-list', icon: 'Home', ionicon: 'icofont-money-bag', exact: true,
    },
	 {
      name: 'Enquires', url: '/app/gym-enquires', icon: 'Home', ionicon: 'icofont-book-alt', exact: true,
    },
	  {
      name: 'Receipt Entry', url: '/app/receipts', icon: 'Home', ionicon: 'icofont-notebook', exact: false,
    },
	/* {
      name: 'Payment Entry', url: '/app/payments', icon: 'Home', ionicon: 'icofont-notepad', exact: false,
    }, */
	  {
      name: 'Staff / Trainers', url: '/app/trainers', icon: 'Home', ionicon: 'icofont-ui-user-group', exact: true,
    },
	  {
      name: 'Staff Payroll', url: '/app/staff-payroll', icon: 'Home', ionicon: 'icofont-file-sql', exact: false,
    },
    {
      name: 'Accounts', url: '/app/accounts', icon: 'Home', ionicon: 'icofont-file-spreadsheet', exact: false,
    },
    {
      name: 'Product', url: '/app/product', icon: 'Home', ionicon: 'icofont-basket', exact: false,
    },
	{
      name: 'Product Enquiry', url: '/app/product-enquiry', icon: 'Home', ionicon: 'icofont-info', exact: false,
    },
    {
      name: 'Equipments', url: '/app/equipments', icon: 'Home', ionicon: 'icofont-gym', exact: false,
    },
    {
      name: 'Price Packages', url: '/app/price-packages', icon: 'Home', ionicon: 'icofont-money', exact: true,
    },
    {
      name: 'Batch Timings', url: '/app/batch-timings', icon: 'Home', ionicon: 'icofont-calendar', exact: true,
    },
    {
      name: 'Excel Offers', url: '/app/offers', icon: 'Home', ionicon: 'icofont-gift', exact: true,
    },
	{
      name: 'Announcements', url: '/app/announcement', icon: 'Home', ionicon: 'icofont-bullhorn', exact: true,
    },
	{
      name: 'Pages', url: '/app/pages', icon: 'Home', ionicon: 'icofont-list', exact: true,
    },
	{
      name: 'Gallery', url: '/app/gallery', icon: 'Home', ionicon: 'icofont-image', exact: true,
    },
	{
      name: 'ReferalCode', url: '/app/referalcode', icon: 'Home', ionicon: 'icofont-key', exact: true,
    },
    {
      name: 'Communication', url: '/app/communication', icon: 'Home', ionicon: 'icofont-google-talk', exact: true,
    },
	  {
      name: 'My Devices', url: '/app/mydevices', icon: 'Home', ionicon: 'icofont-bull-dozer', exact: true,
    },
	/* {
      name: 'Settings', url: '/app/settings', icon: 'Settings', ionicon: 'icofont-gear', exact: false,
    }, */
   
  ],
  staff: [
    {
      name: 'Dashboard', url: '/app', icon: 'Home', ionicon: 'icofont-ui-home', exact: true,
    },
	{
      name: 'Members', url: '/app/members', icon: 'Home', ionicon: 'icofont-users', exact: true,
    },
	{
      name: 'Members Report', url: '/app/members-report', icon: 'Home', ionicon: 'icofont-file-document', exact: false,
    },
	{
      name: 'Due List', url: '/app/members-due-list', icon: 'Home', ionicon: 'icofont-money-bag', exact: true,
    },
	{
      name: 'Enquires', url: '/app/gym-enquires', icon: 'Home', ionicon: 'icofont-book-alt', exact: true,
    },
	{
      name: 'Receipt Entry', url: '/app/receipts', icon: 'Home', ionicon: 'icofont-notebook', exact: false,
    },
  ],
  bottom: [
  ],
};
