 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, Modal, ListGroup, Table} from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate, todayDate
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Drawer, Skeleton, Spin, Select,message, 
} from 'antd';

import stateDistricts from '../../../utils/stateDistricts.json';

import PsContext from '../../context/PsContext';
import PaymentMenu from './paymentMenu';
import ReceiptEntry_Form from '../Member/receiptEntry';

const { Option } = Select;

class PaymentEntry extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnSearchText: 'Search',
			showLoader: false,
			dataList: [],
			dataView: [],
		}
		
		this.loadReceipts = this.loadReceipts.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		
	}
	
	loadReceipts()
	{
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/receipts/list_recent?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({
				showLoader: true,
				dataList: [],
				dataView: []
			});
			
			
			axios.post(Api.Url+'v1/accounts/receipts/', $("#frm_receipts_q").serialize()).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){

		var sno=1;
		var totalAmount = 0;
		return(
			<div>
			
			<PaymentMenu />
			
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
					<Card>
						<Card.Body>
							<form
									action=""
									method="post"
									encType="multipart/form-data" 
									id="frm_receipts_q"
									onSubmit={this.handleFormSubmit}
								>
									<input type="hidden" name="api" value={this.context.state.api} />
									<Row>
										<Col md={2} >
											<label className="font-12" >From Date</label>
											<Form.Control 
												type="date"
												size="sm"
												name="i__from"
												max={todayDate()}
											/>
										</Col>
										
										<Col md={2} >
											<label className="font-12" >To Date</label>
											<Form.Control 
												type="date"
												size="sm"
												name="i__to"
												max={todayDate()}
											/>
										</Col>
										
										<Col md={2} >
											<Button
												type="submit"
												variant="warning"
												size="sm"
												className="mt-30"
											>
												{this.state.btnSearchText}
											</Button>
										</Col>
									</Row>
								</form>
						</Card.Body>
					</Card>
					
					<Card className="mt-15" >
						<Card.Body>
							<Table
								size="sm"
								bordered
							>
								<thead>
									<tr>
										<th>S.No</th>
										<th>Date</th>
										<th>Type</th>
										<th>Code</th>
										<th>Member Name</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
								{this.state.dataView.map((item,i)=>{
									totalAmount = parseFloat(totalAmount) + parseFloat(item.paid_amount);
									return <tr key={i} >
										<td>{sno++}</td>
										<td>{momentDate(item.created_on, 'DD-MM-YYYY')}</td>
										<td>{item.recort_type}</td>
										<td>{item.member_code}</td>
										<td>{item.firstname} {item.lastname}</td>
										<td align="right" >{item.paid_amount}</td>
									</tr>;
								})									
								}
								<tr>
									<td colSpan="5" align="right"  >Total : </td>
									<td  align="right" ><b>{parseFloat(totalAmount).toFixed(2)}</b></td>
								</tr>
								</tbody>
							</Table>
						</Card.Body>
					</Card>
							
				</Spin>
				
			</div>
		);
	}
	
}
export default PaymentEntry;
