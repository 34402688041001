import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, Select,message, TimePicker,
} from 'antd';

import { Api } from '../../../utils/Variables';
import { getDateByFormat } from '../../../utils/appUtils';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress,
} from '../../../utils';
import stateDistricts from '../../../utils/stateDistricts.json';
import ReactFileReader from 'react-file-reader';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import UPLOAD_ARROW from '../../../assets/img/upload-arrow.png';

const { Option } = Select;

class AddTiming extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			startTime: '',
			endTime: '',
			submitBtn: 'Save Timing',
		}
		
	
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.onStartTimeChange = this.onStartTimeChange.bind(this);
		this.onEndTimeChange = this.onEndTimeChange.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	onStartTimeChange(time, timeString){
		try{
			this.setState({
				startTime: timeString
			});
		}
		catch(error){
			
		}
	}
	
	onEndTimeChange(time, timeString){
		try{
			this.setState({
				endTime: timeString
			});
		}
		catch(error){
			
		}
	}
	
	
	handleFormSubmit(e) {
		e.preventDefault();
		try{
			
			if(this.state.startTime.length<3)
			{
				message.error('Select Start Time');
				return;
			}
			
			if(this.state.endTime.length<3)
			{
				message.error('Select End Time');
				return;
			}
			
			this.setState({showLoader: true, submitBtn: '....'});			
			axios.post(Api.Url+'v1/timing/save', $("#frm_addTiming_DForm").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frm_addTiming_DForm").reset();
					message.success(res['data'].message || 'Success');
					this.setState({
						showLoader: false, 
						submitBtn: 'Save Timing',
						startTime: '',
						endTime: '',
					});
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false, submitBtn: 'Save Timing'});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false, submitBtn: 'Save Timing'});
		}
	}
	
	
	render(){

		const date = new Date();
		const ageLimit = date.setFullYear(date.getFullYear() - 10);
  
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<form action="" method="post" encType="multipart/form-data" id="frm_addTiming_DForm" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="i__startTime" value={this.state.startTime} />
					<input type="hidden" name="i__endTime" value={this.state.endTime} />
					
					<Row>
						<Col md={12} >
							<label>
								Start Time 
								<span className="text-danger">*</span>
							</label>
							<TimePicker 
								use12Hours 
								format="h:mm A" 
								onChange={this.onStartTimeChange} 
								style={{ width: '100%' }} />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<label>
								End Time 
								<span className="text-danger">*</span>
							</label>
							<TimePicker 
								use12Hours 
								format="h:mm A" 
								onChange={this.onEndTimeChange} 
								style={{ width: '100%' }} />
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={12} >
							<label>
								Preferable
								<span className="text-danger"></span>
							</label>
							<Form.Control
								as="select"
								name="i__preferable"
								size="sm" 
							>
								<option value="Unisex Time">Unisex Time</option>
								<option value="Pink Time">Pink Time</option>
								<option value="Men Time">Men Time</option>
							</Form.Control>
						</Col>
					</Row>
					
					<Row>
						<Col md={12} >
							<hr />
							<div className="text-center" >
								<Button type="submit" size="sm" variant="success" disabled={this.state.submitBtn!='Save Timing'} >
									{this.state.submitBtn}
								</Button>
							</div>
						</Col>
					</Row>
					
				</form>	
				</Spin>				
			</React.Fragment>
		);
	}
	
}
export default AddTiming;
