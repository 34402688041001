 import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../../utils/Variables';
import axios from 'axios';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , ButtonGroup , Table,
} from 'react-bootstrap';
import {
  Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch, Tabs 
} from 'antd';
import ReactFileReader from 'react-file-reader';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../../utils';
import iziToast from 'izitoast';

import PsContext from '../../../context/PsContext';
import AddCentre from './addCentre';
import UPLOAD_ARROW from '../../../../assets/img/upload-arrow.png';

const { Option } = Select;
const { TabPane } = Tabs;

class ClubInfo extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			clubData: this.props.data,
			showModal: false,
			selectedProfilePhoto: '',
			profilePhoto: '',
		}
		
		this.handleModalOpen = this.handleModalOpen.bind(this);
		this.handleModalClose = this.handleModalClose.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleProfilePhotoChange = this.handleProfilePhotoChange.bind(this);
		this.handleProfilePhotoRemoveClick = this.handleProfilePhotoRemoveClick.bind(this);
	}
	
	componentDidMount(){
		
		this.setState({profilePhoto: this.props.data.logo});
	}
	
	
	handleProfilePhotoChange(files) {
		this.setState({
			profilePhoto: files.base64,
			selectedProfilePhoto: files.base64
		});
	}
	
	handleProfilePhotoRemoveClick() {
		this.setState({
			profilePhoto: '',
			selectedProfilePhoto: ''
		});
	}
	
	getClubDetails(fieldName){
		try{
			var d = this.state.clubData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	
	handleModalClose(){
		this.setState({showModal: false, showLoader: false});
	}
	
	handleModalOpen(){
		this.setState({showModal: true});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/ma/fitness/update_basic', $("#frmEditClub_Frm").serialize()).then(res=>{
				
				if(res['data'].status === '1'){
										
					message.success(res['data'].message || 'Success');
					this.setState({showLoader: false, btnText: 'Change Password'});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					this.setState({showLoader: false, btnText: 'Change Password'});
					message.error(res['data'].message || 'Error');
				}
			});	 
		}
		catch(error){
			
		}
	}
	
	render(){

		
		return(
			<div>
			<Spin
				spinning={this.state.showLoader}
			>
					<Card>
						<Card.Body>
							<div className="text-right mb-15" >
								<Button type="button" size="sm" variant="info" onClick={this.handleModalOpen} >
									<i className="icofont-edit"></i> Edit Club Info
								</Button>
							</div>
							<Table>
								<tbody>
									<tr>
										<td>Club Name</td>
										<td>
											<Form.Control
												type="text"
												size="md"
												value={this.getClubDetails('name')}
											/>
										</td>
									</tr>
									<tr>
										<td>Short Code</td>
										<td>
											<Form.Control
												type="text"
												size="md"
												value={this.getClubDetails('prefix')}
											/>
										</td>
									</tr>
									<tr>
										<td>Registered On</td>
										<td>
											<Form.Control
												type="text"
												size="md"
												value={this.getClubDetails('idate')}
											/>
										</td>
									</tr>
									<tr>
										<td>Email</td>
										<td>
											<Form.Control
												type="text"
												size="md"
												value={this.getClubDetails('mail')}
											/>
										</td>
									</tr>
									<tr>
										<td>Contact Number</td>
										<td>
											<Form.Control
												type="text"
												size="md"
												value={this.getClubDetails('contactnumber1')}
											/>
										</td>
									</tr>
									<tr>
										<td>GST No</td>
										<td>
											<Form.Control
												type="text"
												size="md"
												value={this.getClubDetails('gst')}
											/>
										</td>
									</tr>
									<tr>
										<td>Address</td>
										<td>
											<Form.Control
												type="text"
												size="md"
												value={this.getClubDetails('address')}
											/>
										</td>
									</tr>
									
									<tr>
										<td>Last Login</td>
										<td>
											<Form.Control
												type="text"
												size="md"
												value={this.getClubDetails('lastlogin')}
											/>
										</td>
									</tr>
									<tr>
										<td>Last Login IP</td>
										<td>
											<Form.Control
												type="text"
												size="md"
												value={this.getClubDetails('ip')}
											/>
										</td>
									</tr>
								</tbody>
							</Table>
						</Card.Body>
					</Card>
					
								
			</Spin>
			
					<Modal show={this.state.showModal} onHide={this.handleModalClose} size="lg" >
						<Modal.Header closeButton >
							Edit Club Info
						</Modal.Header>
						<Modal.Body>
							<form action="" method="post" id="frmEditClub_Frm" encType="multipart/form-data" onSubmit={this.handleFormSubmit} >
							<input type="hidden" name="api" value={this.context.state.api} />
							<input type="hidden" name="fitness_key" value={this.getClubDetails('fitness_key')} />
							<input type="hidden" name="i__profilePhoto" value={this.state.selectedProfilePhoto} />
							
							<Spin
								spinning={this.state.showLoader}
							>
								<Row>
									<Col md={4} >
										<label>Club Name <span className="text-danger">*</span> </label>
									</Col>
									<Col md={8} >
										<Form.Control
											type="text"
											size="md"
											defaultValue={this.getClubDetails('name')}
											id="i__name"
											name="i__name"
											required
										/>
									</Col>
								</Row>
								
								<Row className="mt-15" > 
									<Col md={4} >
										<label>GST No <span className="text-danger"></span> </label>
									</Col>
									<Col md={8} >
										<Form.Control
											type="text"
											size="md"
											defaultValue={this.getClubDetails('gst')}
											id="i__gst"
											name="i__gst"
										/>
									</Col>
								</Row>
								
								<Row className="mt-15" > 
									<Col md={4} >
										<label>Contact Number <span className="text-danger">*</span> </label>
									</Col>
									<Col md={8} >
										<Form.Control
											type="number"
											size="md"
											defaultValue={this.getClubDetails('contactnumber1')}
											id="i__mobile"
											name="i__mobile"
											required
										/>
									</Col>
								</Row>
								
								<Row className="mt-15" > 
									<Col md={4} >
										<label>Club Email <span className="text-danger">*</span> </label>
									</Col>
									<Col md={8} >
										<Form.Control
											type="text"
											size="md"
											defaultValue={this.getClubDetails('mail')}
											id="i__email"
											name="i__email"
											required
											readOnly
										/>
									</Col>
								</Row>
								
								<Row className="mt-15" > 
									<Col md={4} >
										<label>Address <span className="text-danger">*</span> </label>
									</Col>
									<Col md={8} >
										<Form.Control
											type="text"
											size="md"
											defaultValue={this.getClubDetails('address')}
											id="i__address"
											name="i__address"
											required
										/>
									</Col>
								</Row>
								
								<Row className="mt-20" >
									<Col md={8} >
										<label>Logo</label>
									</Col>
									<Col md={4} >
										
										<ReactFileReader  base64 handleFiles={this.handleProfilePhotoChange} >
										<img 
											src={this.state.selectedProfilePhoto  ? 
											this.state.selectedProfilePhoto : 
											this.state.profilePhoto ? 
											Api.Url + this.state.profilePhoto 
											: UPLOAD_ARROW
											} 
											className="img-upload" 
											tooltip="Click to Upload new Image"
											style={{
												maxHeight: '120px',
											}}
										/>
										</ReactFileReader>
										{this.state.profilePhoto ? <>
											<Button 
												size="xs" 
												variant="danger" 
												onClick={this.handleProfilePhotoRemoveClick} >
												Remove
											</Button>
										</> : null}
									</Col>
								</Row>
								
								<Row className="mt-15" > 
									<Col md={4} >
										
									</Col>
									<Col md={8} >
										<div className="text-right" >
											<Button
												type="submit"
												variant="success"
												size="md"
											>
												Update
											</Button>
										</div>
									</Col>
								</Row>
								
								
							</Spin>
							</form>
						</Modal.Body>
					</Modal>
			</div>							
		);
	}
	
}
export default ClubInfo;
