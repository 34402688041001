import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Table
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate, todayDate, groupByMultiple,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import AddTrainer from './addTrainer';
import EditTrainer from './editTrainer';

const { Option } = Select;

class TrainerAttendance extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddDrawer: false,
			showEditDrawer: false,
			showLogModal: false,
			editData: [],
			dataList: [],
			dataView: [],
			selectedRowGroup: 'ALL',
			selectedMonth: '',
			selectedMonthValue: '',
			selectedYearValue: '',
		}
		
		this.loadData = this.loadData.bind(this);
		this.handleMonthChange = this.handleMonthChange.bind(this);
		
	}
	
	componentDidMount(){
		
	}
	
	handleMonthChange(e){
		var m = new Date(e.target.value).getMonth();
		var y = new Date(e.target.value).getFullYear();
		
		this.setState({
			selectedMonth: e.target.value,
			selectedMonthValue: m,
			selectedYearValue: y,
		});
	}
	
	loadData(){
		try{
			
			this.setState({
				showLoader: true, 
				dataList: [], 
				dataView: []
			});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__date', this.state.selectedMonth);
			form.append('i__mid', this.props.mid);
			
			axios.post(Api.Url+'v1/iclock/employee/attnlogs', form).then(res=>{
				if(res['data'].status=='1'){
					var d = res['data'].data;
					var s = groupByMultiple(d, function(obj){
						return [momentDate(obj.LogDate, 'YYYY-MM-DD')];
					});
					
					this.setState({
						dataList: s, //res['data'].data,
						dataView: s, //res['data'].data,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
					message.error(res['data'].message || 'Error');
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	daysInMonth (month, year) {
		return new Date(year, month, 0).getDate();
	}
	
	getSundays(year, month, totalDays){
		
		var sun = new Array();
		for(var i=1;i<=totalDays;i++){    //looping through days in month
			var newDate = new Date(year,month,i)
			if(newDate.getDay()==0){   //if Sunday
				sun.push(i);
			}
		}
	}
	
	getTimeList(lists){
		var s = [];
		lists.map((item,i)=>{
			s.push(`${momentDate(item.LogDate, 'HH:mm A')} -`);
		});
		return s; //.slice(0,-1);
	}
	
	render(){
		
		var sno=1;
		
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
				<div style={{minHeight: 'calc(100vh - 200px)'}}>
					
					
					<Row>
						<Col md={1} >
							<label>Month</label>
						</Col>
						<Col md={3} >
							<Form.Control
								type="month"
								size="sm"
								max={momentDate(todayDate(), 'YYYY-MM')}
								onChange={this.handleMonthChange} 
							/>
						</Col>
						<Col md={1} >
							<Button
								type="button"
								size="sm"
								variant="warning"
								onClick={this.loadData} 
								
							>
								Search
							</Button>
						</Col>
					</Row>	
					
					<Row className="mt-20" >
						<Col md={12} >
							{/*<Table
								bordered
								size="sm"
							>
								<thead>
									<tr>
										<th>S.No</th>
										<th>Date</th>
										<th>Time</th>
									</tr>
								</thead>
							
	</Table>*/}
							<Table
								bordered
								size="sm"
							>
								<thead>
									<tr>
										<th>S.No</th>
										<th>Date</th>
										<th>Time</th>
									</tr>
								</thead>
								<tbody>
								{this.state.dataView.map((items, i)=>{
									return <tr key={i} >
										<td>{sno++}</td>
										<td>{momentDate(items[0].LogDate, 'DD-MM-YYYY')}</td>
										<td>{this.getTimeList(items)}</td>
									</tr>;
								})
								}
								</tbody>
							</Table>
						</Col>
					</Row>
					
					
				</div>
				</Spin>
			</React.Fragment>
		);
	}
	
}
export default TrainerAttendance;
