import React from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import SiteLayout from '../layouts/SiteLayout';
import Login from '../views/pages/Login';
import StaffLogin from '../views/pages/Login/StaffLogin';

export default () => (

  <HashRouter>
    <Switch>
      <Route path="/app" component={SiteLayout} />

      <Route path="/login" component={StaffLogin} />
      <Route path="/" component={Login} />

    </Switch>
  </HashRouter>
);
