import moment from 'moment';

export function getDateByFormat(input, format) {
  let local = '';
  if (input) {
    local = moment(input).format(format);
  }
  return local;
}

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function getFirstDayofMonth() {
  const date = new Date();
  const firstDay = new Date(date.getFullYear(),
    date.getMonth(), 1);

  return moment(firstDay).format('YYYY-MM-DD');
}

export function getLastDayofMonth() {
  const date = new Date();
  const lastDay = new Date(date.getFullYear(),
    date.getMonth(), daysInMonth(date.getMonth() + 1,
      date.getFullYear()));

  return moment(lastDay).format('YYYY-MM-DD');
}

export function getDefaultNoValue(input) {
  let value = 'Not Assigned';
  if (input) {
    value = input;
  }
  return value;
}
