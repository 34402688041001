 import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import Notifications, {notify} from 'react-notify-toast';
import {Api} from '../../../utils/Variables';
import axios from 'axios';
import {Row, Col, Card, Form, Button, ButtonGroup, Modal, ListGroup, Badge } from 'react-bootstrap';
import {
  getls, integerIndMobile, lettersOnly, integerAadhar, integerKeyPress, decimalKeyPress, upperCase, momentDate
} from '../../../utils';
import iziToast from 'izitoast';

import {
  Table, Drawer, Skeleton, Spin, Select,message, 
} from 'antd';


import PsContext from '../../context/PsContext';
import AddDevice from './addDevice';

const { Option } = Select;

class DeviceList extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
			showAddModal: false,
			dataList: [],
			dataView: [],
		}
		
		this.loadDevices = this.loadDevices.bind(this);
		this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
		this.handleAddModalClose = this.handleAddModalClose.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
	}
	
	componentDidMount(){
		
		this.loadDevices();
	}
	
	loadDevices()
	{
		try{
			this.setState({
				showLoader: true, 
				dataList: [], 
				dataView: []			
			});
			axios.get(Api.Url+'v1/devices/all?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleAddButtonClick(){
		this.setState({showAddModal: true});
	}
	
	handleAddModalClose(){
		this.setState({showAddModal: false});
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return( (upperCase(obj.serialno).indexOf(v)>-1) || 
				(upperCase(obj.devicename).indexOf(v)>-1) || 
				(upperCase(obj.deviceip).indexOf(v)>-1));
			});
			
			this.setState({dataView: d});
		}
		catch(error){
			
		}		
	}
	
	handleDelete(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: 'Do you want to Delete?',
			buttons: {
				Yes: function(){
					$this.handleConfirmDelete(record);
				},
				No: function(){ }
			}
		});
	}
	
	handleConfirmDelete(record){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__serialNo', record.serialno);
			form.append('i__id', record.id);
			axios.post(Api.Url+'v1/device/remove', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					var dat = d.filter(function(obj){
						return(obj.id != record.id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						showLoader: false
					});
					message.success(res['data'].message || 'Deleted');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){

		
		const columns = [
			{
				title: 'Serial No',
				dataIndex: 'serialno',
				key: 'serialno'
			},
			{
				title: 'Status',
				dataIndex: 'device_status',
				key: 'device_status',
				render: (text, record)=>(
				<center>
				{record.device_status== 1 ? 
				<Badge variant="success" >
					Active
				</Badge >
				:
				<Badge variant="danger" >
					Pending
				</Badge >
				}
				</center>
				)
			},
			{
				title: 'Device Name',
				dataIndex: 'devicename',
				key: 'devicename',
			},
			{
				title: 'Location',
				dataIndex: 'devicelocation',
				key: 'devicelocation',
			},
			{
				title: 'Device IP',
				dataIndex: 'deviceip',
				key: 'deviceip',
			},
			{
				title: 'Manage',
				dataIndex: 'id',
				key: 'id',
				render: (text,record)=> (
					<center>
						{record.device_status=='0' ? <Button
								variant="danger"
								size="xs"
								onClick={this.handleDelete.bind(this, record)}
							>
								<i className="icofont-delete"></i>
						</Button> : null}
					</center>
				)
			},
		];
		
		return(
			<div>
			
				<Card>
					<Card.Body>
						<Row>
							<Col md={8} >
								<span className="input-icon">
									<i className="material-icons" >search</i>
									<Form.Control  
										size="sm" 
										placeholder="Search by Serial No, Device Name, Device IP" 
										onChange={this.handleSearchOnChange} />
								  </span>
							</Col>
							<Col md={4} >
								<div className="text-right" >
									<ButtonGroup>
										<Button
											type="button"
											size="sm"
											variant="outline-secondary"
											onClick={this.loadDevices} 
										>
											<i className="icofont-refresh" ></i> Refresh
										</Button>
										
										<Button
											type="button"
											size="sm"
											variant="outline-secondary"
											onClick={this.handleAddButtonClick} 
										>
											<i className="icofont-plus" ></i> New Device
										</Button>
										
									</ButtonGroup>
								</div>
							</Col>
						</Row>
					</Card.Body>
				</Card>
				
				<div className="mt-10 ps-table" >
					<Table 
						size="small"
						loading={this.state.showLoader}
						columns={columns} 
						dataSource={this.state.dataView} />
				</div>
				
				<Modal
					show={this.state.showAddModal}
					onHide={this.handleAddModalClose}
					backdrop="static" 
				>
					<Modal.Header closeButton >
						Add New Device
					</Modal.Header>
					<Modal.Body>
						<AddDevice 
							afterFinish={this.loadDevices} 
						/>
					</Modal.Body>
				</Modal>
				
			</div>
		);
	}
	
}
export default DeviceList;
