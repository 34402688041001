 import React, { Component } from 'react';
import {Redirect, NavLink} from 'react-router-dom';


class PaymentMenu extends Component{
	
	constructor(props){
		super(props);
		this.state = {
			btnText: 'Login',
			showLoader: false,
		}
	}
	
	render(){

		
		
		return(
			<ul className="nav nav-tabs">
			  <li className="nav-item">
				<NavLink to="/app/payments" className="nav-link" exact>Payments</NavLink>
			  </li>
			  <li className="nav-item">
				<NavLink to="/app/payments/new" className="nav-link" exact>Payment Entry</NavLink>
			  </li>
			</ul>
		);
	}
	
}
export default PaymentMenu;
