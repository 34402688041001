import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Tabs,
} from 'antd';

import { Api } from '../../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../../utils';
import { SpinLoader } from '../../../elements';
import PsContext from '../../../context/PsContext';
import UpgradePlan from './upgradePlan';
import Repayment from './Repayment';

const { TabPane } = Tabs;

class PlanAndPackage extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showUpgradeModal: false,
			editData: this.props.editData,
			dataList: [],
			dataView: [],
			showRepayment: false,
			repayInfo: [],
			planCount: 0,
		}
		
		this.handleUpgradeModalClose = this.handleUpgradeModalClose.bind(this);
		this.handlePlanUpgradeClick = this.handlePlanUpgradeClick.bind(this);
		this.loadPackages = this.loadPackages.bind(this);
		this.handleRepaymentClose = this.handleRepaymentClose.bind(this);
		
	}
	
	componentDidMount(){
		this.loadPackages();
	}
	
	handlePlanUpgradeClick(){
		this.setState({
			showUpgradeModal: true,
		});
	}
	
	handleUpgradeModalClose(){
		this.setState({
			showUpgradeModal: false,
		});
	}
	
	loadPackages(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/member/packages?mid='+
				this.props.editData.id+'&api='+
				this.context.state.api).then(res=>{
					if(res['data'].status=='1'){
						this.setState({
							showLoader: false,
							dataList: res['data'].data,
							dataView: res['data'].data,
							planCount: res['data'].data.length-1,
						});
					}
					else{
						this.setState({showLoader: false});
					}
					
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleRemovePlan(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: `Do you want to Remove the plan ?`,
			buttons: {
				Yes: function(){ 
					$this.handleDeleteConfirm(record);
				},
				No: function() { }
			}
		});
	}
	
	handleDeleteConfirm(record){
		try{
			
			this.setState({showDeleteLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('id', record.id);
			form.append('memberid', this.props.editData.id);
			
			axios.post(Api.Url+'v1/members/removeplan', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					/*var index = d.findIndex(function(obj){
						return(obj.id==record.id);
					});
					d.splice(index,1);*/
					var dat = d.filter(function(obj){
						return(obj.id != record.id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						planCount: dat.length,
						showDeleteLoader: false
					});
					//this.loadData();
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showDeleteLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleRepaymentClick(record){
		this.setState({
			repayInfo: record,
			showRepayment: true,
		});
	}
	
	handleRepaymentClose() {
		this.setState({
			showRepayment: false,
			repayInfo: [],
		});
	}
	
	handleResetZero(){

		if(!window.confirm('Action can not revert back. Do you want to reset ?. ')){
			return;
		}
		this.setState({showLoader: true});
		var form = new FormData();
		form.append('api', this.context.state.api);
		form.append('i__id', this.props.editData.id);
		
		axios.post(Api.Url+'v1/members/reset_balance_zero', form).then(res=>{
			if(res['data'].status=='1'){
				var d = this.state.dataList;
				
				this.loadData();
				message.success(res['data'].message || 'Success');
			}
			else{
				message.error(res['data'].message || 'Error');
				this.setState({showLoader: false});
			}
		});
	}

	render(){
	
		const columns = [
				{
					title: '#',
					dataIndex: 'id',
					key: 'id',
					render: (text, record)=>(
						<>
						{record.receipt_id}
						</>
					)
				},
				{
					title: 'Package Name',
					dataIndex: 'packagename',
					key: 'packagename',
				},
				{
					title: 'Activated From',
					dataIndex: 'activated_from',
					key: 'activated_from',
					render: (text, record)=>(
						<>
							{momentDate(record.activated_from)}
						</>
					)
				},
				{
					title: 'Type',
					dataIndex: 'recort_type',
					key: 'recort_type',
				},
				{
					title: 'Plan Amount',
					dataIndex: 'amount',
					key: 'amount',
					render: (text, record)=>(
						<div
							className="text-right" 
						>
							{record.amount}
						</div>
					)
				},
				{
					title: 'Paid',
					dataIndex: 'paid_amount',
					key: 'paid_amount',	
					render: (text, record)=>(
						<div
							className="text-right" 
						>
							{record.paid_amount}
						</div>
					)					
				},
				{
					title: 'Received On',
					dataIndex: 'created_on',
					key: 'created_on',	
					render: (text, record)=>(
						<div>
							{momentDate(record.created_on, 'DD/MM/YYYY')}
						</div>
					)
				},
				{
					title: 'No.of Days',
					dataIndex: 'total_days',
					key: 'total_days',					
				},
				{
					title: 'Plan Expire On',
					dataIndex: 'plan_expire_on',
					key: 'plan_expire_on',
					render: (text, record)=>(
						<>
							{momentDate(record.plan_expire_on)}
						</>
					)
				},
				{
					title: 'Manage',
					dataIndex: 'id',
					key: 'id',
					render: (text,record,index)=> (
						<center>
						{this.state.planCount == index ?
							<Button
								type="button"
								size="sm"
								variant="warning"
								onClick={this.handleRemovePlan.bind(this, record)} 
							>
								<i className="icofont-trash"></i>Remove
							</Button>
						: ''}
						</center>
					)
				}
				/*{
					title: 'Manage',
					dataIndex: 'id',
					key: 'id',
					render: (text,record)=> (
						<center>
							<Button
								type="button"
								size="sm"
								variant="warning"
								onClick={this.handleRepaymentClick.bind(this, record)} 
							>
								<i className="icofont-reply"></i> Re Payment
							</Button>
						</center>
					)
				},*/
			];
						
		return(
			<React.Fragment>
				
				<Row>
					<Col md={8} >
						<h6>Recently plan activities..</h6>
					</Col>
					<Col md={4} >
						<div className="text-right" >
						<Button 
								type="button" 
								size="sm" 
								variant="secondary" 
								onClick={this.handleResetZero.bind(this)}
							>
								Reset Balance to Zero
							</Button>
							&nbsp;
							<Button 
								type="button" 
								size="sm" 
								variant="secondary" 
								onClick={this.loadPackages}
							>
								<i className="icofont-refresh" ></i> 
							</Button>
							&nbsp;
							<Button 
								type="button" 
								size="sm" 
								variant="secondary" 
								onClick={this.handlePlanUpgradeClick}
							>
								<i className="icofont-plus" ></i> Add New Plan
							</Button>
						</div>
					</Col>
				</Row>
				<hr />
				
				<Table 
					size="small"
					columns={columns} 
					bordered
					loading={this.state.showLoader}
					dataSource={this.state.dataView}
				 />
					
				<Modal 
					show={this.state.showUpgradeModal} 
					onHide={this.handleUpgradeModalClose}  
					backdrop="static"
					keyboard={false}
				>
					<Modal.Header closeButton >
						Upgrade Membership Plan
					</Modal.Header>
					<Modal.Body>
						<UpgradePlan
							id={this.props.editData.id}
							memberId={this.props.editData.memberid}
							editData={this.props.editData}
							afterFinish={this.loadPackages}
						/>
					</Modal.Body>
				</Modal>
				
				<Modal 
					show={this.state.showRepayment} 
					onHide={this.handleRepaymentClose}  
					backdrop="static"
					keyboard={false}
				>
					<Modal.Header closeButton >
						Repayment
					</Modal.Header>
					<Modal.Body>
						<Repayment
							id={this.props.editData.id}
							memberId={this.props.editData.memberid}
							editData={this.state.repayInfo}
						/>
					</Modal.Body>
				</Modal>
					
			</React.Fragment>
		);
	}
	
}
export default PlanAndPackage;
