import React, { Component } from 'react';
import $ from 'jquery';
import { Switch, Route, HashRouter } from 'react-router-dom';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select,  
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import nav from './nav';
import Menu from './Menu';

const { Option } = Select;

class Settings extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
		
	}
	
	componentDidMount(){
		
	}
	
	render(){
		
				
		return(
			<React.Fragment>
				<Row>
					<Col md={3} >
						<Menu />
					</Col>
					
					<Col md={9} >
						  <HashRouter>
							<Switch>
							{nav.map((item,i)=>{
							  return <Route path={item.to} component={item.component} exact />
							  })
							}
							</Switch>
						  </HashRouter>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
	
}
export default Settings;
