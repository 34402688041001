import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Badge , InputGroup ,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate, decimalKeyPress, todayDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import AddMember from './addMember';
import EditMember from './editMember';
import MemberDetails from './MemberDetails';
import PrintReceipt from '../ReceiptEntry/printReceipt';

const { Option } = Select;

class ReceiptEntry extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showNextInstallment: true,
			showPrintReceipt: false,
			amountReceived: '',
			dataList: [],
			dataView: [],
			printData: [],
			searchMid: '',
			searchMob: '',
		}
		
		this.handleMidSearchChange = this.handleMidSearchChange.bind(this);		
		this.handleMobSearchChange = this.handleMobSearchChange.bind(this);		
		this.handleMidEnterPress = this.handleMidEnterPress.bind(this);		
		this.handleMobEnterPress = this.handleMobEnterPress.bind(this);		
		this.handleAmountChange = this.handleAmountChange.bind(this);		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);		
		this.handleResetClick = this.handleResetClick.bind(this);	
		this.handleReceiptModalClose = this.handleReceiptModalClose.bind(this);		
		
	}
	
	componentDidMount(){
		
		
	}
	
	handleMidSearchChange(e){
		this.setState({searchMid: e.target.value});
	}
	
	handleMobSearchChange(e){
		this.setState({searchMob: e.target.value});
	}
	
	handleMobEnterPress(e){
		if(e.which==13)
			this.handleSearchClick('mob');
	}
	
	handleMidEnterPress(e){
		if(e.which==13)
			this.handleSearchClick('mid');
	}
	
	handleReceiptModalOpen(d){
		this.setState({
			printData: d,
			showPrintReceipt: true,
		});
	}
	
	handleReceiptModalClose(){
		this.setState({
			printData: [],
			showPrintReceipt: false,
		});
	}
	
	handleSearchClick(opt){
		try{
			var v = '';
			if(opt=='mob'){
				if(this.state.searchMob.length != 10){
					message.error('Enter 10 Digit Mobile Number');
					return;
				}
				v = this.state.searchMob;
			}
			else if(opt=='mid'){
				if(this.state.searchMid.length < 3){
					message.error('Enter valid Member Id');
					return;
				}
				v = this.state.searchMid;
			}
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/member/search?'+opt+'='+v+'&api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getData(fieldName){
		try{
			var d = this.state.dataList;
			return d[0][fieldName];
		}
		catch(error){
			return '';
		}
	}
	
	getPaidTotal(){
		try{
			var d = this.state.dataList;
			return (parseFloat(d[0]['current_plan_amount']) - parseFloat(d[0]['balance_to_paid'])).toFixed(2);
			
		}
		catch(error){
			return 0;
		}
	}
	
	handleAmountChange(e){
		try{
			var showInstallment = true;
			if(parseFloat(e.target.value)>parseFloat(this.getData('balance_to_paid'))){
				
				if(parseFloat(this.getData('balance_to_paid')) == 0){
					message.error('Already paid ! No Balance !');
				}
				else{
					message.error(`Enter amount less than the balance Rs.${this.getData('balance_to_paid')}`);
				}
				this.setState({amountReceived: ''});
				return false;
			}
			
			showInstallment = parseFloat(e.target.value) != parseFloat(this.getData('balance_to_paid'));
			this.setState({
				amountReceived: e.target.value,
				showNextInstallment: showInstallment
			});
		}
		catch(error){ }
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if(!window.confirm('Do you want to save receipt')){
				return false;
			}
			
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/member/receipt_entry', $("#frm_Mod_MemberReceipt").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frm_Mod_MemberReceipt").reset();
					this.setState({
						showLoader: false,
						showNextInstallment: true,
						dataList: [],
						amountReceived: '',
						printData: res['data'].data,
						showPrintReceipt: true,
					});
					message.success(res['data'].message || 'Success');
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			message.error('Unknown Error');
			this.setState({showLoader: false});
		}
	}
	
	handleResetClick(){
		this.setState({
			showLoader: false,
			showNextInstallment: true,
			dataList: [],
			amountReceived: '',
		});
	}
	
	render(){

				
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					<div 
						style={{minHeight: 'calc(100vh - 300px)'}}
					>
					
					{this.state.dataList 
						&& Object.keys(this.state.dataList).length>0 ?
					<div>					
						<form 
							action=""
							method="post"
							encType="multipart/form-data"
							id="frm_Mod_MemberReceipt" 
							onSubmit={this.handleFormSubmit} 
						>
						
						<input type="hidden" name="api" value={this.context.state.api} />
						<input type="hidden" name="i__id" value={this.getData('id')} />
						
						<Row>
							<Col md={2} >
								<label>Member Id</label>
							</Col>
							<Col md={9} >
								<Form.Control
									type="text"
									name="i__memberId"
									size="sm"
									autocomplete="off" 
									value={this.getData('memberid')} 
								/>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={2} >
								<label>Name</label>
							</Col>
							<Col md={9} >
								<Form.Control
									type="text"
									name="i__name"
									size="sm"
									autocomplete="off" 
									value={this.getData('firstname') +' '+ this.getData('lastname')} 
								/>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={2} >
								<label>Mobile No</label>
							</Col>
							<Col md={9} >
								<Form.Control
									type="text"
									name="i__mobile"
									size="sm"
									autocomplete="off" 
									value={this.getData('mobile')} 
								/>
							</Col>
						</Row>
						
						<Row className="mt-10" >
							<Col md={2} >
								<label>Plan</label>
							</Col>
							<Col md={5} >
								<Form.Control
									type="text"
									name="i__planName"
									size="sm"
									autocomplete="off" 
									value={this.getData('packagename')} 
								/>
							</Col>
							<Col md={4} >
								<Form.Control
									type="text"
									name="i__planExpires"
									size="sm"
									autocomplete="off" 
									value={momentDate(this.getData('current_plan_expires_on'), 'DD-MM-YYYY')} 
								/>
							</Col>
						</Row>
						
						<Row className="" >
							<Col md={2} >
								
							</Col>
							<Col md={3} >
								<label>Total</label>
								<Form.Control
									type="text"
									name="i__planTotal"
									size="sm"
									autocomplete="off" 
									className="text-right"
									value={this.getData('current_plan_amount')} 
								/>
							</Col>
							<Col md={3} >
								<label>Paid</label>
								<Form.Control
									type="text"
									name="i__paidTotal"
									size="sm"
									autocomplete="off" 
									className="text-right"
									value={this.getPaidTotal()} 
								/>
							</Col>
							<Col md={3} >
								<label>Balance</label>
								<Form.Control
									type="text"
									name="i__balance"
									size="sm"
									autocomplete="off" 
									className="text-right"
									value={this.getData('balance_to_paid')} 
								/>
							</Col>
						</Row>
						
						<hr />
						
						<Row>
							<Col md={2} >
							
							</Col>
							<Col md={4} >
								<label>Amount Received </label>
								<Form.Control
									type="number"
									name="i__received"
									size="sm"
									autocomplete="off" 
									className="text-right font-weight-600" 
									required
									onKeyPress={decimalKeyPress}
									onChange={this.handleAmountChange}
									value={this.state.amountReceived}
								/>
							</Col>	
							{this.state.showNextInstallment ? <Col md={5} >
								<label>Next Installment Date</label>
								<Form.Control
									type="date"
									name="i__nextInstallment"
									size="sm"
									autocomplete="off" 
									required
									min={todayDate()}
								/>
							</Col> : null}							
						</Row>
						
						<Row className="mt-10 mb-20" >
							<Col md={11} >
								<div className="text-right" >
									
									<Button 
										type="reset" 
										size="sm"
										variant="danger"
										onClick={this.handleResetClick}
									>
									 Reset
									</Button>
									&nbsp;
									<Button 
										type="submit" 
										size="sm"
										variant="success"
									>
									 Save Receipt
									</Button>
									
								</div>
							</Col>
						</Row>
						</form>
					</div>
					:
					<Row>
						<Col md={2} >
							<label>Member Id</label>
						</Col>
						<Col md={3} >
							<InputGroup >
								<Form.Control 
									type="text"
									name="i__memberId"
									size="sm" 
									placeholder="Member Id"
									autocomplete="off"
									onKeyPress={this.handleMidEnterPress}
									onChange={this.handleMidSearchChange}
								/>
								<InputGroup.Append >
									<Button
										type="button"
										size="sm"
										onClick={this.handleSearchClick.bind(this, 'mid')}
									>
										<i className="icofont-search" ></i>
									</Button>
								</InputGroup.Append >
							</InputGroup >
						</Col>
						
						<Col md={2} >
							<label>Mobile No</label>
						</Col>
						<Col md={3} >
							<InputGroup >
								<Form.Control 
									type="text"
									name="i__mobile"
									size="sm" 
									placeholder="Mobile"
									autocomplete="off"
									autofocus
									onKeyPress={this.handleMobEnterPress} 
									onChange={this.handleMobSearchChange}
								/>
								<InputGroup.Append >
									<Button
										type="button"
										size="sm"
										onClick={this.handleSearchClick.bind(this, 'mob')}
									>
										<i className="icofont-search" ></i>
									</Button>
								</InputGroup.Append >
							</InputGroup >
						</Col>
						<Col md={12} >
							<hr />
						</Col>
					</Row>
					}
					
				</div>
				</Spin>		

				<Modal show={this.state.showPrintReceipt} onHide={this.handleReceiptModalClose} size="lg" >
					<Modal.Header closeButton >
						Print Receipt
					</Modal.Header>
					<Modal.Body>
						<PrintReceipt printData={this.state.printData} />
					</Modal.Body>
				</Modal>

				
			</React.Fragment>
		);
	}
	
}
export default ReceiptEntry;
