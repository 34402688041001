import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Table
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select
} from 'antd';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate, todayDate, groupByMultiple,numberToMonth
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import AddTrainer from './addTrainer';
import EditTrainer from './editTrainer';

const { Option } = Select;
const tdStyle = {border : '1px solid black',borderCollapse : 'collapse',textAlign : 'center'}
const tdSundayStyle = {border : '1px solid black',borderCollapse : 'collapse',textAlign : 'center',backgroundColor : '#ffc9c9',color : 'red'}

class AttendanceSummary extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddDrawer: false,
			showEditDrawer: false,
			showLogModal: false,
			editData: [],
			dataList: [],
			dataView: [],
			staffList: [],
			selectedRowGroup: 'ALL',
			selectedMonth: '',
			selectedMonthValue: '',
			selectedYearValue: '',
		}
		this.handlePrintClick = this.handlePrintClick.bind(this);
		this.loadData = this.loadData.bind(this);
		this.handleMonthChange = this.handleMonthChange.bind(this);
		
	}
	
	handlePrintClick(){
		this.printDocument("emp_list");
	}
	
	printDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.atClose)
			 this.props.atClose();
	}
	
	exportTableToExcel(tableID, filename = ''){
		try{
			
			this.setState({
				showLoader: true
			});
				
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
			
			// Specify file name
			filename = filename?filename+'.xls':'excel_data.xls';
			
			// Create download link element
			downloadLink = document.createElement("a");
			
			document.body.appendChild(downloadLink);
			
			if(navigator.msSaveOrOpenBlob){
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob( blob, filename);
				
				this.setState({ showLoader: false });
			}else{
				// Create a link to the file
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
			
				// Setting the file name
				downloadLink.download = filename;
				
				//triggering the function
				downloadLink.click();
				
				this.setState({ showLoader: false });
			}	
			
		}
		catch(error){
			this.setState({ showLoader: false });
		}
	}
	
	componentDidMount(){
		this.loadStaffData();
	}
	
	handleMonthChange(e){
		var m = new Date(e.target.value).getMonth();
		var y = new Date(e.target.value).getFullYear();
		this.state.selectedMonthValue = m;
		this.state.selectedYearValue = y;
		this.setState({
			selectedMonth: e.target.value,
			selectedMonthValue: m,
			selectedYearValue: y,
		});
	}
	
	loadData(){
		try{
			
			this.setState({
				showLoader: true, 
				dataList: [], 
				dataView: []
			});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__date', this.state.selectedMonth);
			form.append('i__mid', this.props.mid);
			
			axios.post(Api.Url+'v1/iclock/employee/attnsumlogs', form).then(res=>{
				if(res['data'].status=='1'){
					var d = res['data'].data;
					var s = groupByMultiple(d, function(obj){
						return [obj.UserId];
					});
					
					this.setState({
						dataList: d, //res['data'].data,
						dataView: s, //res['data'].data,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
					message.error(res['data'].message || 'Error');
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}

	loadStaffData(){
		try{
			this.setState({showLoader: true});
			axios.get(Api.Url+'v1/trainers/all?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						staffList: res['data'].data,
						showLoader: false
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	daysInMonth (month, year) {
		return new Date(year, month, 0).getDate();
	}
	
	getSundays(year, month, totalDays){
		
		var sun = new Array();
		for(var i=1;i<=totalDays;i++){    //looping through days in month
			var newDate = new Date(year,month,i)
			if(newDate.getDay()==0){   //if Sunday
				sun.push(i);
			}
		}
	}
	
	getTimeList(lists){
		var s = [];
		lists.map((item,i)=>{
			s.push(`${momentDate(item.LogDate, 'HH:mm A')} -`);
		});
		return s; //.slice(0,-1);
	}
	
	getdays(){
		var days = [];
		var year = this.state.selectedYearValue;
		var month = this.state.selectedMonthValue;
		
		for(var i=1; i<this.daysInMonth(month+1,year)+1; i++){
			
			days.push(<th style={tdStyle}>{i}</th>);
		}
		
		return days;
	}

	checkpresentdays(userid){

		var days = [];
		var year = this.state.selectedYearValue;
		var month = this.state.selectedMonthValue;
		
		for(var i=1; i<this.daysInMonth(month+1,year)+1; i++){
			var newDate = new Date(year,month+1,i)
			if(newDate.getDay()==0){
				days.push(<td style={tdSundayStyle}>S</td>);
			}
			else{
				days.push(<td style={tdStyle}>{this.getpresent(userid,i) == 'P' ? <span className="text-success" style={{fontSize : '20px'}} >&#10003;</span> : ''}</td>);
			}
		}
		
		return days;
	}

	getpresent(userid,day){
		var result = '';
		var d = this.state.dataList;
		var year = this.state.selectedYearValue;
		var month = this.state.selectedMonthValue;
		var date = year+'-'+(month+1)+'-'+day;
		var dat = d.filter(function(obj){
			return(obj.UserId == userid && momentDate(obj.LogDate, 'YYYY-MM-DD') == momentDate(date,'YYYY-MM-DD'));
		});
		if(dat.length > 0){
			result = 'P';
		}
		return result;
	}

	render(){
		
		var sno=1;
		var year = this.state.selectedYearValue;
		var month = this.state.selectedMonthValue;
		var mon = (month+1) > 9 ? (month+1) : '0'+(month+1);
		
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
				<div style={{minHeight: 'calc(100vh - 200px)'}}>
					
					
					<Row>
						<Col md={1} >
							<label>Month</label>
						</Col>
						<Col md={3} >
							<Form.Control
								type="month"
								size="sm"
								max={momentDate(todayDate(), 'YYYY-MM')}
								onChange={this.handleMonthChange} 
							/>
						</Col>
						<Col md={1} >
							<Button
								type="button"
								size="sm"
								variant="warning"
								onClick={this.loadData} 
								
							>
								 Search
							</Button>
						</Col>
						<Col md={4} ></Col>
						<Col md={3} >
							<div className="text-right" >
								<ButtonGroup>
									<Button
										type="button"
										size="sm"
										variant="outline-secondary"
										onClick={this.handlePrintClick}
									>
										<i className="icofont-printer" ></i> Print
									</Button>
									{/*<Button
										type="button"
										size="sm"
										variant="outline-secondary"
										onClick={this.exportTableToExcel.bind(this, 'emp_list_table', 'Attendance Summary of '+numberToMonth(mon)+'-'+year )}
									>
										<i className="icofont-file-excel" ></i> Export to Excel
									</Button>*/}
									
								</ButtonGroup>
							</div>
						</Col>
					</Row>	
					
					<Row className="mt-20" >
						<Col md={12} >
							<div id="emp_list" style={{overflowX: 'scroll',}} >
								<table width="100%" id="emp_list_table" style={{borderCollapse : 'collapse'}} >
							
									<thead>
										<tr>
											<th colSpan={this.daysInMonth(month+1,year)+3} style={{textAlign : 'center'}}><b style={{
													fontSize: '18px',
													textTransform: 'uppercase',
												}}>{this.context.state.loggedUser.name}</b>
												<br />Attendance Summary for the month of {numberToMonth(mon)}-{year}
											</th>
										</tr>
										<tr>
											<th style={tdStyle}>S.No</th>
											<th style={tdStyle}>Id</th>
											<th style={tdStyle}>Name</th>
											{this.getdays()}
										</tr>
									</thead>
									<tbody>
									{this.state.staffList.map((items, i)=>{
										return <tr key={i} >
											<td style={tdStyle}>{sno++}</td>
											<td style={tdStyle}>{items.trainerid}</td>
											<td style={tdStyle}>{items.firstname} {items.lastname}</td>
											{this.checkpresentdays(items.trainerid)}
										</tr>;
									})}
									</tbody>
								</table>
							</div>
						</Col>
					</Row>
					
					
				</div>
				</Spin>
			</React.Fragment>
		);
	}
	
}
export default AttendanceSummary;
