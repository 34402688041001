import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, Table,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Tabs, Select,
} from 'antd';

import { Api } from '../../../../utils/Variables';
import {
  getls, upperCase, momentDate, todayDate, decimalKeyPress, 
} from '../../../../utils';
import { SpinLoader } from '../../../elements';
import PsContext from '../../../context/PsContext';

const { TabPane } = Tabs;
const { Option } = Select;

class Communication extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			editData: this.props.editData,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/member/message_to_member', $("#frmMemCommunication").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmMemCommunication").reset();
					message.success(res['data'].message || 'Success');
					this.setState({showLoader: false});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
		
	render(){

		var sno=1;
 		
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					<div style={{minHeight: 'calc(100vh - 200px)'}} >
						
						<form 
							action=""
							method="post"
							encType="multipart/form-data" 
							id="frmMemCommunication"
							onSubmit={this.handleFormSubmit}
						>
						
							<input type="hidden" name="api" value={this.context.state.api} />
							<input type="hidden" name="i__id" value={this.state.editData.memberid} />
							<input type="hidden" name="i__mobile" value={this.state.editData.mobile} />
							
							<Row>
								<Col md={2} >
									<label>Message</label>
								</Col>
								<Col md={6} >
									<Form.Control
										as="textarea"
										name="i__message"
										rows="5"
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-15" >
								<Col md={8} >
									<div className="text-right" >
										<Button
											type="submit"
											size="md"
											variant="success"
										>
											Send Message
										</Button>
									</div>
								</Col>
							</Row>
							
						</form>
					
					</div>
					</Spin>
			</React.Fragment>
		);
	}
	
}
export default Communication;
