import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, Dropdown, Modal, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Tabs, Select,
} from 'antd';

import { Api } from '../../../../utils/Variables';
import {
  getls, upperCase, momentDate, todayDate, decimalKeyPress, 
} from '../../../../utils';
import { SpinLoader } from '../../../elements';
import PsContext from '../../../context/PsContext';

const { TabPane } = Tabs;
const { Option } = Select;

class Repayment extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			editData: this.props.editData,
			memberUId: this.props.id,
			memberId: this.props.memberId,
			editData: this.props.editData,
			selectedPackageId: '',
			selectedTiming: '',
			selectedTrainer: '',
			trainerList: [],
			packageList: [],
			timingList: [],
			selectedPackage: [],
			amountReceived: '',
			btnText: 'Confirm',
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		this.setState({
			repayData: this.props.editData,
		});
	}
	
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			var amt = parseFloat(this.state.selectedPackage.amount);
			var v = parseFloat(this.state.amountReceived);
			if(v == 0 || v > amt){
				message.error('Received amount must less than or equal to package amount');
				return;
			}
			
			this.setState({
				showLoader: true, 
				btnText: '...'
			});
			axios.post(Api.Url+'v1/member/repayment', $("#frm_mem_repayment").serialize()).then(res=>{
				document.getElementById("frm_mem_repayment").reset();
				if(res['data'].status=='1'){
					this.setState({
						showLoader: false, 
						btnText: 'Upgrade Now',
						selectedPackage: [],
						selectedPackageId: '',
						selectedTrainer: [],
						selectedTrainerId: '',
						amountReceived: '0',
					});
					message.success(res['data'].message || 'Success');
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					this.setState({
						showLoader: false, 
						btnText: 'Upgrade Now'
					});
					message.danger(res['data'].message || 'Error');
				}
			});
			
		}
		catch(error){
			this.setState({
				showLoader: false, 
				btnText: 'Upgrade Now'
			});
		}
	}
	
	getData(fieldName){
		try{
			var d = this.state.repayData;
			return d[fieldName];
		}
		catch(error){
			
		}
	}
	
	render(){

						
		return(
			<React.Fragment>
				<Spin 
					size="large"
					tip="Please wait..."
					spinning={this.state.showLoader} >
					
				<form 
					action="" 
					method="post" 
					encType="multipart/form-data" 
					id="frm_mem_repayment" 
					onSubmit={this.handleFormSubmit} 
				>
				
					<input type="hidden" name="api" value={this.context.state.api} />
					<input type="hidden" name="i__rId" value={this.getData('id')} />
					<input type="hidden" name="i__mId" value={this.props.id} />
					<input type="hidden" name="i__memberId" value={this.props.memberId} />
					<input type="hidden" name="package" value={JSON.stringify(this.props.editData)} />
					
					
					<Row>
						<Col md={4} >
							<label>Member Id</label>
						</Col>
						<Col md={8} >
							<Form.Control
								type="text" 
								name="i__memberCode" 
								size="sm"
								value={this.getData('member_code')} 
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={4} >
							<label>Paid Amount</label>
						</Col>
						<Col md={8} >
							<Form.Control
								type="text" 
								name="i__paidAmount"
								size="sm"
								value={this.getData('paid_amount')} 
							/>
						</Col>
					</Row>
					
					<Row className="mt-10" >
						<Col md={4} >
							<label>Receipt Date</label>
						</Col>
						<Col md={8} >
							<Form.Control
								type="text" 
								name="i__receiptDate"
								size="sm"
								value={momentDate(this.getData('receipt_date'), 'DD-MM-YYYY')} 
							/>
						</Col>
					</Row>
					
				
					<Row>
						<Col md={12} >
							<hr />
							<div className="text-center" >
								<Button 
									type="submit"
									size="md"
									variant="success" 
									disabled={this.state.btnText!='Confirm'}
								>
								{this.state.btnText}
								</Button>
							</div>
						</Col>
					</Row>
				
					
				</form>									
				
				</Spin>
			</React.Fragment>
		);
	}
	
}
export default Repayment;
